import React from "react";
import logo from "../images/logo.png";
import whySiepay from "../images/whySiepay.png";


const Available = () => {

return(
    <>
    
<section className="sec6">


    <div className="container">
        <div className="row ai-center">
            <div className="col-lg-7">

             <div className="imgDiv animatable slideInRight">
                <img className="img-fluid" src={whySiepay} alt="" />
             </div>
            </div>


            <div className="col-lg-5">

                <h1 className="h1avail animatable slideInUp">Why SiePay?</h1>

                <p className="pwe animatable slideInUp">Web3 gives you full control over your assets, i.e., self-custody; but this comes at the cost of burdensome approvals each time a transaction is made—every single subscription payment requires confirmation because there's no "set it and forget" feature in Web 3!</p>

                <p className="poffer animatable slideInUp">SiePay is the solution to this problem. Our platform automates all of the approvals required by web3 so you can focus on what’s important: running your business. We make it easy for you to pay employees, accept donations, and subscriptions with just a few clicks. With SiePay, there is no need to worry about confirmations ever again.
</p>

            </div>

        </div>

    </div>







</section>



    
    </>
)

}

export default Available;