import React, { useEffect } from "react";
import { BrowserRouter, BrowserRouter as Router, Route, Switch, useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';


import { NavLink, Link } from "react-router-dom";
import $ from 'jquery';

import { ReactComponent as GetStarted } from "../../../../images/getStarted.svg";
import { ReactComponent as Overview } from "../../../../images/overview.svg";
import { ReactComponent as PaymentIco } from "../../../../images/paymentIco.svg";
import { ReactComponent as SubscriptionIco } from "../../../../images/subscriptionIco.svg";
import { ReactComponent as ProductIco } from "../../../../images/productIco.svg";
import { ReactComponent as SettingIco } from "../../../../images/settingIco.svg";
import { ReactComponent as ProfileIco } from "../../../../images/profileIco.svg";
import { ReactComponent as LogoutIco } from "../../../../images/logoutIco.svg";
import { ReactComponent as FaqIco } from "../../../../images/faq.svg";
import { ReactComponent as ContactIco } from "../../../../images/contactUs.svg";
import profile from "../../../../images/avatar.png";

import logo from "../../../../images/logo.svg";
import logoSm from "../../../../images/logoSm.svg";
// import subscribe from "../../../../assets/images/subscribe.svg";
// import transactions from "../../../../assets/images/transactions.svg";
// import product from "../../../../assets/images/product.svg";
import { useDispatch, useSelector } from "react-redux";
import userActions from "../../../../redux/users/action";
import customerActions from "../../../../redux/customer/action";



function CustomerSidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.userReducer.users);
  
  const { setCustomer } = customerActions;

  useEffect(() => {
    sidebarFunctionality()
    console.log("toggle");
  },[])

  const sidebarFunctionality=()=>{
    /* eslint-disable */
      "use strict";
      $("#sidebarToggle, #sidebarToggleTop").unbind().on("click", function (e) {
        console.log($(".sidebar").hasClass("toggled"));
          $("body").hasClass("sidebar-toggled") ?$("body").removeClass("sidebar-toggled"):$("body").addClass("sidebar-toggled"),
          $(".sidebar").hasClass("toggled") ? $(".sidebar").removeClass("toggled"):$(".sidebar").addClass("toggled"), 
          $(".sidebar").hasClass("toggled") && $(".sidebar .collapse").hide();
      }),
      $(window).resize(function () {
        // $(window).width() < 768 && $(".sidebar .collapse").collapse("hide"),
        $(window).width() < 480 && !$(".sidebar").hasClass("toggled") && ($("body").addClass("sidebar-toggled"), $(".sidebar").addClass("toggled"), $(".sidebar .collapse").hide());
          }),
          $("body.fixed-nav .sidebar").on("mousewheel DOMMouseScroll wheel", function (e) {
              var o;
              768 < $(window).width() && ((o = (o = e.originalEvent).wheelDelta || -o.detail), (this.scrollTop += 30 * (o < 0 ? 1 : -1)), e.preventDefault());
          }),
          $(document).on("scroll", function () {
              100 < $(this).scrollTop() ? $(".scroll-to-top").fadeIn() : $(".scroll-to-top").fadeOut();
          });
         
  }

  // function Toggle(params) {
  //   const elements = window.document.querySelectorAll(".I234")
  //   console.log(elements);
  //   elements.forEach(element => {
  //     if (element.style.display === "none") {
  //       element.style.display = "block"
  //       window.document.querySelector(".sidebar").style.width = "17rem"
  //       window.document.getElementById("content-wrapper").style.marginLeft = "17rem"
        
  //     } else {
  //       element.style.display = "none"
  //       window.document.querySelector(".sidebar").style.width = "3rem"
  //       window.document.getElementById("content-wrapper").style.marginLeft = "6.5rem"


  //     }
  //   });
  // }

  const logout = async () => {
    try {
      await dispatch(setCustomer({}));
      localStorage.clear()
      navigate("/welcome");
    } catch {
      alert.show("Error while logging out");
    }
  };

  return (
    <>

      {/* Sidebar */}
      <ul className="navbar-nav  sidebar sidebar-dark accordion zi-1 " id="accordionSidebar">
        {/* <button style={{ width: "10px" }} id="sidebarToggle" onClick={() => Toggle()}>Toggle</button> */}
        {/* Sidebar - Brand */}
        <Link className="sidebar-brand d-flex align-items-center justify-content-center mt-2" to="/">
          <div className="sidebar-brand-text ">
            <img src={logo} className="mr-2" alt="siepay" />
          </div>
          <div className="sidebar-brandSm">
            <img src={logoSm} className="mr-2" alt="siepay" />
          </div>
        </Link>
        <div className="mnu">Menu</div>
        {/* sidebar links */}
     
          <>
            <li className="nav-item my-1 ">
              <NavLink className="nav-link sidebar-Link fw-500" to="/customer/dashboard">
                <GetStarted />
                <span className="I234">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item my-1 ">
              <NavLink className="nav-link sidebar-Link fw-500" to="/customer/product">
                <ProductIco />
                <span className="I234">Products</span>
              </NavLink>
            </li>
            <li className="nav-item my-1 ">
              <NavLink className="nav-link sidebar-Link fw-500" to="/customer/transaction">
                <Overview />
                <span className="I234">Transactions</span>
              </NavLink>
            </li>
            
            <li className="nav-item my-1 ">
              <NavLink className="nav-link sidebar-Link fw-500" to="/customer/upcoming">
                <PaymentIco />
                <span className="I234">Upcoming</span>
              </NavLink>
            </li>
         
          </>

        {/* <li className="nav-item my-1">
              <a className="nav-link sidebar-Link fw-500" href="#">
              <img className="" src={setting} alt="" />
                <span className="">Settings</span></a>
            </li> */}


        {/* Nav Item - Pages Collapse Menu */}
        {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                <i className="fas fa-fw fa-cog" />
                <span>Components</span>
              </a>
              <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Custom Components:</h6>
                  <a className="collapse-item" href="buttons.html">Buttons</a>
                  <a className="collapse-item" href="cards.html">Cards</a>
                </div>
              </div>
            </li> */}
        <div className="mnu mt-auto">GENERAL</div>
  
          <>
            <li className="nav-item my-1 ">
              <NavLink className="nav-link sidebar-Link fw-500" to="/dashboard/settings">
                <SettingIco />
                <span className="">Settings</span>
              </NavLink>
            </li>
            {/* <li className="nav-item my-1 ">
              <NavLink className="nav-link sidebar-Link fw-500" to="/dashboard/profile">
                <ProfileIco />
                <span className="">Profile</span>
              </NavLink>
            </li> */}
            <li className="nav-item my-1 ">
              <NavLink className="nav-link sidebar-Link fw-500" to="/customer/faq">
                <FaqIco />
                <span className="">FAQ</span>
              </NavLink>
            </li>
            <li className="nav-item my-1 ">
              <NavLink className="nav-link sidebar-Link fw-500" to="/customer/contact">
                <ContactIco />
                <span className="">Contact Us</span>
              </NavLink>
            </li>
            <li className="nav-item my-1 ">
              <NavLink className="nav-link sidebar-Link fw-500" to="/logout" onClick={logout}>
                <LogoutIco />
                <span className="">Logout</span>
              </NavLink>
            </li>
          </>

      </ul>
      {/* End of Sidebar */}

    </>
  )

}
export default CustomerSidebar