import React, { useEffect, useState } from "react";
import dollar from "../../images/dollar.png";
import dynamic from "../../images/dynamic.png";
import data_im from "../../images/data.png";
import star from "../../images/star.png";
import eye from "../../images/eye.png";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api";
import { useDispatch, useSelector } from "react-redux";
import customerActions from "../../redux/customer/action";
import { Loader } from "../Loader";
import axios from "axios";
import { useAlert } from "react-alert";

import Web3 from "web3";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import WalletConnectProvider from "@walletconnect/web3-provider";
import { EthereumProvider } from '@walletconnect/ethereum-provider'

import metamask from "../../images/updated-img/metamask.png"
import walletconnect from "../../images/updated-img/walletconnect.png"
import bitkeep from "../../images/updated-img/bitkeep.png"
import close from "../../images/updated-img/close.png"

import logoWhite from "../../images/logoWhite.svg"
import logo from "../../images/logo.svg"
import { getAccount, web3Initializer } from "../../helpers/metamask";
import web3Object from "../../web3/web3";



const Welcome = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { setCustomer } = customerActions;
  const user = useSelector((state) => state.userReducer.users);
  const customer = useSelector((state) => state.customerReducer.customer);
  const [wallet, setWallet] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const connectMetamask = async () => {
    try {
      setLoader(true);
      await web3Initializer();

      const userWalletId = await getAccount();
        console.log(userWalletId);
      if (!userWalletId) {
        throw new Error("Unable to connect user, Metamask error.");
      }

      const chainId = await web3Object.web3.eth.getChainId();

      if (parseInt(chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
        alert.show("Please connect to Binance Test Network");
        throw new Error("Please connect to Binance Test Net");
      }

      checkCustomer(userWalletId)
      setWallet(userWalletId);
      setLoader(false);
    } catch (e) {
      alert.show("Error while connection with Wallet");
      setLoader(false);
    }
  };

  const connectBitKeep = async () => {
    try {
      setLoader(true);

      const web3 = web3Object;
      const isBitKeepInstalled = window.isBitKeep && !!window.bitkeep.ethereum;
      if (!isBitKeepInstalled) {
        alert.show("Bitkeep wallet not found.");
        return;
      }

      const provider = window.bitkeep && window.bitkeep.ethereum;
      if (!provider) {
        alert.show("Bitkeep wallet provider found.");
        return;
      }

      const accs = await provider.request({
        method: "eth_requestAccounts",
      });


      // const ThisIsBitkeepWeb3 = await new Web3("https://data-seed-prebsc-1-s3.binance.org:8545/");
      const ThisIsBitkeepWeb3 = await new Web3(window.bitkeep.ethereum);
      web3.web3 = ThisIsBitkeepWeb3;

      const isConnecttowallet = await provider.isConnected();

      if (!isConnecttowallet) {
        alert.show("Bitkeep wallet not connected.");
        setLoader(false);
        return;
      }

      console.log(web3Object.web3);

      // const back = sessionStorage.getItem("path");
      let chainId = await web3Object.web3.eth.getChainId();
      if (parseInt(chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
        alert.show("Please connect to Binance Test Network");
        setLoader(false);
        throw new Error("Please connect to Binance Test Net");
      }

      // const signature = await ThisIsBitkeepWeb3.eth.personal.sign(
      //   `I am signing to subscribe this product membership from my account: ${data._id}`,
      //   accs[0],
      //   ""
      // );

      // if (!signature) {
      //   throw new Error("Unable to connect user, Signature denied.");
      // }

      setWallet(accs[0]);
      setLoader(false);

      console.log("accs", accs);
      console.log("chainId_", chainId);
      console.log("isConnecttowallet", isConnecttowallet);

    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  // mobile wllet provider
  // const providerOptions = new WalletConnectProvider({
  //   rpc: {
  //     56: "https://bsc-dataseed1.defibit.io/",
  //     97: "https://data-seed-prebsc-1-s1.binance.org:8545",
  //     1: "https://mainnet.infura.io/v3/3f2f5e20071a4cbe9dd30a0090ff6402",
  //     4: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  //     qrcode: true,
  //     pollingInterval: 12000,
  //   },
  //   chainId: 97,
  // });

  const handleWalletConnect = async () => {
    try {
      const providerOptions = await EthereumProvider.init({
        projectId: process.env.REACT_APP_WC_PROJECT_ID, // REQUIRED your projectId
        chains: [parseInt(process.env.REACT_APP_CHAIN_ID)], // REQUIRED chain ids
        showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal,
        themeVariables: {
          "--w3m-z-index":11111
        }
      });
      const web3 = web3Object;
      setModalShow(false)
      await providerOptions.enable();
      setLoader(true);
      const ThisIsWCWeb3 = new Web3(providerOptions);
      web3.web3 = ThisIsWCWeb3;
      console.log(ThisIsWCWeb3);

      let accs = await ThisIsWCWeb3.eth.getAccounts();
      console.log(accs);


      let chainId = await ThisIsWCWeb3.eth.getChainId();
      if (parseInt(chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
        alert.show("Please connect to Binance Test Network");
        setLoader(false);
        throw new Error("Please connect to Binance Test Net");
      }

      // const signature = await ThisIsWCWeb3.eth.personal.sign(
      //   `I am signing to subscribe this product membership from my account: ${data._id}`,
      //   accs[0],
      //   ""
      // );

      // if (!signature) {
      //   throw new Error("Unable to connect user, Signature denied.");
      // }

  
      checkCustomer(accs[0])
      setWallet(accs[0]);
      setLoader(false);

      console.log("accs", accs);
      console.log("chainId_", chainId);
    }
    catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  // dispatch(setCustomer(response));
  
  const checkCustomer = async (address) => {
    try {
      setLoader(true);
     console.log(address);
      const response = await api.getCustomer(address);
      console.log(response);
      setLoader(false);
      if (response?.success) {
        dispatch(setCustomer(response?.customer[0]));
        navigate('/customer/product')
      }else{
        alert.show("You are not a customer");
      }
      
    } catch (e) {
      setLoader(false);
      console.log('====================================');
      console.log(e);
      console.log('====================================');
      alert.show("You are not a customer");
    }
  };
 
  useEffect(()=>{
    console.log(customer);
  },[customer])

  return (
    <>
      <section className="welcomePage">
        {/* <Header2 /> */}

        <Link to="/" className="m-logo">
          <img src={logoWhite} alt="logo" />
        </Link>
        <div className="WelcomeCard">
            <img className="logo" src={logo} alt="logo" />
            <h3>Welcome Back</h3>
            <div className="desc">The Easiest Crypto Recurring Payment System For Businesses & Individuals.</div>
            <ConnectModal connectMetamask={connectMetamask} connectBitKeep={connectBitKeep} walletConnect={handleWalletConnect} setModalShow={setModalShow} modalShow={modalShow} />
            <div className="terms">By connecting your wallet, you agree to our Terms of services.</div>
        </div>
        {/* <Footer /> */}
      </section>

      <Loader loader={loader} />
    </>
  );
};

export const ConnectModal = ({setModalShow,modalShow,connectMetamask,connectBitKeep,walletConnect}) => {
  
    return (
      <>
        <button className="btn1" onClick={() => setModalShow(true)}>Connect Wallet</button>
  
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          connectMetamask={connectMetamask}
          connectBitKeep={connectBitKeep}
          walletConnect={walletConnect}
        />
      </>
    );
  }
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="checkout-modal"
      >
        <Modal.Header className="pt-4">
          <Modal.Title id="contained-modal-title-vcenter"> Get Started </Modal.Title>
          <div>
            <img src={close} alt="close" onClick={() => props.onHide()} />
          </div>
        </Modal.Header>
        <div className="tagline"> Select what network and wallet you want to connect below </div>
        <Modal.Body>
          <div className="row my-4">
            <div className="col-4">
              <div className="modal-card">
                <img className="img-fluid" src={metamask} alt="metamask" onClick={() => props.connectMetamask()} />
                <h4>Metamask</h4>
              </div>
            </div>
            <div className="col-4">
              <div className="modal-card">
                <img className="img-fluid" src={bitkeep} alt="bitkeep" onClick={() => props.connectBitKeep()} />
                <h4>Bitkeep</h4>
              </div>
            </div>
            <div className="col-4">
              <div className="modal-card">
                <img className="img-fluid" src={walletconnect} alt="walletconnect" onClick={() => props.walletConnect()} />
                <h4>Wallet Connect</h4>
              </div>
            </div>
          </div>
        </Modal.Body>
  
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
  }

export default Welcome;
