import { useEffect, useState } from "react";
import React from "react";
import Modal from 'react-bootstrap/Modal';

import Payment from "./Payment";
import Collaborate from "./Collaborate";
import Siepay from "./Siepay";
import Enable from "./Enable";
import Most from "./Most";
import Available from "./Available";
import What from "./What";
import In from "./In";
import Header from "./Header";
import Footer from "./Footer";
import SuccessSec from "./successSec";
import INTRO from "./videoIntro";
import { animate } from "../assets/js/animate"
import "../assets/css/animate.css"
import TFee from "./transactionFee";
import close from "../images/close.svg";
import logo from "../images/logo.svg";
import { PrivacyModal } from "./privacyModal";
import { TermsModal } from "./termsModal";
import { Demo } from "./demo";

const Home = () => {
    const [show, setShow] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseTerms = () => setShowTerms(false);
    const handleShowTerms = () => setShowTerms(true);

    useEffect(() => {
        animate()
    }, [])
    return (
        <>
            <div className="homePage">
                <Header />
                <Payment />
                <In />
                <Enable />
                <Available />
                <Siepay />
                <TFee />
                {/*  <Collaborate/> */}
                <SuccessSec />

                {/* INTRO */}
                <INTRO />
                <Demo />
                {/* <What/> */}
                <Most />
                <Footer handleShow={handleShow} handleShowTerms={handleShowTerms} />
            </div>
            <PrivacyModal show={show} handleClose={handleClose} />
            <TermsModal handleCloseTerms={handleCloseTerms} showTerms={showTerms} />
        </>
    )



}
export default Home;