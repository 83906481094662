import React, { useEffect, useState } from "react";
import dollar from "../images/dollar.png";
import dynamic from "../images/dynamic.png";
import data_im from "../images/data.png";
import star from "../images/star.png";
import eye from "../images/eye.png";
import Footer from "./Footer";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { useDispatch, useSelector } from "react-redux";
import userActions from "../redux/users/action";
import { Loader } from "./Loader";
import axios from "axios";
import { useAlert } from "react-alert";

const VerifyTwoFAC = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { setUser } = userActions;
  const user = useSelector((state) => state.userReducer.users);

  useEffect (() => {
    if (user.token && user.is2FA) {
      navigate("/twofa");
    } 
  }, [])
  
  const onSubmit = async () => {
    try {
      setLoader(true);
      const validatorResponse = await validation();

      if (validatorResponse) {
        throw new Error(validatorResponse);
      }
      
      const payload = {
        _id: user.user._id,
        twoFACode: data.twofa,
      };
      const resp = await api.verify2FA(payload);

      setLoader(false);

      if (resp.msg) {
        localStorage.removeItem("twofa")
        localStorage.setItem("twofa", true)
        navigate("/dash");
      }
      else {
        alert.show("Error while verifying TWOFA")
      }
    } catch (e) {
      setLoader(false);
      alert.show("Error while verifying TWO FA");
    }
  };

  const validation = async () => {
    if (!data.twofa) {
      return "Two Factor Auth Code is required";
    }

    return false;
  };

  const onInputChange = async (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <>
      <section className="page2sec2">
        <Header2 />

        <div className="container">
          <div className="row">
            <div className="col-lg-6 sectop1">
              <h1 style={{ color: "white" }}>Sign In</h1>
              <p style={{ color: "white" }}>
                We provide lorem ipsum team dolor sit amet, consectetur
                adipiscing performance.
              </p>

              <div className="flex">
                <div>
                  <img className="dollar" src={dollar} />
                </div>
                <div>
                  <h3 style={{ color: "white" }}>Absolutely FREE</h3>
                  <p style={{ color: "#92E2C6" }}>
                    No hidden charges, No credit card required
                  </p>
                </div>
              </div>

              <div className="flex">
                <div>
                  <img className="dollar" src={dynamic} />
                </div>
                <div>
                  <h3 style={{ color: "white" }}>Fast & Easy</h3>
                  <p style={{ color: "#C2C2C2" }}>
                    Get access instantly, no downloads required
                  </p>
                </div>
              </div>

              <div className="flex">
                <div>
                  <img className="dollar" src={data_im} />
                </div>
                <div>
                  <h3 style={{ color: "white" }}>Your Own Data</h3>
                  <p style={{ color: "#C2C2C2" }}>
                    Enjoy the Free Trial with your company data
                  </p>
                </div>
              </div>

              <div className="flex">
                <div>
                  <img className="dollar" src={star} />
                </div>
                <div>
                  <h3 style={{ color: "white" }}>Unlimited Features</h3>
                  <p style={{ color: "#C2C2C2" }}>
                    Access all features of the world's #1 POS software!
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 create1">
              <h1 className="signinh1">Verify account 2FA Authentication</h1>
              <div>
                <div>
                  <input
                    className="secdiv"
                    onChange={onInputChange}
                    type="password"
                    name="twofa"
                    placeholder="Two Code"
                  />
                </div>
              </div>
             
                  <button onClick={onSubmit} className="crebtn2">
                    Verify
                  </button>
            </div>
          </div>
        </div>

        <Footer />
      </section>

      <Loader loader={loader} />
    </>
  );
};

export default VerifyTwoFAC;
