import React, { useEffect, useState } from "react";
import credit from "../images/credit.png";
import secure from "../images/secure.png";
import product from "../images/product.png";
import dash from "../images/dash.png";
import sub from "../images/sub.png";
import Footer from "./Footer";
import Header3 from "./Header3";
import { Loader } from "./Loader";
import api from "../api";
import copy from 'copy-to-clipboard';
import { useDispatch, useSelector } from "react-redux";
import userAction from "../redux/users/action";
import bigNumber from "bignumber.js";
import editicon from "../images/pen.png";
import shareicon from "../images/share.png";
import deleteicon from "../images/delete.png";
import copyicon from "../images/link.png";
import iframelinkicon from "../images/photo.png";
import transaction from "../images/invoice.png";
import subscription from "../images/transaction.png";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";

const Dash = () => {
  const alert = useAlert()
  const dispatch = useDispatch();
  const { setUser } = userAction;
  const [clickButton, setClickButton] = React.useState(0);
  const [loader, setLoader] = useState(false);
  const userObject = useSelector((state) => state.userReducer.users);
  const navigate = useNavigate();
  // Profile tabs state
  const [userProfileData, setUserProfileData] = useState([]);
  const [twofamodel, settwofamodel] = useState(false);
  const [twofaqr, set2faqr] = useState(null);
  const [codeVerify, setCodeVerify] = useState(null);
  // Product Tan State
  const [addProduct, setAddProduct] = useState(false);
  const [editProduct, setEditProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [data, setData] = useState([]);
  const [productData, setProductData] = useState([]);
  // Subscription State
  const [subscriptions, setSubscritpions] = useState([]);
  // Transaction State
  const [transactions, setTransaction] = useState([]);
  const [transactionsProduct, setTransactionProduct] = useState([]);
  const [billingModal, setBillingModal] = useState(false);

  useEffect(() => {
    fetchUserData();
    fetchAllProducts();
    fetchAllTrans();
    fetchALlSubscription();
    // to set profile data by default
    setUserProfileData({
      firstName: userObject?.user?.firstName,
      lastName: userObject?.user?.lastName,
      username: userObject?.user?.username,
      email: userObject?.user?.email,
      pin: userObject?.user?.pin,
      wallet: userObject?.merchant?.wallet,
    });
  }, []);
  console.log('====================================');
  console.log(userObject);
  console.log('====================================');

  useEffect(() => {
    fetchUserData();
    fetchAllProducts();
    fetchAllTrans();
    fetchALlSubscription();
    // to set profile data by default
    setUserProfileData({
      firstName: userObject?.user?.firstName,
      lastName: userObject?.user?.lastName,
      username: userObject?.user?.username,
      email: userObject?.user?.email,
      pin: userObject?.user?.pin,
      wallet: userObject?.merchant?.wallet,
    });
  }, [clickButton]);

  // To fetch updated data of user and save to redux
  const fetchUserData = async () => {
    try {
      setLoader(true);

      const payload = {
        _id: userObject?.user?._id,
      };
      const userData = await api.getUserData(payload);

      const token = userObject.token;
      userData.token = token;

      dispatch(setUser(userData));
      console.log(userObject);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  //  TO generate QR and save secrete to db
  const connect2FA = async () => {
    try {
      setLoader(true);
      const payload = {
        _id: userObject?.user?._id,
      };
      const response = await api.connect2FA(payload);

      set2faqr(response.qr);
      settwofamodel(true);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      alert.show("Error while connecting 2FA");
    }
  };

  //  To remove 2FA from profile
  const remove2FA = async () => {
    try {
      setLoader(true);
      const payload = {
        _id: userObject?.user?._id,
      };
      const response = await api.remove2FA(payload);

      set2faqr(response.qr);
      settwofamodel(true);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      alert.show("Error while removing 2FA");
    }
  };

  // To close all active modals.
  const close = () => {
    set2faqr(null);
    setAddProduct(false);
    settwofamodel(false);
    setShareModal(false);
    setSelectedProduct(false);
    setEditProduct(false);
    setBillingModal(false);
  };

  //  Verify 2FA Function
  const verify2FA = async () => {
    try {
      setLoader(true);

      if (!codeVerify) {
        alert.show("TWOFA code is required");
        return;
      }

      const payload = {
        _id: userObject.user._id,
        twoFACode: codeVerify,
      };
      const resp = await api.verify2FA(payload);

      if (resp.msg) {
        if (userObject?.user?.is2FA) {
          await remove2FA();
        }

        set2faqr(null);
        settwofamodel(false);
        alert.show("2FA Verfied");
      } else {
        alert.show("2FA Verification Failed");
      }

      await fetchUserData();
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  //  Edit profile
  const edit = async () => {
    try {
      const validationRes = await validation();

      if (validationRes) {
        throw new Error(validationRes);
      }

      const payload = {
        _id: userObject.user._id,
        userProfileData,
      };

      const resp = await api.editProfile(payload);

      if (resp.error) {
        throw new Error("Password not matched or link is down.");
      }

      setUserProfileData({ ...userProfileData, password: "", newPassword: "" });

      await fetchUserData();
      alert.show("Successfully profile editted!");
    } catch (e) {
      alert.show("Error while editting profile");
    }
  };

  //  Validation for Profile
  const validation = () => {
    if (!userProfileData.email) {
      return "Email is required";
    }
    if (!userProfileData.firstName) {
      return "First Name is required";
    }
    if (!userProfileData.lastName) {
      return "Last Name is required";
    }
    if (!userProfileData.username) {
      return "Username is required";
    }
    if (userProfileData.password) {
      if (!userProfileData.newPassword) {
        return "Old and New password are required";
      }
    }
    if (userProfileData.newPassword) {
      if (!userProfileData.password) {
        return "Old and New password are required";
      }
    }
    if (!userProfileData.pin) {
      return "Pin is required";
    }

    return false;
  };

  //  To Fetch All Products
  const fetchAllProducts = async () => {
    try {
      setLoader(true);
      const response = await api.getAllProduct();
      setData(response.product);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  // add product func
  const addProductFunc = async () => {
    try {
      setLoader(true);
      const validation = await productValidation();

      if (validation) {
        throw new Error(validation);
      }

      const price = new bigNumber(productData.amount * 1000000000000000000);
      const payload = {
        ...productData,
        amount: price,
        unit: productData.unit - 1,
      };
      const response = await api.createProduct(payload);

      console.log(response);
      await fetchAllProducts();
      alert.show("Product added successfully");
      setLoader(false);
      close();
    } catch (e) {
      console.log(e);
      setLoader(false);
      alert.show("Error while adding");
    }
  };

  // To edit product
  const editProductFunc = async () => {
    try {
      const validation = await productValidationEdit();

      if (validation) {
        throw new Error(validation);
      }

      setLoader(true);

      const price = new bigNumber(productData.amount * 10 ** 18);
      console.log(productData);
      const payload = {
        ...productData,
        amount: price?.toString(),
      };
      console.log(payload);
      const response = await api.editProduct(payload);

      console.log(response);
      setLoader(false);
      await fetchAllProducts();
      alert.show("Product editted successfully");
      close();
    } catch (e) {
      console.log(e);
      setLoader(false);
      alert.show("Error while editting");
    }
  };

  // Product validation - add
  const productValidation = () => {
    if (!productData.name) {
      return "Name is required";
    }
    if (!productData.amount) {
      return "Amount is required";
    }
    if (productData.amount && isNaN(productData.amount)) {
      return "Amount is not correct";
    }
    //     if (!productData.address) {
    //       return "Address is required";
    //     }
    if (!productData.token) {
      return "Token is required";
    }
    if (!productData.time) {
      return "Time is required";
    }
    if (productData.time && isNaN(productData.time)) {
      return "Time is not correct";
    }
    if (!productData.unit) {
      return "Unit is required";
    }

    return false;
  };

  // Product validation - edit
  const productValidationEdit = () => {
    if (!productData.name) {
      return "Name is required";
    }
    if (!productData.amount) {
      return "Amount is required";
    }
    if (productData.amount && isNaN(productData.amount)) {
      return "Amount is not correct";
    }

    return false;
  };

  // Open share modal
  const openShareModal = (item) => {
    setSelectedProduct(item);
    setShareModal(true);
  };

  // Open billing modal
  const openBillingModal = (item) => {
    setSelectedProduct(item);
    setBillingModal(true);
    fetchProductTransaction(item);
  };

  // Generate IFRAME
  const generateIframe = () => {
    const iframe = `<iframe src="${process.env.REACT_APP_CLIENT}/product/checkout/?pid=${selectedProduct?._id}" name="SiePay" scrolling="auto" width="500px" height="400px" style="border: 0.5px groove #3b3b3b;"></iframe>`;
    // navigator.clipboard.writeText(iframe);
    copy(iframe)

    alert.show("Iframe generated and copied");
    setShareModal(true);
    setSelectedProduct(false);
  };

  // Generate Link of checkout
  const generateCopyLink = () => {
    const link =
      process.env.REACT_APP_CLIENT +
      "/product/checkout/?pid=" +
      selectedProduct?._id;
    // navigator.clipboard.writeText(link);
    copy(link)
    alert.show("Link generated and copied");
    setShareModal(true);
    setSelectedProduct(false);
  };

  // Open edit product model
  const openEditModal = (item) => {
    setSelectedProduct(item);
    const price = new bigNumber(parseInt(item.amount) / 10 ** 18);
    setProductData(item);
    setProductData({ ...item, amount: price });
    setEditProduct(true);
  };

  // Fetch Transaction Details
  const fetchAllTrans = async () => {
    try {
      setLoader(true);

      const payload = {
        user: userObject?.user?._id,
      };
      const response = await api.fetchTransactionAll(payload);

      console.log(response);
      setTransaction(response.msg);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  // Fetch Transaction Details - Product
  const fetchProductTransaction = async (item) => {
    try {
      setLoader(true);

      const payload = {
        product: item?._id,
      };
      const response = await api.fetchTransaction(payload);

      setTransactionProduct(response.msg);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  // fetch all subscription of product
  const fetchALlSubscription = async () => {
    try {
      setLoader(true);
      const response = await api.getUserSubscribeAll({
        userID: userObject?.user?._id,
      });

      setSubscritpions(response.msg);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  // format date
  const formatDate = (dateParam) => {
    const date = new Date(dateParam);
    return date.toLocaleDateString();
  };

  // to delete product 
  const deleteItem = async (item) => {
    try {
      setLoader(true)
      const payload = {
        _id: item._id
      }
      const apires = await api.deleteProduct(payload)

      await fetchAllProducts();
      alert.show("Product deleted successfully")
      setLoader(false)
    } catch (e) {
      alert.show("Error while deleting")
      setLoader(false)
    }
  }
  return (
    <>
      <section className="dashboard">
        <Header3 />
        {/* Tabs Icons  */}
        <div className="container">
          <div className="row">
            <div className="col-12 dashboard1">
              <div className="display">
                <div>
                  <button
                    onClick={() => setClickButton(0)}
                    className={
                      clickButton == 0
                        ? "activeDashButton"
                        : "inactiveDashButton"
                    }
                  >
                    <img className="left" src={dash} /> Dashboard
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => setClickButton(1)}
                    className={
                      clickButton == 1
                        ? "activeDashButton"
                        : "inactiveDashButton"
                    }
                  >
                    <img className="left" src={secure} /> Payment and Security
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => setClickButton(2)}
                    className={
                      clickButton == 2
                        ? "activeDashButton"
                        : "inactiveDashButton"
                    }
                  >
                    <img className="left" src={secure} /> Products
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => setClickButton(3)}
                    className={
                      clickButton == 3
                        ? "activeDashButton"
                        : "inactiveDashButton"
                    }
                  >
                    <img className="left" src={credit} /> Transactions
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => setClickButton(4)}
                    className={
                      clickButton == 4
                        ? "activeDashButton"
                        : "inactiveDashButton"
                    }
                  >
                    <img className="left" src={sub} /> Subscriptions
                  </button>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    marginLeft: "20px",
                    marginTop: "12px",
                  }}
                >
                  <div>
                    <img src={product} />
                  </div>
                  <div>
                    <p style={{ marginLeft: "10px" }}>Products</p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginLeft: "20px",
                    marginTop: "12px",
                  }}
                >
                  <div>
                    <img src={sub} />
                  </div>
                  <div>
                    <p style={{ marginLeft: "10px" }}>Subscriptions</p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginLeft: "20px",
                    marginTop: "12px",
                  }}
                >
                  <div>
                    <img src={credit} />
                  </div>
                  <div>
                    <p style={{ marginLeft: "10px" }}>Payments</p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginLeft: "20px",
                    marginTop: "12px",
                  }}
                >
                  <div>
                    <img src={secure} />
                  </div>
                  <div>
                    <p style={{ marginLeft: "10px" }}>Profile and Security</p>
                  </div>
                </div> */}
              </div>

              {/* For Dashboard tab */}
              {clickButton == 0 && (
                <table className="table table-responsive-sm">
                  <tbody>
                    <tr>
                      <td scope="row">
                        <input type="checkbox" name="brand" />
                      </td>
                      <th scope="row">Murray Simon</th>
                      <td>simon.m@email.com</td>
                      <td>(+62) 812 3456 7890</td>
                      <td>$892</td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <input type="checkbox" name="brand" />
                      </td>
                      <th scope="row">Rafael Sinatra</th>
                      <td>rafaelsinatra@email.com</td>
                      <td>(+62) 812 3456 7890</td>
                      <td>$724</td>
                    </tr>
                  </tbody>
                </table>
              )}

              {/* For profile tab */}
              {clickButton == 1 && (
                <div className="profile">
                  <label>Email</label>
                  <input
                    value={userProfileData?.email}
                    onChange={(e) =>
                      setUserProfileData({
                        ...userProfileData,
                        email: e.target.value,
                      })
                    }
                  ></input>
                  <label>First Name</label>
                  <input
                    value={userProfileData?.firstName}
                    onChange={(e) =>
                      setUserProfileData({
                        ...userProfileData,
                        firstName: e.target.value,
                      })
                    }
                  ></input>
                  <label>Last Name</label>
                  <input
                    value={userProfileData?.lastName}
                    onChange={(e) =>
                      setUserProfileData({
                        ...userProfileData,
                        lastName: e.target.value,
                      })
                    }
                  ></input>
                  <label>User Name</label>
                  <input
                    value={userProfileData?.username}
                    onChange={(e) =>
                      setUserProfileData({
                        ...userProfileData,
                        username: e.target.value,
                      })
                    }
                  ></input>
                  <label>Pin</label>
                  <input
                    value={userProfileData?.pin}
                    type="password"
                    onChange={(e) =>
                      setUserProfileData({
                        ...userProfileData,
                        pin: e.target.value,
                      })
                    }
                  ></input>
                  <label>Old Password</label>
                  <input
                    type="password"
                    value={userProfileData?.password}
                    onChange={(e) =>
                      setUserProfileData({
                        ...userProfileData,
                        password: e.target.value,
                      })
                    }
                  ></input>
                  <label>New Password</label>
                  <input
                    value={userProfileData?.newPassword}
                    type="password"
                    onChange={(e) =>
                      setUserProfileData({
                        ...userProfileData,
                        newPassword: e.target.value,
                      })
                    }
                  ></input>
                  <div className="d-flex w-100">
                    <button onClick={edit} className="w-100 mr-3">Edit Profile</button>
                    {!userObject?.user?.is2FA && (
                      <button onClick={connect2FA} className="w-100">Connect 2FA</button>
                    )}
                    {userObject?.user?.is2FA && (
                      <button onClick={() => settwofamodel(true)} className="w-100">
                        Remove 2FA
                      </button>
                  )}
                  </div>
                </div>
              )}

              {/* For product tab  */}
              {clickButton == 2 && (
                <table className="table table-responsive-sm table-con table-con-ico">
                  <p className="addIcon" onClick={() => setAddProduct(true)}>
                    +
                  </p>
                  <tbody>
                    <tr>
                      <th scope="col">Product Name</th>
                      <th scope="col">Product Amount</th>
                      <th colSpan={2} scope="col">
                        Recurring Time
                      </th>
                    </tr>
                    {data?.map((item) => (
                      <tr>
                        <td scope="row">{item?.name}</td>
                        <td scope="row">{item?.amount / 10 ** 18}</td>
                        {item?.unit == 0 && (
                          <td scope="row">{item?.time + " " + "Minutes"}</td>
                        )}
                        {item?.unit == 1 && (
                          <td scope="row">{item?.time + " " + "Hours"}</td>
                        )}
                        {item?.unit == 2 && (
                          <td scope="row">{item?.time + " " + "Days"}</td>
                        )}
                        {item?.unit == 3 && (
                          <td scope="row">{item?.time + " " + "Weeks"}</td>
                        )}
                        {item?.unit == 4 && (
                          <td scope="row">{item?.time + " " + "Months"}</td>
                        )}
                        {item?.unit == 5 && (
                          <td scope="row">{item?.time + " " + "Years"}</td>
                        )}
                        <td scope="row">
                          <div className="icon-actions">
                            <div className="icon">
                              <img
                                onClick={() => openEditModal(item)}
                                src={editicon}
                              />
                            </div>
                            <div
                              onClick={() => deleteItem(item)}
                              className="icon"
                            >
                              <img src={deleteicon} />
                            </div>
                            <div
                              onClick={() => openShareModal(item)}
                              className="icon"
                            >
                              <img src={shareicon} />
                            </div>
                            <div
                              onClick={() => openBillingModal(item)}
                              className="icon"
                            >
                              <img src={transaction} />
                            </div>
                            {/* <div
                              onClick={() => openShareModal(item)}
                              className="icon"
                            >
                              <img src={subscription} />
                            </div> */}
                          </div>
                        </td>
                      </tr>
                    ))}

                    {!data?.length && (
                      <tr>
                        <td colspan={4}>
                          No data available to display. Please try again later.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
              {clickButton == 3 && (
                <table className="table table-responsive-sm table-con table-con-ico">
                  <tbody>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Product Amount</th>
                      <th scope="col">Transaction Date</th>
                    </tr>
                    {transactions?.map((item) => (
                      <tr>
                        <td scope="row">{item?._id}</td>
                        <td scope="row">{item?.product?.name}</td>
                        <td scope="row">{item?.product?.amount / 10 ** 18}</td>
                        <td scope="row">{formatDate(item?.date)}</td>
                      </tr>
                    ))}

                    {!transactions?.length && (
                      <tr>
                        <td colspan={4}>
                          No data available to display. Please try again later.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
              {clickButton == 4 && (
                <table className="table table-responsive-sm table-con table-con-ico">
                  <tbody>
                    <tr>
                      <th scope="col">Product Name</th>
                      <th scope="col">Subscription Amount</th>
                      <th scope="col">Subscribed By</th>
                      <th scope="col">Start Time</th>
                      <th scope="col">End Time</th>
                      <th colSpan={1} scope="col">
                        Status
                      </th>
                    </tr>
                    {subscriptions?.map((item) => (
                      <tr>
                        <td scope="row">{item?.productId?.name}</td>
                        <td scope="row">
                          {item?.productId?.amount / 10 ** 18 + "$"}
                        </td>
                        <td scope="row">
                          {item?.address.slice(0, 5) +
                            "..." +
                            item?.address?.slice(-5)}
                        </td>
                        <td scope="row">{formatDate(item?.startDate)}</td>
                        <td scope="row">{formatDate(item?.endDate)}</td>
                        <td scope="row">
                          {item?.status == 0 ? "Pending" : "Active"}
                        </td>
                      </tr>
                    ))}

                    {!subscriptions?.length && (
                      <tr>
                        <td colspan={5}>
                          No data available to display. Please try again later.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />

      {/* Loader component */}
      <Loader loader={loader} />

      {/* Modal for 2FA Authentication */}
      {twofamodel && (
        <div className="gloabalModal">
          <div className="modal_visible twofa-modal">
            <span onClick={close}>X</span>
            <div>
              <h3>Please authorize 2FA</h3>
            </div>
            <div>
              <img src={twofaqr} />
            </div>
            <div>
              <label>Enter 2FA verification code</label>
              <input onChange={(e) => setCodeVerify(e.target.value)}></input>
            </div>
            <div>
              <button onClick={verify2FA}>Verify 2FA Code</button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for add Product */}
      {addProduct && (
        <div className="gloabalModal">
          <div className="modal_visible product-modal">
            <span onClick={close}>X</span>
            <div>
              <h3>Add Product</h3>
            </div>
            <div>
              <label>Name</label>
              <input
                onChange={(e) =>
                  setProductData({ ...productData, name: e.target.value })
                }
              ></input>
            </div>
            <div>
              <label>Amount</label>
              <input
                onChange={(e) =>
                  setProductData({ ...productData, amount: e.target.value })
                }
              ></input>
            </div>
            <div>
              <label>Merchant Address</label>
              <input
                disabled
                value={userObject?.merchant?.wallet}
                onChange={(e) =>
                  setProductData({ ...productData, address: e.target.value })
                }
              ></input>
            </div>
            <div>
              <label>Token</label>
              <select
                type={"select"}
                onChange={(e) =>
                  setProductData({ ...productData, token: e.target.value })
                }
              >
                <option selected disabled>
                  Please select any option
                </option>
                <option value={process.env.REACT_APP_USDT_ADDRESS}>USDT</option>
              </select>
            </div>
            <div>
              <label>Time Period</label>
              <input
                onChange={(e) =>
                  setProductData({ ...productData, time: e.target.value })
                }
              ></input>
            </div>
            <div>
              <label>Unit</label>
              <select
                type={"select"}
                onChange={(e) =>
                  setProductData({ ...productData, unit: e.target.value })
                }
              >
                <option selected disabled>
                  Please select any option
                </option>
                <option value={1}>Minutes</option>
                <option value={2}>Hours</option>
                <option value={3}>Days</option>
                <option value={4}>Weeks</option>
                <option value={5}>Months</option>
                <option value={6}>Years</option>
              </select>
            </div>
            <div>
              <button onClick={addProductFunc}>Add</button>
            </div>
          </div>
        </div>
      )}

      {/* Edit product model */}
      {editProduct && (
        <div className="gloabalModal">
          <div className="modal_visible product-modal">
            <span onClick={close}>X</span>
            <div>
              <h3>Edit Product</h3>
            </div>
            <div>
              <label>Name</label>
              <input
                value={productData.name}
                onChange={(e) =>
                  setProductData({ ...productData, name: e.target.value })
                }
              ></input>
            </div>
            <div>
              <label>Amount</label>
              <input
                value={productData.amount}
                onChange={(e) =>
                  setProductData({ ...productData, amount: e.target.value })
                }
              ></input>
            </div>
            <div>
              <button onClick={editProductFunc}>Edit</button>
            </div>
          </div>
        </div>
      )}

      {/* Share model */}
      {shareModal && (
        <div className="gloabalModal">
          <div className="modal_visible share-modal-product">
            <span onClick={close}>X</span>
            <div>
              <h3>Share Product</h3>
            </div>
            <div className="icons-cont">
              <img onClick={generateCopyLink} src={copyicon} />
              <img onClick={generateIframe} src={iframelinkicon} />
            </div>
          </div>
        </div>
      )}
      {/* Billing model */}
      {billingModal && (
        <div className="gloabalModal">
          <div className="modal_visible transaction-modal-product">
            <span onClick={close}>X</span>
            <div>
              <h3>Payments</h3>
            </div>
            <table className="table table-responsive-sm">
              <tbody>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Product Amount</th>
                  <th scope="col">Transaction Date</th>
                </tr>
                {transactionsProduct?.map((item) => (
                  <tr>
                    <td scope="row">{item?._id}</td>
                    <td scope="row">{item?.product?.name}</td>
                    <td scope="row">{item?.product?.amount / 10 ** 18}</td>
                    <td scope="row">{formatDate(item?.date)}</td>
                  </tr>
                ))}
                {!transactionsProduct?.length && (
                  <tr>
                    <td colspan={4}>
                      No data available to display. Please try again later.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default Dash;
