import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import logo from "../images/logo.svg";



const Header = () => {
  const user = useSelector((state) => state.userReducer.users);
  const userState2FA = localStorage.getItem('twofa')
  const [showBorder, setShowBorder] = useState(false)

  const scrollToView = (id) => {
    setTimeout(() => {   
      let element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth"
      })
    }, 50);
  }

  useEffect(() => {
    window.addEventListener('scroll', function (e) {
      let scrollHeight = window.scrollY;
      scrollHeight >= 80 ? setShowBorder(true) : setShowBorder(false);
    });

  }, [])
  return (
    <>
      <nav className={showBorder ? "navbar navbar-expand-xl navbar-light bb-green py-3 navbackdrop" : " py-3 navbar navbar-expand-xl navbar-light"}    >
        <section className='container'>

          <NavLink className="navbar-brand" to="/">
            <img src={logo} width={130} height={50} className="d-inline-block align-top" alt="" />
          </NavLink>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto" id="headerrnav" >

              <li className="nav-item active" onClick={() => scrollToView("home")}>
                <NavLink style={{ color: "black", fontSize: "16px", }} className="nav-link" to="/">HOME <span className="sr-only">(current)</span></NavLink>
              </li>

              <li className="nav-item" onClick={() => scrollToView("howWorks")}>
                <NavLink style={{ color: "black", fontSize: "16px", }} className="nav-link" to="/">HOW IT ALL WORKS? </NavLink>
              </li>

              <li className="nav-item" onClick={() => scrollToView("features")}>
                <NavLink style={{ color: "black", fontSize: "16px", }} className="nav-link" to="/" >FEATURES</NavLink>
              </li>

              <li className="nav-item">
                <a target="_blank" style={{ color: "black", fontSize: "16px", }} className="nav-link" href="https://blog.siepay.io/">BLOG</a>
              </li>

              <li className="nav-item" onClick={() => scrollToView("faq")}>
                <NavLink style={{ color: "black", fontSize: "16px", }} className="nav-link" to="/">FAQ's</NavLink>
              </li>
              <li className="nav-item" >
                <NavLink style={{ color: "black", fontSize: "16px", }} className="nav-link" to="/protocol">Protocol</NavLink>
              </li>


              {/* <li className="nav-item" id="discord">
      <a style={{color: '#A3D1E0', marginLeft: '219px'}} className="nav-link disabled" href="#" tabIndex={-1} aria-disabled="true">DISCORD</a>
    </li> */}
            </ul>
            <div style={{ display: "flex" }} >

              {user.token && userState2FA == 'true' && <NavLink to={user?.user?.isAdmin ? "/admin/overview" : "/dashboard/overview"} style={{ color: "black", fontSize: "18px", }} className="nav-link" >Profile</NavLink>}
              {/* {!user.token &&  */}
                 {/* <NavLink to="/signup" style={{ color: "black", fontSize: "18px", }} className="nav-link" >Sign Up</NavLink> */}
                <NavLink to="contact" className="transparentBtnDark mr-2">Demo Request</NavLink>
              {/* } */}
              
              {!user.token && 
              <>
                {/* <NavLink to="/signin"
                  // id="btn1header1" 
                  className="btn btn1 my-2 my-sm-0" >Login</NavLink> */}
                 <div className="dropdown no-arrow nav-item ">

                    <a className="btn btn1 my-2 my-sm-0 dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Login
                          {/* <div>
                          <img src={chevronDown} className="w-9 ml-1 mb-2" alt="dropdown icon" />
                        </div> */}

                    </a>
                    {/* Dropdown - User Information */}
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                      <NavLink to="/signin" className="dropdown-item" >
                        As Merchant
                      </NavLink>
                      <NavLink to="/welcome" className="dropdown-item" >
                        As Customer
                      </NavLink>
                     
                      {/* <div className="dropdown-divider" /> */}
                    
                    </div>
                  </div >
              </>
                
              }
            </div>
          </div>
        </section>
      </nav>


    </>
  )

}

export default Header;