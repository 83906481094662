import React, { useEffect, useState } from "react";
import dollar from "../images/dollar.png";
import dynamic from "../images/dynamic.png";
import data_im from "../images/data.png";
import star from "../images/star.png";
import Footer from "./Footer";
import Header2 from "./Header2";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import { useSelector } from "react-redux";
import { Loader } from "./Loader";
import { useAlert } from "react-alert";

const ResetPassword = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const {id} = useParams();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const user = useSelector((state) => state.userReducer.users);
  const [passError, setPassError] = useState(false);

  useEffect (() => {
    if (user.token) {
      navigate("/");
    }
  }, [])

  const onSubmit = async () => {
    try {
      const validatorResponse = await validation();
      
      if (validatorResponse) {
        return alert.show(validatorResponse);
      }
      
      setLoader(true);
      const payload = {
        ...data,
        token:id
      };
      console.log(payload);
      const response = await api.resetPass(payload);

      setLoader(false);
      console.log('====================================');
      console.log(response);
      console.log('====================================');
      if (response?.error) {
        console.log(response?.error);
      }
      if (response?.message == "Link expired") {
        return alert.show(response?.message);
      }

      alert.show(response?.message);
      setTimeout(() => {
          navigate("/signin");
      }, 1500);
    } catch (e) {
      setLoader(false);
      alert.show("Error while Reset Password");
    }
  };

  const validation = async () => {
    var letter = /[a-zA-Z]/; 
    var number = /[0-9]/;
    var valid = number.test(data.password) && letter.test(data.password);

    if (!data.password) {
      return "Password is required";
    }
    if (data.password && data.password != data.confirmPassword) {
      return "Confirm Password is must be same as Password";
    }
    if(data.confirmPassword.includes(' ')){
       return "Spaces are not allowed in password";
    }
    if(!valid || data.password.length < 6) {
      setPassError(true)
      return  "password not valid"
    }else(setPassError(false))
    return false;
  };

  const onInputChange = async (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <>
      <section className="page2sec1">
        <Header2 />
        <div className="container">
          <div className="row jc-center mb-3">

            <div className="col-lg-6 create order-1 order-lg-2 m-signin-form">
              <div id="smallcenter ">
                <h1 className="sigup">Reset Password</h1>
                <p className="sigupp">
                  Remember Password? <span onClick={() => navigate("/signin")}> Sign in</span>
                </p>

                <div className="">
                  <div className="form-input">
                    <input
                      className="firstdiv secDiv w-100"
                      name="password"
                      onChange={onInputChange}
                      type={"password"}
                      placeholder="Password"
                    />
                  </div>

                  <div className="form-input">
                    <input
                      
                      name="confirmPassword"
                      onChange={onInputChange}
                      className="lastdiv secDiv w-100"
                      type={"password"}
                      placeholder="Confirm Password"
                    />
                  </div>
                </div>
                {passError &&<p className="passError">Password must have alpha numaric and atleast 6 character</p>}

                <div className="btnnncre mt-4">
                  <button onClick={onSubmit} className="crebtn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>
      <Loader loader={loader} />
 </>
  );
};

export default ResetPassword;
