import React from "react";
import successIco1 from "../images/successIco1.png";
import successIco2 from "../images/successIco2.png";
import successIco3 from "../images/successIco3.png";

const SuccessSec = () => {
  return (
    <>
      <section className="successPage">



        <div className="container">
      <h1 className="inh1">Your success is our success</h1>
          <div className="row">
           <div className="col-xl-4">
                <div className="successCard animatable slideInUp">
                    <div className="imgDiv">
                        <img src={successIco1} alt="icon" />
                    </div>
                    <h5>Bring Automation To Your Business</h5>
                    <p>Siepay revolutionizes automated payments by giving customers the power to authorize recurring transactions, laying the groundwork for all </p>
                </div>
           </div>
           <div className="col-xl-4">
                <div className="successCard animatable slideInUp animationDelay100">
                    <div className="imgDiv">
                        <img src={successIco2} alt="" />
                    </div>
                    <h5>Spend Less Time Signing, More Time Building</h5>
                    <p>SiePay allows businesses to streamline their recurring crypto transactions with the power of automated smart contracts - all in just one simple approval.</p>
                </div>
           </div>
           <div className="col-xl-4">
                <div className="successCard animatable slideInUp animationDelay200">
                    <div className="imgDiv">
                        <img src={successIco3} alt="" />
                    </div>
                    <h5>Unlock The Potential Of Web3</h5>
                    <p>SiePay provides an accessible gateway for unlocking new customer segments that operate within the web3 ecosystem and are looking to transact in </p>
                </div>
           </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SuccessSec;
