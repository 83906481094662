import React, { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../api";
import { useDispatch, useSelector } from "react-redux";
import userActions from "../redux/users/action";
import { Loader } from "./Loader";
import axios from "axios";
import { useAlert } from "react-alert";

const AccountVerified = () => {
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [uMessage, setUMessage] = useState(false);
  const [search,setSearch] = useSearchParams();
  const user = useSelector((state) => state.userReducer.users);

  
  const submitUser = async (token) => {
    console.log(token);
    setLoader(true)
    
    try {
      setLoader(true);

          const response = await api.verifyAccount({
            token: token,
          });

          console.log(response);
          console.log(response);
          if (response.message == "Account Verified" || response.message == "Acount Verified") {
            setLoader(false);
            setUMessage("Thank You, your account is verfied")
            return alert.show("verified Successfully");
          } 
          else if(response.message === "Your account already verified"){
            setLoader(false);
            setUMessage(response.message)
            console.log("Already verified");
            return alert.show("Already verified");
          }
         else {
          setLoader(false);
          return setUMessage("Error on verification.")
        }
      } catch (error) {        
        setUMessage("Something went wrong.")
        setLoader(false);
      }
    };

    useEffect(()=> {
      submitUser(search.get("token"))
    },[])

  return (
    <>
      <section className="page2sec2 verifyPage">

        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <h1 style={{ color: "white" }}>{uMessage}</h1>
            </div>
          </div>
        </div>

      </section>

      <Loader loader={loader} />
    </>
  );
};

export default AccountVerified;
