import React from "react";
import integration1 from "../images/compress/integration1.webp";
import integration2 from "../images/compress/integration2.webp";
import integration3 from "../images/compress/integration3.webp";
import integration4 from "../images/compress/integration4.webp";
import intEffect1 from "../images/compress/intEffect1.webp";
import intEffect2 from "../images/compress/intEffect2.webp";



const In = () => {
    return(
        <>
        
        <section className="sec5" id="howWorks">

 <h1 className="inh1 animatable slideInUp">Simple Integration, Easy Payments</h1>

            <div className="container">
                {/* <div className="row">
                    <div className="col-lg-12">

                        <div className="inmain">

<div>
<div className="inback">
    <img className="inimg" src={frame1} />
</div>

<h5 className="h5in">Create Account</h5>
<p className="pcrea">Creating your merchant Siepay <br></br> account.</p>
</div>


<div>
<div className="inback">
    <img className="inimg" src={frame2} />
</div>

<h5 className="h5in">Add a Product</h5>
<p className="pcrea">Redirect customer to the generated link.<br></br></p>
</div>


<div>
<div className="inback">
    <img className="inimg" src={frame3} />
</div>

<h5 className="h5in">Dashboard</h5>
<p className="pcrea">Manage your customers <br></br> Get your data in order with our easy-to use analytics.</p>
</div>


</div>

                    </div>

                </div> */}
                <div className="row1">     
                    <div className="row ai-center">
                        <div className="col-sm-4 col-6">
                            <div className="imgCard animatable slideInRight">
                                <img className="image1" src={integration1} alt="" />
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="contentCard animatable slideInUp">
                                <h1>01</h1>
                                <h6>Create Account</h6>
                                <p>Create your merchant SiePay account for free.</p>
                            </div>
                        </div>
                    </div>
                    <img className="effect1" src={intEffect1} alt="" />
                </div>
                <div className="row1">     
                    <div className="row ai-center jc-fe-Sm">
                        <div className="col-8 order-2 order-sm-1">
                            <div className="contentCard text-right animatable slideInUp">
                                <h1>02</h1>
                                <h6>Add a Product</h6>
                                <p>On your SiePay Dashboard, Add a product with subscription price and set your receiving period.</p>
                            </div>
                        </div>
                        <div className="col-sm-4 col-6 order-1 order-sm-2">
                            <div className="imgCard animatable slideInLeft">
                                <img className="image2" src={integration2} alt="" />
                            </div>
                        </div>
                    </div>
                    <img className="effect1" src={intEffect2} alt="" />
                </div>
                <div className="row1">     
                    <div className="row ai-center">
                        <div className="col-sm-4 col-6">
                            <div className="imgCard animatable slideInRight">
                                <img className="image3" src={integration3} alt="" />
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="contentCard animatable slideInUp">
                                <h1>03</h1>
                                <h6>Embed Your Button</h6>
                                <p>Customize your payout button and embed in your site.</p>
                            </div>
                        </div>
                    </div>
                    <img className="effect1" src={intEffect1} alt="" />
                </div>
                <div className="row1">     
                    <div className="row ai-center jc-fe-Sm">
                        <div className="col-8 order-2 order-sm-1">
                            <div className="contentCard text-right animatable slideInUp">
                                <h1>04</h1>
                                <h6>Start Receiving</h6>
                                <p>Manage your customers & get your data in order with our easy to use dashboard. The user's wallet will be automatically deducted funds when they confirm a transaction.</p>
                            </div>
                        </div>
                        <div className="col-sm-4 col-6 order-1 order-sm-2">
                            <div className="imgCard animatable slideInLeft">
                                <img className="image4" src={integration4} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>


        
        </>
    )
}


export default In;