import React,{useEffect,useState} from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";

import "./subscription.css"
import api from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import copy from 'copy-to-clipboard';


// import axios from "axios";



// js

// import Navbar from './navbar';
// import Sidebar from './sidebar';

function Subscription(props) {
  const navigate = useNavigate()


  return(
    <>
      {/* Begin Page Content */}
      <div className="container-fluid subscripPage">
        <div className="row">
            <div className="col">
              <Table />
            </div>
        </div>
      </div>
      {/* /.container-fluid */}
       
    </>
  )
}


const Table=()=>{
  const userObject = useSelector((state) => state.userReducer.users);

  const [loader, setLoader] = useState(false);
  const [subscriptions, setSubscritpions] = useState([]);

  const [pageLimit, setPageLimit] = useState(3);
  const [pageSkip, setPageSkip] = useState(0);
  const [docCount, setDocCount] = useState(0);
  const [activeTab, setActiveTab] = useState(1);
  const [copyAddress, setCopyAddress] = useState("Copy Address");

    // fetch all subscription of product
    const fetchALlSubscription = async (skip) => {
      try {
        setLoader(true);
        const response = await api.getUserSubscribeAll({
          userID: userObject?.user?._id,
          skip:skip,
          pageSize:pageLimit
        });
  
        console.log(response);
        setSubscritpions([...subscriptions, ...response?.msg]);
        setLoader(false);
        setDocCount(response?.docCount)
        console.log(response?.docCount);
      } catch (e) {
        console.log(e);
        setLoader(false);
      }
    };

    // format date
    const formatDate = (dateParam) => {
      const date = new Date(dateParam);
      return date.toLocaleDateString();
    };

    const handleShowMore = async () => {
      setPageSkip(pageSkip+pageLimit)
      console.log(pageSkip + pageLimit);
      await fetchALlSubscription(pageSkip+ pageLimit);

    }
    const handleCopyAdr = (address) => {
      copy(address)
      setCopyAddress("copied")
      setTimeout(() => {
        setCopyAddress("Copy Address")
      }, 3000);
    }

    useEffect(()=>{
      fetchALlSubscription(0);
    },[])
  return(
                <div className=" sortby-box br-10 border-transparent "> 
                  <div className="m-pi-header">
                    <div className='d-flex justify-content-between flex-wrap'>
                      <h3 className='mb-0  mt-2'>Payment</h3>
                    </div>
                    <div className="pi-filters">
                      <div className={`filter ${activeTab === 1 && "active"}`} onClick={() => setActiveTab(1)}>All</div>
                      <div className={`filter ${activeTab === 2 && "active"}`} onClick={() => setActiveTab(2)}>Active</div>
                      <div className={`filter ${activeTab === 3 && "active"}`} onClick={() => setActiveTab(3)}>Pending</div>
                    </div>
                  </div>
                  <div className='card sortby-box br-10 border-transparent m-pi-data'>
                    <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
                        <table className="table table-hover table-striped m-pi-table">
                          <thead>
                            <tr>
                              <th scope="col">Product Name</th>
                              <th scope="col">Subscription Amount</th>
                              <th scope="col">Subscribed By</th>
                              <th scope="col">Start Time</th>
                              <th scope="col">End Time</th>
                              <th colSpan={1} scope="col">
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                          {subscriptions?.map((item) => (
                            <tr>
                              <td>
                                      <div className='font-500'>{item?.productId?.name}</div>
                              </td>
                              <td>
                                      <div>
                                        {item?.productId?.unitPrice ?
                                        <>
                                         {(parseFloat(item?.productId?.unitPrice) * item?.quantity) + parseInt(item?.productId?.amount) / 10 ** 18 + "$"}
                                        </>
                                        :
                                        <>
                                        {item?.productId?.amount / 10 ** 18 + "$"}
                                        </>
                                        }
                                      </div>
                              </td>
                              <td>

                                  <div className="copyLink">
                                    <div className='d-flex jc-center'>
                                      <span className='' onClick={() => handleCopyAdr(item?.address)}> {item?.address.slice(0,4)}...{item?.address.slice(item?.address.length-4,item?.address.length)}</span>
                                    </div>
                                    <div className="copyText">{copyAddress}</div>
                                  </div>

                              </td>
                              
                              <td>
                                      <div>{moment(item?.startDate).format("DD MMM YYYY")}</div>
                              </td>
                              <td>
                                      <div>{moment(item?.endDate).format("DD MMM YYYY")}</div>
                              </td>
                              <td>
                                {item?.status == 0 ?
                                  <div className='badge badge-warning'>Pending </div>
                                  : 
                                  <div className='badge badge-success'> Active</div>
                                }
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                        {docCount != subscriptions?.length && docCount >= pageLimit &&
                        <div className='showMoreMain text-center'>
                            <button className="gradBtn fs-12" onClick={handleShowMore}>
                              Show More
                              {/* <div className="loaderStyle"></div> */}
                            </button> 
                        </div>
                        }
                    </div>
                  </div>
                  </div>
  )
}

export {Subscription};
