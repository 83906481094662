import React,{useEffect,useState} from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";

import api from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import view from "../../../../images/view.png";

// import axios from "axios";
// js

// import Navbar from './navbar';
// import Sidebar from './sidebar';

function AdminMerchant(props) {
  const navigate = useNavigate()


  return(
    <>
      {/* Begin Page Content */}
      <div className="container-fluid subscripPage">
        <div className="row">
            <div className="col">
              <Table />
            </div>
        </div>
      </div>
      {/* /.container-fluid */}
       
    </>
  )
}


const Table=()=>{
  const userObject = useSelector((state) => state.userReducer.users);

  const [loader, setLoader] = useState(false);
  const [subscriptions, setSubscritpions] = useState([]);

  const [pageLimit, setPageLimit] = useState(3);
  const [pageSkip, setPageSkip] = useState(0);
  const [docCount, setDocCount] = useState(0);

    // fetch all subscription of product
    const fetchALlSubscription = async (skip) => {
      try {
        setLoader(true);
        const response = await api.getMerchantAll({});
  
        console.log(response);
        // setSubscritpions([...subscriptions, ...response?.merchant]);
        setSubscritpions(response?.merchant);
        setLoader(false);
        setDocCount(response?.docCount)
        console.log(response?.docCount);
      } catch (e) {
        console.log(e);
        setLoader(false);
      }
    };

    // format date
    const formatDate = (dateParam) => {
      const date = new Date(dateParam);
      return date.toLocaleDateString();
    };

    const handleShowMore = async () => {
      setPageSkip(pageSkip+pageLimit)
      console.log(pageSkip + pageLimit);
      await fetchALlSubscription(pageSkip+ pageLimit);

    }

    useEffect(()=>{
      fetchALlSubscription(0);
    },[])
  return(
                <div className="card sortby-box br-10 border-transparent "> 
                  <div className="card-header bg-white border-transparent pt-3 ">
                    <h3 className='mb-4'>Subscription Integration Guide</h3>
                    <div className="row1">
                        <div className="">
                          <button className='roundButton active'>All</button>
                          <button className='roundButton'>Active</button>
                          <button className='roundButton'>Pending</button>
                          {/* <button className='roundButton'>Expired</button> */}
                        </div>
                        <div>
                            {/* <button className="gradBtn fs-12 ">+ Create Subscription</button> */}
                        </div>
                    </div>
                  </div>
                    <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Merchant Name</th>
                              <th scope="col">Merchant Email</th>
                              <th scope="col">Merchant Id</th>
                              <th scope="col">Register At</th>
                             
                              <th colSpan={1} scope="col">
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                          {subscriptions?.map((item) => (
                            <tr>
                              <td>
                                      <div className='font-500'>{item?.userID?.username}</div>
                              </td>
                              <td>
                                    <div>{item?.userID?.email}</div>
                              </td>
                              <td>
                                    <div>{item?.merchantID}</div>
                              </td>
                              
                              <td>
                                      <div>{moment(item?.timestamp).format("DD MMM YYYY")}</div>
                              </td>
                              <td>
                                <Link to={`/admin/merchant/${item?.userID?._id}`}>
                                    <img style={{width:25}} src={view} alt="" />
                                </Link>
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                        {docCount != subscriptions?.length && docCount >= pageLimit &&
                        <div className='showMoreMain text-center'>
                            <button className="gradBtn fs-12" onClick={handleShowMore}>
                              Show More
                              {/* <div className="loaderStyle"></div> */}
                            </button> 
                        </div>
                        }
                    </div>
                  </div>
  )
}

export {AdminMerchant};
