import React from "react";
import img4 from "../images/compress/imgsec4.webp";

const Enable = () => {
  return (
    <>

    <section className="sec4">


      <div className="container">
        <div className="row ai-center">
         

          <div className="col-lg-6">
            <div className="">
              <h1 className="h1enable animatable slideInUp">Make Recurring Payments</h1>
              <p className="pmerchant animatable slideInUp">Cryptocurrencies offer a secure and convenient way to pay for goods and services online. SiePay allows customers to pay for goods or services on a regular basis using crypto currencies. This type of payment can provide several benefits for both the customer and the merchant, including convenience, security, and reduced transaction costs.</p> 
              <button className="btn1 animatable slideInUp"> Get Started </button>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img4 animatable slideInLeft">
              <img style={{width:"100%"}} src={img4} />
            </div>
          </div>

        </div>
      </div>

      </section>
    </>
  );
};

export default Enable;
