import React, {useEffect, useState  } from 'react';
import { BrowserRouter, Routes, Route, useNavigate,Navigate ,useLocation, useParams } from "react-router-dom";
import { CustomerProduct } from '../../customer/product/product';
import { Transaction } from '../../customer/transaction/transaction';
import { UpcomingTx } from '../../customer/upcoming/upcoming';
import { AdminMerchant } from '../admin/adminMerchant/adminMerchant';
import { AdminMerchantDetail } from '../admin/adminMerchant/merchantDetail';

import  {Dashboard}  from '../dashboard/dashboard';
import { GetStarted } from '../getStarted/getStarted';
import Navbar from '../global/navbar/navbar';
import CustomerSidebar from '../global/sidebar/customerSidebar';
import Sidebar from '../global/sidebar/sidebar';


function CustomerRouter(){
    const {next} = useParams();
    const {id} = useParams();

    const navigation = useNavigate();
    useEffect(()=>{
        // if(location.pathname == "/"){
        //     navigation("/dashboard")
        // }
    },[])
    console.log(id);

    return(
    

           <div id="wrapper">
           <CustomerSidebar />
             <div id="content-wrapper" className="d-flex flex-column">
               <div id="content">
                 <Navbar />
                {next == "overview" &&
                   <Dashboard />
                }
                {next == "product" &&
                   <CustomerProduct />
                }
                {next == "upcoming" &&
                   <UpcomingTx />
                }
                {next == "transaction" &&
                   <Transaction />
                }
               
                {/* {next == "profile" &&
                   <Profile />
                } */}
              
                {/* <Routes>
                  
                    <Route exact path='/dashboard/overview' element={<Dashboard/>} />
                </Routes> */}

               
             </div>
         </div>
       </div>
    )
}

export default CustomerRouter