import React, { useEffect, useState } from "react";
import dollar from "../images/dollar.png";
import dynamic from "../images/dynamic.png";
import data_im from "../images/data.png";
import star from "../images/star.png";
import eye from "../images/eye.png";
import Footer from "./Footer";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { useDispatch, useSelector } from "react-redux";
import userActions from "../redux/users/action";
import { Loader } from "./Loader";
import axios from "axios";
import { useAlert } from "react-alert";


import logo from "../images/updated-img/logo.png"



const Signin = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { setUser } = userActions;
  const user = useSelector((state) => state.userReducer.users);

  useEffect(() => {
    if (user.token) {
      navigate("/");
    }
  }, [])

  const onSubmit = async () => {
    try {
      setLoader(true);
      const validatorResponse = await validation();

      if (validatorResponse) {
        throw new Error(validatorResponse);
      }

      const payload = {
        username: data.username,
        password: data.password,
      };
      const response = await api.login(payload);
      console.log(response);
      setLoader(false);
      if (response?.msg == "Please verify your mail first") {
        return alert.show(response.msg);
      }
      if (response?.error) {
        throw new Error(response?.error);
      }

      alert.show(response.msg);
      dispatch(setUser(response));
      axios.defaults.headers.common["Authorization"] = response.token;
      console.log("token:" + response.token);
      if (response.user.is2FA) {
        navigate("/twofa");
        localStorage.setItem("twofa", false)
      } else if (response.user.isAdmin) {
        navigate("/admin/get-started")
        localStorage.setItem("twofa", true)
      }
      else {
        navigate("/dashboard/get-started")
        localStorage.setItem("twofa", true)
      }
    } catch (e) {
      setLoader(false);
      console.log('====================================');
      console.log(e);
      console.log('====================================');
      alert.show("Invalid Credentials");
    }
  };

  const validation = async () => {
    if (!data.username) {
      return "Username is required";
    }
    if (!data.password) {
      return "Password is required";
    }
    return false;
  };

  const onInputChange = async (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <>
      <section className="page2sec2">
        {/* <Header2 /> */}

        <div className="container-fluid px-0">
          <div className="row mx-auto m-signin">
            <div className="col-lg-6 pt-5 order-lg-1 order-2 px-5 m-signin-content">
              <div className="m-logo">
                <img src={logo} alt="logo" />
              </div>

              <div className="m-sign-text">
                <h1>
                  Stay on top of your finances.
                  <br />
                  Don't leave that up to others.
                </h1>
                <p>- Leif Garrett</p>
              </div>
              {/* <h1 style={{ color: "white" }}>Sign In</h1>
              <p style={{ color: "white" }}>
                A cryptocurrency recurring payment system is a way to automate payments using DeFi wallets.
              </p>

              <div className="flex">
                <div>
                  <img className="dollar" src={dollar} />
                </div>
                <div>
                  <h3 style={{ color: "white" }}>Easy Integration</h3>
                  <p style={{ color: "rgb(187 245 225)" }}>
                    With a few clicks you can integrate with this system. No coding experience required!
                  </p>
                </div>
              </div>

              <div className="flex">
                <div>
                  <img className="dollar" src={dynamic} />
                </div>
                <div>
                  <h3 style={{ color: "white" }}>Gateway for Merchant's</h3>
                  <p style={{ color: "rgb(187 245 225)" }}>
                    You can send invoices and manage payments with our powerful dashboard. Get set up in minutes!
                  </p>
                </div>
              </div> */}
            </div>






            {/* COLUMN #2 */}
            <div className="col-lg-6 order-lg-2 order-1 px-0">
              <div className="m-signin-sec2">

                <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
                <h2>Sign In Your </h2>
                <h2>Merchant Account</h2>

                <p>New User? <span onClick={() => navigate("/signup")} style={{ cursor: "pointer" }}>Create your account</span></p>
                  <div className="form-input">
                    <input
                      onChange={onInputChange}
                      name="username"
                      className="secdiv"
                      placeholder="Username or Email"
                    />
                  </div>
                  <div className="form-input">
                    <input
                      className="secdiv"
                      onChange={onInputChange}
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </div>

                  <div className="m-forget">
                    <p className="forgot" style={{ cursor: "pointer" }} onClick={() => navigate("/forget")}>Forget Password?</p>
                  </div>

                  <div className="text-center m-signin-submit">
                    <button onClick={onSubmit} className="crebtn1">
                      Sign In
                    </button>
                  </div>

                </form>


              </div>


              {/* <h1 className="signinh1">SignIn Your Merchant Account</h1> */}
              {/* <p onClick={() => navigate("/signup")} className="sigupp">
                Do not have an account? <span>Sign up</span>
              </p> */}

              {/* <div>
                <input
                  onChange={onInputChange}
                  name="username"
                  className="secdiv"
                  placeholder="Username"
                />
              </div> */}

              {/* <div>
                <div>
                  <input
                    className="secdiv"
                    onChange={onInputChange}
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                </div>
              </div> */}

              {/* <p className="ppp">
                Protected by reCAPTCHA and subject to the <span> Siepay Privacy Policy </span>
                and <span> Terms of Service.</span>
              </p> */}

              {/* <div className="">
                <div>
                  <p className="forgot" style={{ cursor: "pointer" }} onClick={() => navigate("/forget")}>Forgot Password?</p>
                </div>

                <div className="text-center">
                  <button onClick={onSubmit} className="crebtn1">
                    Login Now
                  </button>
                </div>
              </div> */}



            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </section>

      <Loader loader={loader} />
    </>
  );
};

export default Signin;
