import { useState, useEffect } from "react";
import api from "../api/index";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "./Loader";
import moment from "moment";

function ActivityLogs() {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false);
    const userObject = useSelector((state) => state.userReducer.users);
    // console.log(userObject.user);

    const fetchAllLogs = async () => {
        try {
            setLoader(true);
            const response = await api.fetchLogs(userObject?.user?._id);
            console.log(response?.logs)
            if (response?.logs.length > 0) {
                setData(response?.logs);
            }
            setLoader(false);

        } catch (e) {
            setLoader(false);
            console.log(e);
        }
    };

    const dateFormat = (datePram) => {
        const date = new Date(datePram);
        return moment(date).format('MMM DD YYYY, h:mm:ss a')
        // return date.toLocaleString();
    };


    useEffect(() => {
        fetchAllLogs()

    }, [])


    return (
        <>
         <div className="container-fluid subscripPage">
            <div className="row">
                <div className="col">

                    <div className="sortby-box br-10 border-transparent ">
                        <div className="m-pi-header mb-3">
                            <div className='d-flex justify-content-between flex-wrap'>
                            <h3 className='mb-0  mt-2'>Activity Logs</h3>
                            </div>
                        </div>
                        <div className='card sortby-box br-10 border-transparent m-pi-data'>
                            <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
                                <table className="table table-hover table-striped m-pi-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Logged In</th>
                                        <th scope="col">Device Name</th>
                                        <th scope="col">Start Time</th>
                                        <th scope="col">End Time</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.length > 0 ?
                                            data.map((item, i) => {
                                                return (
                                                    <tr>
                                                        <td>{item?.ipAddress}</td>
                                                        <td>{item?.device}</td>
                                                        <td>
                                                            {/* {dateFormat(item?.createdAt).slice(0, 11)} */}
                                                            <div className="fullDate">{dateFormat(item?.createdAt)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {
                                                                item.status ? "On-going Session" :
                                                                    <div className="dateTool">
                                                                        {/* {dateFormat(item?.updatedAt).slice(0, 11)} */}
                                                                        <div className="fullDate">{dateFormat(item?.updatedAt)}</div>
                                                                    </div>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                item.status ?
                                                                    <div className="badge bg-success text-light">Active</div>
                                                                    :
                                                                    <div className="badge bg-danger text-light">Ended</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={5}>
                                                    <h4>Nothing Found</h4>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Loader loader={loader} />
                </div>
            </div>
         </div>

        </>
    )
}


export default ActivityLogs;