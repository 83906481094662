import React,{useEffect,useState} from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";

import "./setting.css"


// import axios from "axios";



// js

// import Navbar from './navbar';
// import Sidebar from './sidebar';

function Settings(props) {
  const navigate = useNavigate()
  const [notifications, setNotifications] = useState(false);
  const [Inspayment, setInsPayment] = useState(false);
  const [successfullPay , setSuccessfullPay ] = useState(false);
  const [fee , setFee ] = useState(false);
  const [payReview , setPayReview ] = useState(false);
  const [Invoice , setInvoice ] = useState(false);
  const [bankAccount , setBankAccount ] = useState(false);


  return(
    <>
      {/* Begin Page Content */}
      <div className="container-fluid settingPage">
        <div className="row">
            <div className="col">
                <div className="card sortby-box br-10 border-transparent "> 
                  <div className="card-header bg-white border-transparent pt-3 ">
                    <h3 className='mb-4'>Account Settings</h3>
                    <div className="row1">
                        <div className="">
                          <button className='roundButton active'>All</button>
                          <button className='roundButton'>Active</button>
                          <button className='roundButton'>Pending</button>
                          <button className='roundButton'>Expired</button>
                        </div>
                      
                    </div>
                  </div>
                    <div className="card-header br-10 bg-white border-transparent ">
                        <div className="sRowM">
                            <h6>Notifications</h6>
                            <div className="sRow mt-4">
                                <div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</p>
                                </div>
                                <div className={notifications ?"toggleBtn bgToggle":"toggleBtn"} onClick={()=>setNotifications(!notifications)} >
                                    <div className={notifications ?"ml-20 circle":"circle"} ></div>
                                </div>
                            </div>
                        </div>
                        <div className="sRowM">
                            <h6>Install Payment Notifications</h6>
                            <div className="sRow mt-4">
                                <div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</p>
                                </div>
                                <div className={Inspayment ?"toggleBtn bgToggle":"toggleBtn"} onClick={()=>setInsPayment(!Inspayment)} >
                                    <div className={Inspayment ?"ml-20 circle":"circle"} ></div>
                                </div>
                            </div>
                        </div>
                        <div className="sRowM">
                            <h6>Successfull Payments</h6>
                            <div className="sRow mt-4">
                                <div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</p>
                                </div>
                                <div className={successfullPay ?"toggleBtn bgToggle":"toggleBtn"} onClick={()=>setSuccessfullPay(!successfullPay)} >
                                    <div className={successfullPay ?"ml-20 circle":"circle"} ></div>
                                </div>
                            </div>
                        </div>
                        <div className="sRowM">
                            <h6>Application Fees</h6>
                            <div className="sRow mt-4">
                                <div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</p>
                                </div>
                                <div className={fee ?"toggleBtn bgToggle":"toggleBtn"} onClick={()=>setFee(!fee)} >
                                    <div className={fee ?"ml-20 circle":"circle"} ></div>
                                </div>
                            </div>
                        </div>
                        <div className="sRowM">
                            <h6>Payment Reviews</h6>
                            <div className="sRow mt-4">
                                <div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</p>
                                </div>
                                <div className={payReview ?"toggleBtn bgToggle":"toggleBtn"} onClick={()=>setPayReview(!payReview)} >
                                    <div className={payReview ?"ml-20 circle":"circle"} ></div>
                                </div>
                            </div>
                        </div>
                        <div className="sRowM">
                            <h6>Invoice</h6>
                            <div className="sRow mt-4">
                                <div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</p>
                                </div>
                                <div className={Invoice ?"toggleBtn bgToggle":"toggleBtn"} onClick={()=>setInvoice(!Invoice)} >
                                    <div className={Invoice ?"ml-20 circle":"circle"} ></div>
                                </div>
                            </div>
                        </div>
                        <div className="sRowM">
                            <h6>Bank Account</h6>
                            <div className="sRow mt-4">
                                <div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</p>
                                </div>
                                <div className={bankAccount ?"toggleBtn bgToggle":"toggleBtn"} onClick={()=>setBankAccount(!bankAccount)} >
                                    <div className={bankAccount ?"ml-20 circle":"circle"} ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      {/* /.container-fluid */}
       
    </>
  )
}




export {Settings};
