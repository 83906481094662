import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import Web3 from "web3";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { ethers } from "ethers";

import api from "../../../api/index";
import SubscriptionContract from "../../../abi//subscription.json";
import { Loader } from "../../Loader";
import bigNumber from "bignumber.js";
import { useAlert } from "react-alert";
import copy from 'copy-to-clipboard';

import Modal from 'react-bootstrap/Modal';
import chevronDown from "../../../images/chevronDown.svg";
import close from "../../../images/close.svg";
import addProduct from "../../../images/addProduct.png";
import ethIco from "../../../images/ethIco.png";
import bscIco from "../../../images/bscIco.png";
import polygonIco from "../../../images/polygonIco.svg";
import tronIco from "../../../images/tronIco.png";
import usdtIco from "../../../images/usdtIco.png";
import usdcIco from "../../../images/usdcIco.png";
import busdIco from "../../../images/busdIco.png";
import view from "../../../images/view.png";

import copyicon from "../../../images/link.png";
import iframelinkicon from "../../../images/photo.png";
import editIco from "../../../images/editIco.svg";
import checkIcon from "../../../images/updated-img/check-icon.png";

import "./product.css"
import web3Object from '../../../web3/web3';
import USDTContract from "../../../abi/usdt.json";

import { getAccount, web3Initializer } from '../../../helpers/metamask';
import { ConnectModal } from '../../Checkout';

function Product() {
  const navigate = useNavigate()


  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid subscripPage">
        <div className="row">
          <div className="col">
            <Table />
          </div>
        </div>
      </div>
      {/* /.container-fluid */}

    </>
  )
}


const Table = () => {
  const userObject = useSelector((state) => state.userReducer.users);
  const alert = useAlert()
  const [productData, setProductData] = useState({
    network: "eth",
    currency: "usdt"
  });
  const [editData, setEditData] = useState({});
  const [viewData, setViewData] = useState({});

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const [showView, setShowView] = useState(false);
  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);

  const [copyLink, setCopyLink] = useState("Copy Link");
  const [copyCode, setCopyCode] = useState("Copy Embed Code");
  const [copyAddress, setCopyAddress] = useState("Copy Address");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);
  const [docCount, setDocCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [step, setStep] = useState(1);

  const [wallet, setWallet] = useState(null);
  const [approved, setApprove] = useState(true);



  //  To Fetch All Products
  const fetchAllProducts = async (skip) => {
    try {
      setLoader(true);
      let fil = {
        skip: skip,
        pageSize: pageLimit
      }
      const response = await api.getAllProduct(fil);
      if (response.product) {
        setData([...data, ...response?.product]);
        setLoader(false);
        setDocCount(response?.docCount)
        console.log(response?.docCount);
        console.log(response?.product);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }
  // Product validation - add
  const productValidation = () => {
    console.log('====================================');
    console.log(validURL(productData.webhookUrl));
    console.log('====================================');
    if (!productData.name) {
      return "Name is required";
    }
    if (!productData.address) {
      return "Address is required";
    }
    if (productData.address.length != 42) {
      return "Please add valid ethereum address";
    }
    if (!productData.network) {
      return "Network is required";
    }
    if (!productData.currency) {
      return "Currency is required";
    }

    if (!productData.unit) {
      return "Time is required";
    }
    if (productData.webhookUrl && !validURL(productData.webhookUrl)) {
      return "Incorrect URL";
    }
    if (!productData.type) {
      return "Type is required";
    }
    if (!productData.amount) {
      return "Amount is required";
    }
    if (productData.amount && isNaN(productData.amount)) {
      return "Amount is not correct";
    }

    if (!productData.unitPrice && productData.type == "dynamic") {
      return "Unit Price is required";
    }
    if (productData.unitPrice && isNaN(productData.unitPrice)) {
      return "Unit Price is not correct";
    }

    return false;
  };

  const setTimePeriod = () => {
    var newPeriodType = "";
    var newUnit = "";
    if (productData.unit == "onetime") {
      newPeriodType = 1
      newUnit = 1
    }
    if (productData.unit == "5-minutes") {
      newPeriodType = 2
      newUnit = 5
    }
    if (productData.unit == "10-minutes") {
      newPeriodType = 2
      newUnit = 10
    }
    if (productData.unit == "weekly") {
      newPeriodType = 5
      newUnit = 1
    }
    if (productData.unit == "bi weekly") {
      newPeriodType = 5
      newUnit = 2
    }
    if (productData.unit == "monthly") {
      newPeriodType = 6
      newUnit = 1
    }
    if (productData.unit == "bi monthly") {
      newPeriodType = 6
      newUnit = 2
    }
    if (productData.unit == "quaterly") {
      newPeriodType = 6
      newUnit = 4
    }
    if (productData.unit == "half yearly") {
      newPeriodType = 6
      newUnit = 6
    }
    if (productData.unit == "yearly") {
      newPeriodType = 7
      newUnit = 1
    }
    return { newUnit: newUnit, newPeriodType: newPeriodType }
  }
  // add product func
  const addProductFunc = async () => {
    try {
      console.log(productData);
      const validation = await productValidation();

      if (validation) {
        return alert.show(validation);
      }
      setLoader(true);
      let { newPeriodType, newUnit } = await setTimePeriod()
      const price = new bigNumber(productData.amount * 1000000000000000000);
      const payload = {
        ...productData,
        amount: price,
        PeriodType: newPeriodType,
        unit: newUnit,
      };
      console.log(payload);
      console.log(parseInt(payload.amount));

      // const provider = new ethers.BrowserProvider(window.ethereum)

      // await provider.send("eth_requestAccounts", []);
      // const signer = await provider.getSigner()

      // web3 function
      // const subsContract = new ethers.Contract(
      //   process.env.REACT_APP_SUBSCRIPTION_ADDRESS,
      //   SubscriptionContract,
      //   signer
      // );
      const subsContract = new web3Object.web3.eth.Contract(
        SubscriptionContract,
        process.env.REACT_APP_SUBSCRIPTION_ADDRESS
      );
      // console.log(signer);
      console.log(subsContract);
      // console.log(subsContract.defaultBlock);
      const subscriptionResp = await subsContract.methods
      .createProduct(payload.PeriodType,payload.unit,payload.address,payload.amount)
      .send({from:wallet});
       
      // const subscriptionResp = await subsContract.createProduct(payload.PeriodType,payload.unit,payload.address,payload.amount.toString());
      // await subscriptionResp.wait()
      // web3 function
      console.log(subscriptionResp);
      console.log(subscriptionResp.events.NewProduct.returnValues._productid);
      
      let payloadDB = {
        ...payload,
        productId:subscriptionResp.events.NewProduct.returnValues._productid
      };
      const response = await api.createProduct(payloadDB);

      console.log(response.data);
      console.log(userObject);
      if (response.error) {
        setLoader(false);
        return alert.show("Error while adding");
      }
      else {
        setProductData({
          network: "eth",
          currency: "usdt"
        });
        //  setData([])
        let tempData = [...data]
        tempData.unshift(response?.data)
        setData(tempData);
        alert.show("Product added successfully");
        //  setTimeout(() => {
        //    window.location.reload()
        //  }, 500);
        // await fetchAllProducts(0);
        setLoader(false);
        handleClose()
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
      alert.show("Error while adding");
    }
  };

  // Generate Link of checkout
  const generateCopyLink = (item) => {
    const link =
      process.env.REACT_APP_CLIENT +
      "/product/checkout/?pid=" +
      item?._id;
    // navigator.clipboard.writeText(link);
    copy(link)
    alert.show("Link generated and copied");
    setCopyLink("copied")
    setTimeout(() => {
      setCopyLink("Copy Link")
    }, 3000);
  };

  // Generate IFRAME
  const generateIframe = (item) => {
    const iframe = `<iframe src="${process.env.REACT_APP_CLIENT}/product/checkout/?pid=${item?._id}" name="SiePay" scrolling="auto" width="500px" height="400px" style="border: 0.5px groove #3b3b3b;"></iframe>`;
    // navigator.clipboard.writeText(iframe);
    copy(iframe)

    alert.show("Iframe generated and copied");
    setCopyCode("copied")
    setTimeout(() => {
      setCopyCode("Copy Embed Code")
    }, 3000);
  };
  const handleCopyAdr = (address) => {
    copy(address)
    setCopyAddress("copied")
    setTimeout(() => {
      setCopyAddress("Copy Address")
    }, 3000);
  }
  //open edit product modal
  const openEditM = (item, index) => {
    console.log(item);
    setCurrentIndex(index)

    handleShowEdit()
    setEditData({ _id: item._id, name: item.name, webhookUrl: item?.webhookUrl })
  }
  //open view product modal
  const openViewM = (item) => {
    console.log(item);
    handleShowView()
    setViewData(item)
  }

  const submitEditProduct = async () => {
    console.log(editData);
    setLoader(true);

    let response = await api.editProduct(editData)
    console.log(response);
    if (response.error) {
      setLoader(false);
      return alert.show("Error while updating product.");
    }
    else {
      let tempData = [...data]
      tempData.splice(currentIndex, 1, response.data)
      setData(tempData)

      // setData([])
      alert.show("Product update successfully");
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1000);
      // await fetchAllProducts(0);
      setLoader(false);
      setProductData({
        network: "eth",
        currency: "usdt"
      });
      handleCloseEdit()
      // window.location.reload()
    }


  }
  const handleShowMore = async () => {
    setPageSkip(pageSkip + pageLimit)
    console.log(pageSkip + pageLimit);
    await fetchAllProducts(pageSkip + pageLimit);
  }
  useEffect(() => {
    fetchAllProducts(0)
    console.log("data");
  }, [userObject])


  // wallet code
  
  const connectMetamask = async () => {
    try {
      setLoader(true);
      await web3Initializer();

      const userWalletId = await getAccount();
      console.log(userWalletId);

      if (!userWalletId) {
        throw new Error("Unable to connect user, Metamask error.");
      }

      const chainId = await web3Object.web3.eth.getChainId();
      console.log(chainId);
      if (parseInt(chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
        alert.show("Please connect to Binance Test Network");
        throw new Error("Please connect to Binance Test Net");
      }

      const signature = await web3Object.web3.eth.personal.sign(
        `I am signing to create product from my account: ${userWalletId}`,
        userWalletId,
        ""
      );

      if (!signature) {
        throw new Error("Unable to connect user, Signature denied.");
      }
      setWallet(userWalletId);
      setLoader(false);
    } catch (e) {
      alert.show("Error while connection with Wallet");
      setLoader(false);
    }
  };

  const connectBitKeep = async () => {
    try {
      setLoader(true);

      const web3 = web3Object;
      const isBitKeepInstalled = window.isBitKeep && !!window.bitkeep.ethereum;
      if (!isBitKeepInstalled) {
        alert.show("Bitkeep wallet not found.");
        return;
      }

      const provider = window.bitkeep && window.bitkeep.ethereum;
      if (!provider) {
        alert.show("Bitkeep wallet provider found.");
        return;
      }

      const accs = await provider.request({
        method: "eth_requestAccounts",
      });


      // const ThisIsBitkeepWeb3 = await new Web3("https://data-seed-prebsc-1-s3.binance.org:8545/");
      const ThisIsBitkeepWeb3 = await new Web3(window.bitkeep.ethereum);
      web3.web3 = ThisIsBitkeepWeb3;

      const isConnecttowallet = await provider.isConnected();

      if (!isConnecttowallet) {
        alert.show("Bitkeep wallet not connected.");
        setLoader(false);
        return;
      }

      console.log(web3Object.web3);

      // const back = sessionStorage.getItem("path");
      let chainId = await web3Object.web3.eth.getChainId();
      if (parseInt(chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
        alert.show("Please connect to Binance Test Network");
        setLoader(false);
        throw new Error("Please connect to Binance Test Net");
      }

      const signature = await ThisIsBitkeepWeb3.eth.personal.sign(
        `I am signing to create product from my account: ${accs[0]}`,
        accs[0],
        ""
      );

      if (!signature) {
        throw new Error("Unable to connect user, Signature denied.");
      }

      setWallet(accs[0]);
      setLoader(false);

      console.log("accs", accs);
      console.log("chainId_", chainId);
      console.log("isConnecttowallet", isConnecttowallet);

    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  // mobile wllet provider
  // const providerOptions = new WalletConnectProvider({
  //   rpc: {
  //     56: "https://bsc-dataseed1.defibit.io/",
  //     97: "https://data-seed-prebsc-1-s1.binance.org:8545",
  //     1: "https://mainnet.infura.io/v3/3f2f5e20071a4cbe9dd30a0090ff6402",
  //     4: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  //     qrcode: true,
  //     pollingInterval: 12000,
  //   },
  //   chainId: 97,
  // });

  const handleWalletConnect = async () => {
    try {
      // setLoader(true);
      const providerOptions = await EthereumProvider.init({
        projectId: process.env.REACT_APP_WC_PROJECT_ID, // REQUIRED your projectId
        chains: [parseInt(process.env.REACT_APP_CHAIN_ID)], // REQUIRED chain ids
        showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal,
      });
      const web3 = web3Object;

      // setModalShow(false)
      await providerOptions.enable();
      setLoader(true);
      const ThisIsWCWeb3 = new Web3(providerOptions);
      web3.web3 = ThisIsWCWeb3;
      console.log(ThisIsWCWeb3);

      let accs = await ThisIsWCWeb3.eth.getAccounts();
      console.log(accs);


      let chainId = await ThisIsWCWeb3.eth.getChainId();
      if (parseInt(chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
        alert.show("Please connect to Binance Test Network");
        setLoader(false);
        throw new Error("Please connect to Binance Test Net");
      }

      const signature = await ThisIsWCWeb3.eth.personal.sign(
        `I am signing to create product from my account: ${accs[0]}`,
        accs[0],
        ""
      );

      if (!signature) {
        throw new Error("Unable to connect user, Signature denied.");
      }

      setWallet(accs[0]);
      setLoader(false);

      console.log("accs", accs);
      console.log("chainId_", chainId);
    }
    catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  function toFixedExp(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += (new Array(e + 1)).join('0');
      }
    }
    return x;
  }

  // wallet code
  return (
    <>
      {/* ------------------------- Musto Updates ------------------------- */}
      <div className="m-pi-header">
        <div className='d-flex justify-content-between flex-wrap'>
          <h3 className='mb-0  mt-2'>Products Integration Guide</h3>
          <button className="gradBtn fs-12 mt-2" onClick={handleShow}>Add a Product</button>
        </div>
        <div className="pi-filters">
          <div className={`filter ${activeTab === 1 && "active"}`} onClick={() => setActiveTab(1)}>All</div>
          <div className={`filter ${activeTab === 2 && "active"}`} onClick={() => setActiveTab(2)}>Active</div>
          <div className={`filter ${activeTab === 3 && "active"}`} onClick={() => setActiveTab(3)}>Pending</div>
        </div>
      </div>

      <div className="card sortby-box br-10 border-transparent m-pi-data">
        <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
          <table className="table table-hover table-striped m-pi-table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Price</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Address</th>
                <th scope="col">Subscription Period</th>
                <th scope="col">Created</th>
                <th scope="col">Share</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, key) => (
                <tr key={key}>
                  <td>
                    <div className='font-500'>{item?.name}</div>
                  </td>
                  <td>
                    <div className='price'>${item?.amount / 10 ** 18}  </div>
                  </td>
                  <td>
                    <div>{item?.unitPrice ? "$" + item?.unitPrice : "-"}  </div>
                  </td>
                  <td>
                    <div className="copyLink">
                      <div className='d-flex jc-center'>
                        <span className='' onClick={() => handleCopyAdr(item?.address)}> {item?.address.slice(0,4)}...{item?.address.slice(item?.address.length-4,item?.address.length)}</span>
                      </div>
                      <div className="copyText">{copyAddress}</div>
                    </div>

                  </td>

                  <td>
                    <div>
                      {item?.PeriodType == 1 && item?.unit == 1 && "Onetime"}
                      {item?.PeriodType == 2 && item?.unit == 5 && "5-minutes"}
                      {item?.PeriodType == 2 && item?.unit == 10 && "10-minutes"}
                      {item?.PeriodType == 5 && item?.unit == 1 && "weekly"}
                      {item?.PeriodType == 5 && item?.unit == 2 && "bi weekly"}
                      {item?.PeriodType == 6 && item?.unit == 1 && "monthly"}
                      {item?.PeriodType == 6 && item?.unit == 2 && "bi monthly"}
                      {item?.PeriodType == 6 && item?.unit == 4 && "quaterly"}
                      {item?.PeriodType == 6 && item?.unit == 6 && "half yearly"}
                      {item?.PeriodType == 7 && item?.unit == 1 && "yearly"}
                    </div>
                  </td>
                  <td>
                    <div>{moment(item?.createdAt).format("DD MMM YYYY")}</div>
                  </td>
                  <td>
                    <div className="icons-cont">
                      <div className="copyLink">
                        <img className='mr-2' onClick={() => generateCopyLink(item)} src={copyicon} />
                        <div className="copyText">{copyLink}</div>
                      </div>
                      <div className="copyLink">
                        <img onClick={() => generateIframe(item)} src={iframelinkicon} />
                        <div className="copyText">{copyCode}</div>
                      </div>

                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <div className='editIco mr-2' onClick={() => openViewM(item)}>
                        <img style={{ width: 25 }} src={view} alt="" />
                      </div>
                      <div className='editIco' onClick={() => openEditM(item, key)}>
                        <img src={editIco} alt="" />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {
            docCount != data.length && docCount >= pageLimit &&
            <div className='showMoreMain text-center'>
              <button className="gradBtn fs-12" onClick={handleShowMore}> Show More </button>
            </div>
          }
        </div>
      </div>

      {/* ------------------------- END ------------------------- */}


      {/* <div className="card sortby-box br-10 border-transparent" style={{ marginTop: "250px" }}>
        <div className="card-header bg-white border-transparent pt-3 ">
          <div className="row1">
            <div className="">
              <h3 className='mb-0'>Products Integration Guide</h3>
            </div>
            <div>
              <button className="gradBtn fs-12 " onClick={handleShow}>Add a Product</button>
            </div>
          </div>
        </div>
        <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Price</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Name</th>
                <th scope="col">Address</th>
                <th scope="col">Subscription Period</th>
                <th scope="col">Created</th>
                <th scope="col">Share</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, key) => (
                <tr>
                  <td>
                    <div>${item?.amount / 10 ** 18}  </div>
                  </td>
                  <td>
                    <div>{item?.unitPrice ? "$" + item?.unitPrice : "-"}  </div>
                  </td>
                  <td>
                    <div className='font-500'>{item?.name}</div>
                  </td>
                  <td>
                    <div className="copyLink">
                      <div className='d-flex jc-center'>
                        <span className='limitedAddress' onClick={() => handleCopyAdr(item?.address)}> {item?.address}</span> ...
                      </div>
                      <div className="copyText">{copyAddress}</div>
                    </div>

                  </td>

                  <td>
                    <div>
                      {item?.unit == 0 && item?.time == 5 && "5-minutes"}
                      {item?.unit == 0 && item?.time == 10 && "10-minutes"}
                      {item?.unit == 3 && item?.time == 1 && "weekly"}
                      {item?.unit == 3 && item?.time == 2 && "bi weekly"}
                      {item?.unit == 4 && item?.time == 1 && "monthly"}
                      {item?.unit == 4 && item?.time == 2 && "bi monthly"}
                      {item?.unit == 4 && item?.time == 4 && "quaterly"}
                      {item?.unit == 4 && item?.time == 6 && "half yearly"}
                      {item?.unit == 5 && item?.time == 1 && "yearly"}
                    </div>
                  </td>
                  <td>
                    <div>{moment(item?.createdAt).format("DD MMM YYYY")}</div>
                  </td>
                  <td>
                    <div className="icons-cont">
                      <div className="copyLink">
                        <img className='mr-2' onClick={() => generateCopyLink(item)} src={copyicon} />
                        <div className="copyText">{copyLink}</div>
                      </div>
                      <div className="copyLink">
                        <img onClick={() => generateIframe(item)} src={iframelinkicon} />
                        <div className="copyText">{copyCode}</div>
                      </div>

                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <div className='editIco mr-2' onClick={() => openViewM(item)}>
                        <img style={{ width: 25 }} src={view} alt="" />
                      </div>
                      <div className='editIco' onClick={() => openEditM(item, key)}>
                        <img src={editIco} alt="" />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
          {docCount != data.length && docCount >= pageLimit &&
            <div className='showMoreMain text-center'>
              <button className="gradBtn fs-12" onClick={handleShowMore}>
                Show More
              </button>
            </div>
          }
        </div>
      </div> */}

      <Modal show={show} onHide={handleClose} centered={true}>
        <Modal.Body>
          <div className="m-product-modal pb-3" >
            <div className="closeIco" onClick={handleClose}><img src={close} alt="close" /></div>
            <div className='m-add-product-head'>
              <div className={`m-product-modal-tab ${step === 1 && "active"}`} onClick={() => setStep(1)} >Step 1</div>
              <div className={`m-product-modal-tab ${step === 2 && "active"}`} onClick={() => setStep(2)} >Step 2</div>
              <div className={`m-product-modal-tab ${step === 3 && "active"}`} onClick={() => setStep(3)} >Step 3</div>
            </div>

            <form className="m-signin-form productModal p-0" onSubmit={(e) => e.preventDefault()}>
              <h3 style={{ color: "#0F1F3B", textAlign: "center" }}>Add Product </h3>
              <p style={{ color: "#0F1F3B", textAlign: "center" }}>Subscription plan depend on what you need</p>

              {
                step === 1 ?
                  <>
                    <div className="form-input modalInput">
                      <input
                        value={productData?.name}
                        onChange={(e) =>
                          setProductData({ ...productData, name: e.target.value })
                        }
                        placeholder='Product Name' className="secdiv"
                        style={{ fontSize: "12px" }}
                      />
                    </div>
                    <div className="form-input modalInput">
                      <input
                        name='address'
                        value={productData?.address}
                        onChange={(e) =>
                          setProductData({ ...productData, address: e.target.value })
                        }
                        placeholder='Erc20 address'
                        className="secdiv"
                        style={{ fontSize: "12px" }}
                      />
                    </div>
                    <div className="d-flex">
                      <div className="form-group w-100 mr-2">
                        <div className="dropdown no-arrow nav-item ">
                          <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="profile-div w-100 ml-2 text-uppercase">
                              {productData?.network == "bsc" &&
                                <div className="Icoimg">
                                  <img className='' src={bscIco} alt="" />
                                </div>
                              }
                              {productData?.network == "eth" &&
                                <div className="Icoimg">
                                  <img className='' src={ethIco} alt="" />
                                </div>
                              }
                              {productData?.network == "polygon" &&
                                <div className="Icoimg">
                                  <img className='' src={polygonIco} alt="" />
                                </div>
                              }
                              {productData?.network == "tron" &&
                                <div className="Icoimg">
                                  <img className='' src={tronIco} alt="" />
                                </div>
                              }
                              {productData?.network ? productData?.network : "Please select Network"}
                            </div>
                            <div>
                              <img src={chevronDown} className="w-9 ml-1" alt="dropdown icon" />
                            </div>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, network: "eth" })}>
                              <div className="Icoimg">
                                <img className='' src={ethIco} alt="" />
                              </div>
                              Eth
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, network: "bsc" })}>
                              <div className="Icoimg">
                                <img className='' src={bscIco} alt="" />
                              </div>
                              Bsc
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, network: "polygon" })}>
                              <div className="Icoimg">
                                <img className='' src={polygonIco} alt="" />
                              </div>
                              Polygon
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, network: "tron" })}>
                              <div className="Icoimg">
                                <img className='' src={tronIco} alt="" />
                              </div>
                              Tron
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="form-group w-100">
                        <div className="dropdown no-arrow nav-item ">
                          <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="profile-div w-100 ml-2 text-uppercase">
                              {productData?.currency == "usdt" &&
                                <div className="Icoimg">
                                  <img className='' src={usdtIco} alt="" />
                                </div>
                              }
                              {productData?.currency == "usdc" &&
                                <div className="Icoimg">
                                  <img className='' src={usdcIco} alt="" />
                                </div>
                              }
                              {productData?.currency == "busd" &&
                                <div className="Icoimg">
                                  <img className='' src={busdIco} alt="" />
                                </div>
                              }
                              {productData?.currency ? productData?.currency : "Please select Currency"}
                            </div>
                            <div>
                              <img src={chevronDown} className="w-9 ml-1" alt="dropdown icon" />
                            </div>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, currency: "usdt" })}>
                              <div className="Icoimg">
                                <img className='' src={usdtIco} alt="" />
                              </div>
                              USDT
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, currency: "usdc" })}>
                              <div className="Icoimg">
                                <img className='' src={usdcIco} alt="" />
                              </div>
                              USDC
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, currency: "busd" })}>
                              <div className="Icoimg">
                                <img className='' src={busdIco} alt="" />
                              </div>
                              BUSD
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center m-signup-submit mt-2 ml-0">
                      <button className="crebtn1 ml-0" style={{ width: "80%" }} onClick={() => setStep(2)}> Continue </button>
                    </div>
                  </>
                  :
                  step === 2 ?
                    <>
                      <div className="form-group mb-0">
                        <div className="dropdown no-arrow nav-item ">
                          <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="profile-div w-100 ml-2" style={{ color: "#707070", fontSize: "12px" }}>
                              {productData?.unit ?
                                productData?.unit
                                : "Please select Time"}
                            </div>
                            <div>
                              <img src={chevronDown} className="w-9 ml-1" alt="dropdown icon" />
                            </div>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "onetime" })}>
                              Onetime
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "5-minutes" })}>
                              5-minutes
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "10-minutes" })}>
                              10-minutes
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "weekly" })}>
                              weekly
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "bi weekly" })}>
                              bi weekly
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "monthly" })}>
                              monthly
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "bi monthly" })}>
                              bi monthly
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "quaterly" })}>
                              quaterly
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "half yearly" })}>
                              half yearly
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "yearly" })}>
                              yearly
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="form-input modalInput">
                        <input
                          value={productData?.webhookUrl}
                          onChange={(e) =>
                            setProductData({ ...productData, webhookUrl: e.target.value })
                          }
                          name='webhookUrl'
                          placeholder='Webhook Url' className="secdiv"
                          style={{ fontSize: "12px" }}
                        />
                      </div>

                      <div className="text-center m-signup-submit mt-2 ml-0">
                        <button className="crebtn1 ml-0" style={{ width: "80%" }} onClick={() => setStep(3)}> Continue </button>
                      </div>
                    </>
                    :
                    <>
                      <div className='row justify-content-between'>
                        <div className="col-6 form-input modalInput">
                          <div className={`checkBoxModal ${productData.type === "fixed" && "active"}`} onClick={(e) => setProductData({ ...productData, type: "fixed" })}>
                            <p>Fixed Price</p>
                            <p className='opiop'><img src={checkIcon} alt="checkIcon" /></p>
                          </div>
                        </div>
                        <div className="col-6 form-input modalInput">
                          <div className={`checkBoxModal ${productData.type === "dynamic" && "active"}`} onClick={(e) => setProductData({ ...productData, type: "dynamic" })}>
                            <p>Dynamic Price</p>
                            <p className='opiop'><img src={checkIcon} alt="checkIcon" /></p>
                          </div>
                        </div>
                      </div>

                      <div className="form-input modalInput">
                        <input
                          value={productData?.amount}
                          onChange={(e) =>
                            setProductData({ ...productData, amount: e.target.value })
                          }
                          placeholder='Enter Price'
                          className="secdiv"
                          style={{ fontSize: "12px" }}
                        />
                      </div>
                      {
                        productData.type === "dynamic" &&
                        <div className="form-input modalInput">
                          <input
                            value={productData?.unitPrice}
                            onChange={(e) =>
                              setProductData({ ...productData, unitPrice: e.target.value })
                            }
                            placeholder='Enter Unit Price'
                            className="secdiv"
                            style={{ fontSize: "12px" }}
                          />
                        </div>
                      }

                      <div className="text-center m-signup-submit mt-2">
                        {!wallet && (
                          <div className="text-center m-signup-submit mt-4">
                            <ConnectModal mx={"auto"} connectMetamask={connectMetamask} connectBitKeep={connectBitKeep} walletConnect={handleWalletConnect} />
                          </div>
                        )}
                        {" "}
                     
                       {wallet && (
                          <div className="text-center m-signup-submit mt-4">
                            <button className="crebtn1 ml-0 mx-0" style={{ width: "80%" }} onClick={addProductFunc}>Save Product </button>
                          </div>
                        )}
                      </div>
                    </>
              }
            </form>
          </div>

        </Modal.Body>
      </Modal>

      {/* subscibe modal */}
      {/* <Modal show={show} onHide={handleClose} centered={true}>
        <Modal.Body>
          <div className="m-product-modal pb-3" >
            <div className="closeIco" onClick={handleClose}><img src={close} alt="close" /></div>

            <form className="m-signin-form productModal p-0" onSubmit={(e) => e.preventDefault()}>
              <h3 style={{ color: "#0F1F3B", textAlign: "center" }}>Add Product </h3>
              <p style={{ color: "#0F1F3B", textAlign: "center" }}>Subscription plan depend on what you need</p>
                  <>
                    <div className="form-input modalInput">
                      <input
                        value={productData?.name}
                        onChange={(e) =>
                          setProductData({ ...productData, productId: e.target.value })
                        }
                        placeholder='Product ID' className="secdiv"
                        style={{ fontSize: "12px" }}
                      />
                    </div>
                    <div className="form-group w-100 mr-2 mb-0">
                        <div className="dropdown no-arrow nav-item ">
                          <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="profile-div w-100 ml-2 text-uppercase">
                              {productData?.productId ? productData?.productId : "Please select Product ID"}
                            </div>
                            <div>
                              <img src={chevronDown} className="w-9 ml-1" alt="dropdown icon" />
                            </div>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                          
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, productId: "a1234" })}
                              >
                              a1234
                            </a>
                          </div>
                        </div>
                      </div>
                    <div className="d-flex">
                      <div className="form-group w-100 mr-2">
                        <div className="dropdown no-arrow nav-item ">
                          <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="profile-div w-100 ml-2 text-uppercase">
                              {productData?.network == "bsc" &&
                                <div className="Icoimg">
                                  <img className='' src={bscIco} alt="" />
                                </div>
                              }
                              {productData?.network == "eth" &&
                                <div className="Icoimg">
                                  <img className='' src={ethIco} alt="" />
                                </div>
                              }
                              {productData?.network == "polygon" &&
                                <div className="Icoimg">
                                  <img className='' src={polygonIco} alt="" />
                                </div>
                              }
                              {productData?.network == "tron" &&
                                <div className="Icoimg">
                                  <img className='' src={tronIco} alt="" />
                                </div>
                              }
                              {productData?.network ? productData?.network : "Please select Network"}
                            </div>
                            <div>
                              <img src={chevronDown} className="w-9 ml-1" alt="dropdown icon" />
                            </div>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, network: "eth" })}>
                              <div className="Icoimg">
                                <img className='' src={ethIco} alt="" />
                              </div>
                              Eth
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, network: "bsc" })}>
                              <div className="Icoimg">
                                <img className='' src={bscIco} alt="" />
                              </div>
                              Bsc
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, network: "polygon" })}>
                              <div className="Icoimg">
                                <img className='' src={polygonIco} alt="" />
                              </div>
                              Polygon
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, network: "tron" })}>
                              <div className="Icoimg">
                                <img className='' src={tronIco} alt="" />
                              </div>
                              Tron
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="form-group w-100">
                        <div className="dropdown no-arrow nav-item ">
                          <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="profile-div w-100 ml-2 text-uppercase">
                              {productData?.currency == "usdt" &&
                                <div className="Icoimg">
                                  <img className='' src={usdtIco} alt="" />
                                </div>
                              }
                              {productData?.currency == "usdc" &&
                                <div className="Icoimg">
                                  <img className='' src={usdcIco} alt="" />
                                </div>
                              }
                              {productData?.currency == "busd" &&
                                <div className="Icoimg">
                                  <img className='' src={busdIco} alt="" />
                                </div>
                              }
                              {productData?.currency ? productData?.currency : "Please select Currency"}
                            </div>
                            <div>
                              <img src={chevronDown} className="w-9 ml-1" alt="dropdown icon" />
                            </div>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, currency: "usdt" })}>
                              <div className="Icoimg">
                                <img className='' src={usdtIco} alt="" />
                              </div>
                              USDT
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, currency: "usdc" })}>
                              <div className="Icoimg">
                                <img className='' src={usdcIco} alt="" />
                              </div>
                              USDC
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, currency: "busd" })}>
                              <div className="Icoimg">
                                <img className='' src={busdIco} alt="" />
                              </div>
                              BUSD
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center m-signup-submit mt-2 ml-0">
                      <button className="crebtn1 ml-0" style={{ width: "80%" }} onClick={() => setStep(2)}> Continue </button>
                    </div>
                  </>
            </form>
          </div>

        </Modal.Body>
      </Modal> */}

      
        
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Body>
          <div className="productModal">
            <div className="closeIco" onClick={handleCloseEdit}><img src={close} alt="close" /></div>
            <div className="logoRow">
              <img src={addProduct} alt="" />
              <div className='ml-2'>
                <h6 className='mb-0'>Edit Product</h6>
                {/* <p className='mb-0'>Subscription plan depend on what you need</p> */}
              </div>
            </div>
            <div className="form-group">
              <label >Name</label>
              <input type="text"
                onChange={(e) =>
                  setEditData({ ...editData, name: e.target.value })
                }
                value={editData.name}
                placeholder='Enter product name' className='form-control' />
            </div>
            <div className="form-group">
              <label >Webhook Url <span className='optional'>(optional)</span></label>
              <div className="d-flex">
                <input type="text"
                  onChange={(e) =>
                    setEditData({ ...editData, webhookUrl: e.target.value })
                  }
                  value={editData.webhookUrl}
                  placeholder='Enter price' className='form-control' />
              </div>
            </div>

            <div className="btnMain text-center">
              <button className="gradBtn fs-12 " onClick={submitEditProduct}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* view modal */}
      <Modal show={showView} onHide={handleCloseView}>
        <Modal.Body>
          <div className="productModal">
            <div className="closeIco" onClick={handleCloseView}><img src={close} alt="close" /></div>
            <div className="logoRow">
              <img src={addProduct} alt="" />
              <div className='ml-2'>
                <h6 className='mb-0'>{viewData.name}</h6>
              </div>
            </div>
            <div className="form-group">
              <label >Address</label>
              <p className='mb-0'>{viewData?.address}</p>
            </div>
            <div className="form-group">
              <label >Network</label>
              <p className='mb-0'>
                <div className='text-left text-uppercase'>
                  <>
                    {viewData?.network == "bsc" &&
                      <div className="Icoimg">
                        <img className='' src={bscIco} alt="" />
                      </div>
                    }
                    {viewData?.network == "eth" &&
                      <div className="Icoimg">
                        <img className='' src={ethIco} alt="" />
                      </div>
                    }
                    {viewData?.network == "polygon" &&
                      <div className="Icoimg">
                        <img className='' src={polygonIco} alt="" />
                      </div>
                    }
                    {viewData?.network == "tron" &&
                      <div className="Icoimg">
                        <img className='' src={tronIco} alt="" />
                      </div>
                    }
                  </>
                  {viewData?.network}
                </div>
              </p>
            </div>
            <div className="form-group">
              <label >Currency</label>
              <p className='mb-0'>
                <div className='text-uppercase'>
                  <>
                    {viewData?.currency == "usdt" &&
                      <div className="Icoimg">
                        <img className='' src={usdtIco} alt="" />
                      </div>
                    }
                    {viewData?.currency == "usdc" &&
                      <div className="Icoimg">
                        <img className='' src={usdcIco} alt="" />
                      </div>
                    }
                    {viewData?.currency == "busd" &&
                      <div className="Icoimg">
                        <img className='' src={busdIco} alt="" />
                      </div>
                    }
                  </>
                  {viewData?.currency}
                </div>
              </p>
            </div>
            <div className="form-group">
              <label >Subscription Period</label>
              <p className='mb-0'>
                {viewData?.unit == 3 && viewData?.time == 1 && "weekly"}
                {viewData?.unit == 3 && viewData?.time == 2 && "bi weekly"}
                {viewData?.unit == 4 && viewData?.time == 1 && "monthly"}
                {viewData?.unit == 4 && viewData?.time == 2 && "bi monthly"}
                {viewData?.unit == 4 && viewData?.time == 4 && "quaterly"}
                {viewData?.unit == 4 && viewData?.time == 6 && "half yearly"}
                {viewData?.unit == 5 && viewData?.time == 1 && "yearly"}
              </p>
            </div>
            <div className="form-group">
              <label >Type</label>
              <p className='mb-0'>{viewData?.type}</p>
            </div>
            <div className="form-group">
              <label >Price</label>
              <p className='mb-0'>{viewData?.amount / 10 ** 18}</p>
            </div>
            {viewData?.unitPrice &&
              <div className="form-group">
                <label >Unit Price</label>
                <p className='mb-0'>{viewData?.unitPrice}</p>
              </div>
            }
            {viewData?.webhookUrl &&
              <div className="form-group">
                <label >Webhook Url</label>
                <p className='mb-0'>{viewData?.webhookUrl}</p>
              </div>
            }
            <div className="form-group">
              <label >Created At</label>
              <p className='mb-0'>{moment(viewData?.createdAt).format("DD MMM YYYY")}</p>
            </div>


          </div>
        </Modal.Body>
      </Modal>
      {/* Loader component */}
      <Loader loader={loader} />
    </>
  )
}

export { Product };