import React, {useEffect, useState  } from 'react';
import { BrowserRouter, Routes, Route, useNavigate,Navigate ,useLocation, useParams } from "react-router-dom";

import  {Dashboard}  from '../dashboard/dashboard';
import { GetStarted } from '../getStarted/getStarted';
import Navbar from '../global/navbar/navbar';
import Sidebar from '../global/sidebar/sidebar';
// import Sidebar from '../global/sidebar/newSidebar';
import { Payment } from '../payment/payment';
import { Product } from '../product/product';
import { Profile } from '../profile/profile';
import { Settings } from '../setting/setting';
import { Subscribers } from '../subscribers/subscribers';
import { Subscription } from '../subscription/subscription';
import ActivityLogs  from '../../activityLogs';


function AppRouter(){
    const {next} = useParams();
    const navigation = useNavigate();
    useEffect(()=>{
        // if(location.pathname == "/"){
        //     navigation("/dashboard")
        // }
    },[])

    return(

           <div id="wrapper">
           <Sidebar />
             <div id="content-wrapper" className="d-flex flex-column">
               <div id="content">
                 <Navbar />
                {next == "overview" &&
                   <Dashboard />
                }
                {next == "subscription" &&
                   <Subscription />
                }
                {next == "products" &&
                   <Product />
                }
                {next == "get-started" &&
                   <GetStarted />
                }
                {next == "settings" &&
                   <Settings />
                }
                {next == "activity-logs" &&
                   <ActivityLogs />
                }
                {next == "payment" &&
                   <Payment />
                }
                {next == "profile" &&
                   <Profile />
                }
                {next == "subscribers" &&
                   <Subscribers />
                }
                {/* <Routes>
                  
                    <Route exact path='/dashboard/overview' element={<Dashboard/>} />
                </Routes> */}

               
             </div>
         </div>
       </div>
    )
}

export default AppRouter