import React from "react";
import flogo from "../images/flogo.svg";
import { Link } from "react-router-dom";


const Footer = ({handleShow,handleShowTerms}) => {
  const scrollToView = (id) =>{
    setTimeout(() => {   
      let element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth"
      })
    }, 50);
  }

  return (
    <>
<section className="sec9">

      <div className="container">
        <div className="row">
          <div className="col-lg-12">

<div className="footermain">
    <div>
       <img src={flogo} />
       <br></br>
        <p className="ffp">The easiest crypto recurring payment system for <br></br> businesses & individuals.</p>
        <div className="cDetail">
          <p><span>Address:</span> B36 Block 9 FB Area, Karachi, Pakistan</p>
        </div>
        </div> 
          <div className="company">
            <h3>Company</h3>
            <p><Link to={"/"} className="cursor-pointer" onClick={()=>scrollToView("home")}>About us</Link></p>
            <p><Link to={"/"} className="cursor-pointer" onClick={()=>scrollToView("howWorks")}>How It Works?</Link></p>
            <p><Link to={"/"} className="cursor-pointer" onClick={()=>scrollToView("features")}>Features</Link></p>
            <p><Link to={"/protocol"} className="cursor-pointer" >Protocol</Link></p>
          </div>
          <div className="support">
            <h3>Support</h3>
            <p><Link to={"/"} className="cursor-pointer" onClick={()=>scrollToView("faq")}>FAQ's</Link></p>
            <p><Link to={"/contact"} className="cursor-pointer" >Contact Us</Link></p>
            <p><a onClick={()=>handleShow()} className="cursor-pointer">Privacy Policy</a></p>
            <p><a onClick={()=>handleShowTerms()} className="cursor-pointer">Terms of Conditions</a></p>
          </div>
        </div> 
        </div>
    </div>
  </div>

<p className="pfot">©2023 Siepay. All right reserved</p>
      </section>
    </>
  );
};

export default Footer;
