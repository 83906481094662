export const SET_CUSTOMER = "SET_CUSTOMER";

const action = {};

action.setCustomer = (payload) => {
  return {
    type: SET_CUSTOMER,
    payload,
  };
};

export default action;
