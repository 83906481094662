import React from "react";
import icon1 from "../images/compress/Icon1.webp";
import icon2 from "../images/compress/Icon2.webp";
import icon3 from "../images/compress/Icon3.webp";
import icon4 from "../images/compress/Icon4.webp";
import autoPay from "../images/compress/autoPay.webp";
import metaMask from "../images/compress/metaMask.webp";
import invoiceIco from "../images/compress/invoiceIco.webp";
import panel from "../images/compress/panel.webp";

const Siepay = () => {
  return (
    <>
      <div className="siepayFeatureSec" id="features">
        <h1 className="inh1">Siepay Exclusive Features</h1>
        <div className="row1">

          <div className="col1">
            <div className="fCard">
              <div className="imgDiv">
                <img src={autoPay} alt="" />
              </div>
              <h2>Autopay Enabled</h2>
            </div>
          </div>

          <div className="col2">
            <div className="fCard">
              <div className="imgDiv">
                <img src={metaMask} alt="" />
              </div>
              <h2>Web3 Wallet's Integration</h2>
            </div>
          </div>

        </div>
        <div className="row1">

          <div className="col1">
            <div className="fCard">
              <div className="imgDiv">
                <img src={invoiceIco} alt="" />
              </div>
              <h2>Dashboard Panel</h2>
            </div>
          </div>

          <div className="col2">
            <div className="fCard">
              <div className="imgDiv">
                <img src={panel} alt="" />
              </div>
              <h2>Invoice Generation</h2>
            </div>
          </div>

        </div>
      </div>
      {/* <section className="sec3" id="features">
        <img src={} alt="" className="effect" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
            <h1 className="inh1 features-sect">Siepay Exclusive Features</h1>

              <div className="siepaydiv">
                <div className="siesecdiv">
                  <div className="siediv11">
                    <img src={icon1} />
                    <p className="psie">Autopay Enabled</p>
                  </div>

                  <div className="siediv11">
                    <img src={icon2} />
                    <p className="psie">Metamask Integration</p>
                  </div>

                  <div className="siediv11">
                    <img src={icon3} />
                    <p className="psie">Subscription Panel</p>
                  </div>

                  <div className="siediv11">
                    <img src={icon4} />
                    <p className="psie">Invoice Generation</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Siepay;
