import React, { useEffect, useState } from "react";
import dollar from "../images/dollar.png";
import dynamic from "../images/dynamic.png";
import data_im from "../images/data.png";
import star from "../images/star.png";
import eye from "../images/eye.png";
import Footer from "./Footer";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { useDispatch, useSelector } from "react-redux";
import userActions from "../redux/users/action";
import { Loader } from "./Loader";
import axios from "axios";
import { useAlert } from "react-alert";

const ForgotPassword = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { setUser } = userActions;
  const user = useSelector((state) => state.userReducer.users);

  useEffect (() => {
    if (user.token) {
      navigate("/");
    }
  }, [])
  
  const onSubmit = async (e) => {
      e.preventDefault()
    console.log(data);
    try {
      setLoader(true);
      const validatorResponse = await validation();

      if (validatorResponse) {
        alert.show(validatorResponse);
      }

      const payload = {
        email: data?.email,
      };
      console.log(payload);
      const response = await api.forgetPass(payload);
      console.log(response);
      setLoader(false);

      if (response?.message == "Please verify your mail first") {
        return alert.show(response?.message);
      }
      if (response?.message == "incorrect email address") {
        return alert.show(response?.message);
      }
      if (response?.error) {
          return alert.show("Something went wrong");
      }
    if (response?.message == "Email sent") {
      return alert.show("Please check email, password link sent");
    }

    
    
} catch (e) {
    setLoader(false);
    console.log('====================================');
    console.log(e);
    console.log('====================================');
    alert.show("Something went wrong");
    }
  };

  const validation = async () => {
    if (!data.email) {
      return "Email is required";
    }
    return false;
  };

  const onInputChange = async (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <>
      <section className="page2sec2">
        <Header2 />

        <div className="container">
          <div className="row jc-center">

            <div className="col-lg-6  create1 ">
            <form onSubmit={(e)=>onSubmit(e)}>

              <h1 className="signinh1">Forget Password</h1>

              <p onClick={() => navigate("/signin")} className="sigupp">
                remember Password? <span>Sign in</span>
              </p>

              <div>
                <input
                  onChange={onInputChange}
                  name="email"
                  className="secdiv"
                  placeholder="Enter Email"
                  type={"email"}
                  required
                />
              </div>

                <div className="text-center">
                  <button type="submit" className="crebtn1 mt-3">
                    Submit
                  </button>
                </div>
            </form>
            </div>
          </div>
        </div>

        <Footer />
      </section>

      <Loader loader={loader} />
    </>
  );
};

export default ForgotPassword;
