import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer, } from 'recharts';
import "./dashboard.css"
import "../global/global.css"
import volume1 from "../../../images/volume1.png";
import volume2 from "../../../images/volume2.png";
import dotIco from "../../../images/dotIco.svg";
// import successIco from "../../../assets/images/successIco.png";
// import failedIco from "../../../assets/images/failedIco.png";
// import pendingIco from "../../../assets/images/pendingIco.png";
// import activeCircle from "../../../assets/images/activeCircle.png";
// import cancelCircle from "../../../assets/images/cancelCircle.png";
// import help from "../../../assets/images/alert-circle.svg";
import searchIcon from "../../../images/search.svg";
// import calendar from "../../../assets/images/calendar.svg";
import expenseIco from "../../../images/expenseIco.svg";
import chevronDown from "../../../images/chevronDown.svg";
import redArrow from "../../../images/redArrow.svg";
import arrowGreen from "../../../images/arrowGreen.svg";

import api from "../../../api";
import { useSelector } from 'react-redux';

// import axios from "axios";



// js

// import Navbar from './navbar';
// import Sidebar from './sidebar';

function Dashboard(props) {
  const navigate = useNavigate()
  const userObject = useSelector((state) => state.userReducer.users);
  const [totalAmount, setTotalAmount] = useState("");
  const [activeSubscribers, setActiveSubscribers] = useState("");
  const [transactionCount, setTransactionCount] = useState("");
  const [ActiveProductsCount, setActiveProductsCount] = useState("");
  const [currentMonthvolume, setCurrentMonthvolume] = useState("");

  // fetch all Overview data of merchant
  const fetchALlSubscription = async () => {
    try {
      // setLoader(true);
      const response = await api.getOverViewData({ userID: userObject?.user?._id, });
      console.log(response);
      setTotalAmount(response?.totalAmount)
      setActiveSubscribers(response?.activeSubscribers)
      setTransactionCount(response?.transactionCount)
      setActiveProductsCount(response?.ActiveProductsCount)
      setCurrentMonthvolume(response?.currentMonthvolume)
    } catch (e) {
      console.log(e);
      // setLoader(false);
    }
  };

  const redColor = {
    color: 'red',
    background: "#FFF0F0"
  }
  const greenColor = {
    color: '#28CB89',
    background: "rgba(8,184,130,0.15)"
  }

  const data2 = [
    {
      name: 'Jan',
      uv: 40,
      amt: 400,
    },
    {
      name: 'Feb',
      uv: 10,
      amt: 210,
    },
    {
      name: 'Mar',
      uv: 90,
      amt: 1290,
    },
    {
      name: 'Apr',
      uv: 120,
      amt: 1600,
    },

    {
      name: 'May',
      uv: 100,
      amt: 2500,
    },
    {
      name: 'Jun',
      uv: 34,
      amt: 2100,
    },
    {
      name: 'Jul',
      uv: 34,
      amt: 2100,
    },
    {
      name: 'Aug',
      uv: 34,
      amt: 2100,
    },
    {
      name: 'Sep',
      uv: 44,
      amt: 2100,
    },
    {
      name: 'Oct',
      uv: 84,
      amt: 2100,
    },
    {
      name: 'Nov',
      uv: 44,
      amt: 2100,
    },
    {
      name: 'Dec',
      uv: 34,
      amt: 2100,
    },
  ];

  useEffect(() => {
    fetchALlSubscription()
  }, [])

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid dashboardMain">
        <div className="balanceRow">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="title">Your Balance</div>
              <div className="balance">${totalAmount ? totalAmount / 10 ** 18 : 0}</div>
            </div>
            <div className="col-6 col-md-3 col-sm-6">
              <div className='asd3678'>
                <div className="name">Curency</div>
                <div className="val">
                  <strong> USD / US Dollar </strong>
                </div>
              </div>
            </div>
            <div className="col-3 col-md-3 col-sm-6">
              <div className='asd3678'>
                <div className="name">Status</div>
                <div className="val">
                  <strong> Active </strong>
                </div>
              </div>
            </div>
            <div className="col-3 col-md-3 col-sm-6">
              <div className='asd3678'>
                <div className="name">Exp</div>
                <div className="val">
                  <strong> 12/14 </strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card br-10 h-100 border-transparent overflow-hidden smChartRow">
          <div className="card-body">
            <div className="row1">
              <button className='roundButton active'>Weekly</button>
              <button className='roundButton'>Monthly</button>
              <button className='roundButton'>Yearly</button>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="smChartCard as1000">
                  <div className="title">Gross Volume</div>
                  <div className="amount">$50,435.362</div>
                  <div className="chartRow">
                    <div className="percent">
                      <img className='mr-1' src={redArrow} alt="" />
                      1.7%
                    </div>
                    <div className="chart">
                      <RevenueChart2 lineColor={"red"} data={data2} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="smChartCard as1000 hideborder">
                  <div className="title">Refund Volume</div>
                  <div className="amount">$50,435.362</div>
                  <div className="chartRow">
                    <div className="percent">
                      <img className='mr-1' src={redArrow} alt="" />
                      1.7%
                    </div>
                    <div className="chart">
                      <RevenueChart2 lineColor={"red"} data={data2} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="smChartCard as1000">
                  <div className="title">Customers</div>
                  <div className="amount">124</div>
                  <div className="chartRow">
                    <div className="percent green">
                      <img className='mr-1' src={arrowGreen} alt="" />
                      1.7%
                    </div>
                    <div className="chart">
                      <RevenueChart2 lineColor={"green"} data={data2} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="smChartCard as1000 hideborder" style={{ border: "none" }}>
                  <div className="title">Successful payments</div>
                  <div className="amount">58</div>
                  <div className="chartRow">
                    <div className="percent">
                      <img className='mr-1' src={redArrow} alt="" />
                      1.7%
                    </div>
                    <div className="chart">
                      <RevenueChart2 lineColor={"red"} data={data2} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col-md-6 col-sm-12 mb-4">
            <div className="card br-10 h-100 border-transparent overflow-hidden">
              {/* Card Header - Dropdown */}
              <div className="card-header dashChartRow bg-white border-transparent pt-3 row1 flex-row ">
                <div>
                  <h6 className="m-0 chart-heading">Money Flow</h6>
                </div>
                <div className='col2'>
                  <div className="dropdown no-arrow nav-item ">

                    <a className=" expenseDown dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img className="img-profile rounded-circle" src={expenseIco} />

                      <div className="profile-div ml-2">
                        <div className="mr-2 profile-name">Expenses</div>
                        {/* <span className="status">Active</span> */}
                      </div>
                      <div>
                        <img src={chevronDown} className="w-9 ml-1 mb-2" alt="dropdown icon" />
                      </div>

                    </a>
                    {/* Dropdown - User Information */}
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                      <a className="dropdown-item" href="#">
                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                        Profile
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                        Settings
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                        Activity Log
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                        Logout
                      </a>
                    </div>
                  </div>
                  <div className="dropdown no-arrow nav-item ml-3">

                    <a className=" expenseDown dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img className="img-profile rounded-circle" src={expenseIco} />

                      <div className="profile-div ml-2">
                        <div className="mr-2 profile-name">Monthly</div>
                        {/* <span className="status">Active</span> */}
                      </div>
                      <div>
                        <img src={chevronDown} className="w-9 ml-1 mb-2" alt="dropdown icon" />
                      </div>

                    </a>
                    {/* Dropdown - User Information */}
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                      <a className="dropdown-item" href="#">
                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                        Profile
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                        Settings
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                        Activity Log
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                        Logout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* Card Body */}
              <div className="card-body">
                <div className="chart-area">
                  <RevenueChart />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 mb-4">
            <div className="row">
              <AnalyticsBox title="Customers" percent="+ 20%" volume={volume2} color={greenColor} amount={transactionCount || 0} lastMonthAmount="Over last month: $15,600" />
              <AnalyticsBox title="Successful payments" percent="- 10%" volume={volume1} color={redColor} amount={"$" + currentMonthvolume ? currentMonthvolume / 10 ** 18 : 0} lastMonthAmount="Over last month: -2" />
              {/* <AnalyticsBox title="Active Subscribers" percent="+ 9%" color={greenColor} amount={activeSubscribers || 0} lastMonthAmount="Over last month: $3.05" />
                  <AnalyticsBox title="Active Products" color={greenColor} percent="+ 13%" amount={ActiveProductsCount || 0} lastMonthAmount="Over last month: 54" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function AnalyticsBox(props) {
  return (
    <div className="col-md-6 mb-3">
      <div className="card h-100 border-transparent br-10">
        <div className="card-body">
          <div className=" no-gutters align-items-center volumeCard">
            <div className="text-xs font-weight-bold ">
              <div className="iconRow">
                <img className='ico' src={props.volume} alt="" />
                <span className="analytic-percentage ms-1" style={props.color}>{props.percent}</span>
                {/* <img src={dotIco} alt="" /> */}
              </div>
              <span className="analytics-box-title">{props.title} </span>
            </div>
            <div className="h4 mb-0 amountRow">
              <div className="amount">
                {props.amount}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )

}


const Table = () => {
  return (
    <div className="card sortby-box br-10 border-transparent ">
      <div className="card-header bg-white border-transparent pt-3 d-flex flex-row align-items-center justify-content-between">
        <h6 className="m-0 diver-name">Transaction History</h6>
        <div>
          <span className="driver-status-title">See All</span>
        </div>
      </div>
      <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Transaction Details</th>
              <th scope="col">Product Name</th>
              <th scope="col">Status</th>
              <th scope="col">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">
                <div className="transactionDMain">
                  <div className="imgDiv">
                    image
                  </div>
                  <div className="to">
                    to
                  </div>
                  <div>
                    <div>0x89...98976</div>
                    <div>0x45...2389</div>
                  </div>
                </div>
              </td>
              <td>
                <div>Spotify with good and</div>
                <div>Nov 16, 2022 | 10:30pm</div>
              </td>
              <td>
                <button className="statusBtn success">SuccessFul</button>
              </td>
              <td>
                <p className='mb-0 w-mc'>
                  <div>0.0067 ETH</div>
                  <div className='ms-auto'>$95</div>
                </p>
              </td>
            </tr>
            <tr>
              <td scope="row">
                <div className="transactionDMain">
                  <div className="imgDiv">
                    {/* <img className='img-fluid' src={failedIco} alt="" /> */}
                    image
                  </div>
                  <div className="to">
                    to
                  </div>
                  <div>
                    <div>0x89...98976</div>
                    <div>0x45...2389</div>
                  </div>
                </div>
              </td>
              <td>
                <div>Spotify with good and</div>
                <div>Nov 16, 2022 | 10:30pm</div>
              </td>
              <td>
                <button className="statusBtn fail">FAILED</button>
              </td>
              <td>
                <p className='mb-0 w-mc'>
                  <div>0.0067 ETH</div>
                  <div className='ms-auto'>$95</div>
                </p>
              </td>

            </tr>
            <tr>
              <td scope="row">
                <div className="transactionDMain">
                  <div className="imgDiv">
                    {/* <img className='img-fluid' src={pendingIco} alt="" /> */}
                    image
                  </div>
                  <div className="to">
                    to
                  </div>
                  <div>
                    <div>0x89...98976</div>
                    <div>0x45...2389</div>
                  </div>
                </div>
              </td>
              <td>
                <div>Spotify with good and</div>
                <div>Nov 16, 2022 | 10:30pm</div>
              </td>
              <td>
                <button className="statusBtn pending">Pending</button>
              </td>
              <td>
                <p className='mb-0 w-mc'>
                  <div>0.0067 ETH</div>
                  <div className='ms-auto'>$95</div>
                </p>
              </td>

            </tr>
            <tr>
              <td scope="row">
                <div className="transactionDMain">
                  <div className="imgDiv">
                    {/* <img className='img-fluid' src={failedIco} alt="" /> */}
                    image
                  </div>
                  <div className="to">
                    to
                  </div>
                  <div>
                    <div>0x89...98976</div>
                    <div>0x45...2389</div>
                  </div>
                </div>
              </td>
              <td>
                <div>Spotify with good and</div>
                <div>Nov 16, 2022 | 10:30pm</div>
              </td>
              <td>
                <button className="statusBtn fail">FAILED</button>
              </td>
              <td>
                <p className='mb-0 w-mc'>
                  <div>0.0067 ETH</div>
                  <div className='ms-auto'>$95</div>
                </p>
              </td>

            </tr>
            <tr>
              <td scope="row">
                <div className="transactionDMain">
                  <div className="imgDiv">
                    {/* <img className='img-fluid' src={pendingIco} alt="" /> */}
                    image
                  </div>
                  <div className="to">
                    to
                  </div>
                  <div>
                    <div>0x89...98976</div>
                    <div>0x45...2389</div>
                  </div>
                </div>
              </td>
              <td>
                <div>Spotify with good and</div>
                <div>Nov 16, 2022 | 10:30pm</div>
              </td>
              <td>
                <button className="statusBtn pending">Pending</button>
              </td>
              <td>
                <p className='mb-0 w-mc'>
                  <div>0.0067 ETH</div>
                  <div className='ms-auto'>$95</div>
                </p>
              </td>

            </tr>







          </tbody>
        </table>
      </div>
    </div>
  )
}

function RevenueChart() {
  const data = [
    {
      name: 'Jan',
      uv: 40,
      pv: 24,
      amt: 2400,
    },
    {
      name: 'Feb',
      uv: 10,
      pv: 13,
      amt: 2210,
    },
    {
      name: 'Mar',
      uv: 90,
      pv: 98,
      amt: 2290,
    },
    {
      name: 'Apr',
      uv: 120,
      pv: 110,
      amt: 2000,
    },

    {
      name: 'May',
      uv: 100,
      pv: 90,
      amt: 2500,
    },
    {
      name: 'Jun',
      uv: 34,
      pv: 43,
      amt: 2100,
    },
    {
      name: 'Jul',
      uv: 34,
      pv: 43,
      amt: 2100,
    },
    {
      name: 'Aug',
      uv: 34,
      pv: 43,
      amt: 2100,
    },
    {
      name: 'Sep',
      uv: 44,
      pv: 53,
      amt: 2100,
    },
    {
      name: 'Oct',
      uv: 84,
      pv: 93,
      amt: 2100,
    },
    {
      name: 'Nov',
      uv: 44,
      pv: 43,
      amt: 2100,
    },
    {
      name: 'Dec',
      uv: 34,
      pv: 53,
      amt: 2100,
    },
  ];
  return (
    // <ResponsiveContainer width="100%" height="100%">
    <LineChart width={600} height={300} data={data} className="w-100 h-100">
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
      <YAxis />
      <Tooltip />
      {/* <Line type="monotone" dataKey="pv" stroke="#FF007A" stroke-width="2" activeDot={{ r: 8 }} /> */}
      <Line type="monotone" dataKey="uv" stroke="#00E8B0" stroke-width="2" />
    </LineChart>
    // </ResponsiveContainer>


  )

}

function RevenueChart2({ lineColor, data }) {

  return (
    <ResponsiveContainer width="100%" height={"100%"}>
      <LineChart layout="horizontal" data={data} >
        {/* <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#129a74" stopOpacity={0.1}/>
              <stop offset="95%" stopColor="#000" stopOpacity={0.1}/>
          </linearGradient>
      </defs> */}

        {/* <CartesianGrid  /> */}
        <XAxis dataKey="null" tickLine={false} axisLine={false} padding={{ left: 30, right: 30 }} />
        <YAxis tick={false} hide />
        <Tooltip />
        <CartesianGrid horizontal={false} vertical={false} />
        {/* <Line type="monotone" dataKey="pv" stroke="#FF007A" stroke-width="2" activeDot={{ r: 8 }} /> */}
        <Line type="monotone" dataKey="amt" fill="url(#colorUv)" stroke={lineColor} stroke-width="3" dot={false} activeDot={false} />
      </LineChart>
    </ResponsiveContainer>

  )

}


export { Dashboard };
