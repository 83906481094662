import React from 'react'
import { useAlert } from "react-alert";
import "./contact.css"
import Header from '../Header'
import ReCAPTCHA from "react-google-recaptcha";
import { PrivacyModal } from '../privacyModal';
import { TermsModal } from '../termsModal';
import { useState } from 'react';
import Footer from '../Footer';
import api from '../../api';

export const Contact = () => {
    const recaptchaRef = React.createRef();
    const alert = useAlert();
    const [formData, setformData] = useState({});
    const [show, setShow] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [stripInterest, setStripInterest] = useState("no");
    const [loopUpdates, setLoopUpdates] = useState("no");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseTerms = () => setShowTerms(false);
    const handleShowTerms = () => setShowTerms(true);


    function onChange(value) {
        console.log("Captcha value:", value);
      }
    function onChangeForm(e) {
        setformData({...formData,[e.target.name]:e.target.value})
    }
    function validate() {
        let check = false
        if( formData?.firstName || 
            formData?.lastName || 
            formData?.email || 
            formData?.projectName || 
            formData?.useFor || 
            formData?.business || 
            formData?.NoOfCustomer || 
            formData?.hearAboutUs || 
            formData?.website || 
            formData?.stripInterest || 
            formData?.loopUpdates 
        ){
            check= true
        }else{
            check= false
        }
        return check
    }
    async function  onSubmit() {
        let payload = {
            ...formData,
            stripInterest:stripInterest,
            loopUpdates:loopUpdates
        }
        console.log(payload);
        let check = validate()
        console.log(check);
        if(!check) {
            return alert.show("please fill all fields")
        }
        const recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue) {
            console.log(recaptchaValue);

              const response = await api.contact((payload));
              console.log(response);
              setformData({})
              alert.show("Thanks contacting us, team will connect you as soon as possible");
        }else{
             alert.show("Please fill captcha");
        }

    }
  return (
    <div className="contactPage homePage">
        <Header />
        <div className="">
            <div className="container">
                <div className="heading">Contact Us</div>
                <div className="title">Fill out the form below to stay with the Siepay. We'll get back to you right away if you're interested in a demo.</div>
                <div className="formMain">
                    <div className="row">
                        <div className="col-sm-6 inputM">
                            <label >First Name</label>
                            <input
                                name="firstName"
                                onChange={(e)=>onChangeForm(e)}
                                placeholder="Enter here"
                            />
                        </div>

                        <div className="col-sm-6 inputM">
                            <label>Last Name</label>
                            <input
                                name="lastName"
                                onChange={(e)=>onChangeForm(e)}
                                placeholder="Enter here"
                            />
                        </div>
                        <div className="col-sm-6 inputM">
                            <label>Email Address</label>
                            <input
                                name="email"
                                onChange={(e)=>onChangeForm(e)}
                                placeholder="Enter here"
                            />
                        </div>
                        <div className="col-sm-6 inputM">
                            <label>Project Name</label>
                            <input
                                name="projectName"
                                onChange={(e)=>onChangeForm(e)}
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                </div>

                <div className="formMain">
                    <div className="row">
                        <div className="col-sm-6 inputM">
                            <label >What do you want to use Siepay for?</label>
                            <select name="useFor" id="" onChange={(e)=>onChangeForm(e)}>
                                <option value="" > Select one</option>
                                <option value="one" >one</option>
                                <option value="Two" >Two</option>
                            </select>
                        </div>
                        <div className="col-sm-6 inputM">
                            <label >What best describes you or your business?</label>
                            <select name="business" id="" onChange={(e)=>onChangeForm(e)}>
                                <option value="" > Select one</option>
                                <option value="one" >one</option>
                                <option value="Two" >Two</option>
                            </select>
                        </div>
                        <div className="col-sm-6 inputM">
                            <label >How many customers do you have?</label>
                            <select name="NoOfCustomer" id="" onChange={(e)=>onChangeForm(e)}>
                                <option value="" > Select one</option>
                                <option value="one" >one</option>
                                <option value="Two" >Two</option>
                            </select>
                        </div>
                        <div className="col-sm-6 inputM">
                            <label >How did you first hear about us?</label>
                            <select name="hearAboutUs" id="" onChange={(e)=>onChangeForm(e)}>
                                <option value="" > Select one</option>
                                <option value="one" >one</option>
                                <option value="Two" >Two</option>
                            </select>
                        </div>
                        <div className="col-sm-6 inputM" >
                            <label >What is the website?</label>
                            <input
                                name="website"
                                onChange={(e)=>onChangeForm(e)}
                                placeholder="Enter URL"
                            />
                        </div>
                    </div>

                  
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <div className="formMain">
                            <label>Are you interested in the Stripe integration?</label>
                            <div className="yesNoBtn">
                                <button onClick={()=>setStripInterest("yes")} className={stripInterest == "yes"?'active mr-3':'mr-3'}>Yes</button>
                                <button onClick={()=>setStripInterest("no")} className={stripInterest == "no"?'active':''}>No</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="formMain">
                            <label>Do you want to stay in the Loop wit updates?</label>
                            <div className="yesNoBtn">
                                <button onClick={()=>setLoopUpdates("yes")} className={loopUpdates == "yes"?'active mr-3':'mr-3'}>Yes</button>
                                <button onClick={()=>setLoopUpdates("no")} className={loopUpdates == "no"?'active':''} >No</button>
                            </div>
                        </div>
                    </div>
                </div>


             
                <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LdUCo8cAAAAAMMNEEh3GOOlfSDIBGA261hWxAPr"
                render="explicit"
                onChange={onChange}
                />
                <div className="submitBtn">
                    <button onClick={onSubmit} >Submit</button>
                </div>
               

            </div>
        </div>
        <Footer handleShow={handleShow} handleShowTerms={handleShowTerms} />
        <PrivacyModal show={show} handleClose={handleClose} />
        <TermsModal handleCloseTerms={handleCloseTerms} showTerms={showTerms} />
        
    </div>
  )
}
