import axios from "../helpers/axios.js";

const api = {};

api.register = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/register`;
  return axios.post(url, body);
};

api.login = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/login`;
  return axios.post(url, body);
};

api.fetchLogs = function (params) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/get-logs/${params}`;
  return axios.get(url);
};

api.forgetPass = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/forget`;
  return axios.post(url, body);
};
api.resetPass = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/reset-password`;
  return axios.post(url, body);
};
api.verifyAccount = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/verifyProfile`;
  return axios.post(url, body);
};

api.connect2FA = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/2FA/connect`;
  return axios.post(url, body);
};

api.verify2FA = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/2FA/verify/connect`;
  return axios.post(url, body);
};

api.getUserData = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/get/user/data`;
  return axios.post(url, body);
};
api.getSubscribers = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/subscribers/alldata`;
  return axios.post(url, body);
};
api.getOverViewData = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/dashboard/overview`;
  return axios.post(url, body);
};
api.sendKyc = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/getstarted/kyc`;
  return axios.post(url, body);
};
api.editKyc = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/update-status-kyc`;
  return axios.post(url, body);
};

api.uploadFile = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/file/upload`;
  return axios.post(url, body);
};

api.remove2FA = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/2FA/verify/remove`;
  return axios.post(url, body);
};

api.editProfile = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/profile/edit`;
  return axios.patch(url, body);
};

api.createProduct = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/product/create`;
  return axios.post(url, body);
};

api.getAllProduct = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/product/get-all-products-user`;
  return axios.post(url, body);
};
api.getAllProductByCustomer = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get-all-products-customer`;
  return axios.post(url, body);
};

api.getAllProductByID = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/product/get-all-products-id`;
  return axios.post(url, body);
};

api.subscribe = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/subscribe/create`;
  return axios.post(url, body);
};

api.getUserSubscribe = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/subscribe/get-subscription`;
  return axios.post(url, body);
};

api.getUserSubscribeAll = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/subscribe/get-subscription-all`;
  return axios.post(url, body);
};
api.getMerchantAll = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/merchant`;
  return axios.post(url, body);
};
api.getMerchantDetail = function (id) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/merchant/${id}`;
  return axios.get(url);
};

api.delteSubscription = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/subscribe/delete-subscription`;
  return axios.post(url, body);
};

api.editProduct = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/product/edit-product`;
  return axios.post(url, body);
};

api.fetchTransaction = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/history/get-transactions-all-product`;
  return axios.post(url, body);
};

api.fetchTransactionAll = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/history/get-transactions-all`;
  return axios.post(url, body);
};
api.fetchTransactionAllByAddress = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/get-transactions-all-by-address`;
  return axios.post(url, body);
};

api.createTransaction = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/history/create`;
  return axios.post(url, body);
};

api.deleteProduct = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/product/delete`;
  return axios.post(url, body);
};

api.getCustomer = function (params) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/customer/${params}`;
  return axios.get(url);
};

api.contact = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/contact`;
  return axios.post(url, body);
};





export default api;