import React from "react";
import { NavLink } from "react-router-dom";
import logoo2 from "../images/logoo2.png";
import { useLocation } from "react-router-dom";


const Header = () => {
  const location = useLocation()
return(
    <>
    

<nav className="navbar navbar-expand-lg navbar-dark py-3"  >
    <section  className='container'>

  <NavLink className="navbar-brand" to="/">
    <img src={logoo2} width={130} height={50} className="d-inline-block align-top" alt="" />
    
  </NavLink>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon" />
  </button>
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mx-auto" id="headerrnav" >

      <li className="nav-item active" >
        <NavLink style={{  color: "White", fontSize: "18px",fontWeight:500 }} className="nav-link" to="/">HOME <span className="sr-only">(current)</span></NavLink>
      </li>

      <li className="nav-item" >
        <NavLink style={{ color: "White", fontSize: "18px",fontWeight:500 }} className="nav-link" to="">HOW IT ALL WORKS? </NavLink>
      </li>

      <li className="nav-item" >
        <NavLink style={{ color: "White", fontSize: "18px",fontWeight:500 }} className="nav-link" to="">FEATURES</NavLink>
      </li>

      <li className="nav-item">
        <NavLink style={{ color: "White", fontSize: "18px",fontWeight:500 }} className="nav-link" to="">BLOG</NavLink>
      </li>

      <li className="nav-item" >
        <NavLink style={{ color: "White", fontSize: "18px",fontWeight:500 }} className="nav-link" to="">FAQ's</NavLink>
      </li>

      
      {/* <li className="nav-item" id="discord">
      <a style={{color: '#A3D1E0', marginLeft: '219px'}} className="nav-link disabled" href="#" tabIndex={-1} aria-disabled="true">DISCORD</a>
    </li> */}
    </ul>
    
    <div style={{ display:"flex" }} >
      {location.pathname == "/signin"?
      <NavLink to="/signup" style={{  fontSize: "18px",fontWeight:500 }} className="btn btn1 my-2 my-sm-0" >Sign Up</NavLink> 
      :
      <NavLink to="/signin" className="btn btn1 my-2 my-sm-0" >Login</NavLink> 
      }
 </div>
  </div>
</section>
</nav>

    
    </>
)

}

export default Header;