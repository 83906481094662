import React from 'react'
import { Link } from 'react-router-dom'

export const Demo = () => {
  return (
    <section className='demoSec'>
        <div className="container">
            <div className="demoMain">
                <div>
                    <div className="heading">Start Your Free Trial</div>
                    <p className="desc">want to learn more about our features and product? We are happy to schedula a demo.</p>
                </div>
                <Link className='demoBtn' to={"/contact"}>Book a Demo</Link>
            </div>
        </div>
    </section>
  )
}
