import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import api from "../api";
import Dash from "../components/Dash";
import Home from "../components/Home";
import Signin from "../components/Signin";
import Signup from "../components/Signup";
import TwoFA from "../components/TwoFA";
import ProtectedRoutes from "./SecureRoutes";
import CustomerProtectedRoutes from "./customerSecureRoute";
import ProtectedRoutesTWOFA from "./SecureRoutesTwoFA";
import userActions from "../redux/users/action";
import ProductCheckout from "../components/Checkout";
import AppRouter from "../components/dashboardMain/route/route";
import { useAlert } from "react-alert";
import ForgotPassword from "../components/forgotPassword";
import ResetPassword from "../components/resetPassword";
import AccountVerified from "../components/accountVerified";
import AdminRouter from "../components/dashboardMain/route/adminRoute";


import PreviousProductCheckout from "../components/Checkout/previousCheckout";
import Welcome from "../components/customer/welcome";
import CustomerRouter from "../components/dashboardMain/route/customerRoute";
import { Contact } from "../components/contact/contact";
import { Protocol } from "../components/protocol/protocol";




const Router = (props) => {
  const user = useSelector((state) => state.userReducer.users);
  const { setUser } = userActions;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert()

  useEffect(() => {
    const is2FA = localStorage.getItem("twofa");
    fetchUserData();

    if (user.token && is2FA == "false") {
      dispatch(setUser({}));
      navigate("/signin");
    }
    // if (user.token && is2FA == "true") {
    //   navigate("/dash");
    // }
  }, []);

  // useEffect(() => {
  //   fetchUserData();
  // }, [window.location.pathname]);

  useEffect(() => {
    fetchUserData();
  }, [user?.token]);

  const fetchUserData = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = user?.token;

      const userData = await api.getUserData();
      console.log(userData)
      if(userData.error == "Failed to Authenticate"){
        return logout()
      }
      
      const token = user.token;
      userData.token = token;
      dispatch(setUser(userData));
    } catch (e) {
      console.log(e);
      if(user?.user){
        return logout()
      }
    }
  };

  const logout = async () => {
    try {
      await dispatch(setUser({}));
      localStorage.clear()
      navigate("/");
    } catch {
      alert.show("Error while logging out");
    }
  };
  // console.log(user?.user);

  const location = useLocation()
  useEffect(()=>{
    window.scrollTo({top:0,behavior: 'instant',
  });
},[location.pathname])

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/signin" element={<Signin />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/welcome" element={<Welcome />} />
        <Route exact path="/forget" element={<ForgotPassword />} />
        <Route exact path="/verify-user" element={<AccountVerified />} />
        <Route exact path="/reset-password/:id" element={<ResetPassword />} />
        <Route exact path="/product/checkout/" element={<ProductCheckout />} />
        <Route exact path="/last-product/checkout/" element={<PreviousProductCheckout />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/protocol" element={<Protocol />} />

        <Route element={<ProtectedRoutesTWOFA />}>
          <Route exact path="/twofa" element={<TwoFA />} />
        </Route>

        <Route element={<ProtectedRoutes />}>
          <Route exact path="/Dash" element={<Dash />} />
        </Route>
        {user?.user?.isAdmin ?
          <>
          <Route element={<ProtectedRoutes />}>
            <Route exact path="/admin/:next" element={<AdminRouter />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route exact path="/admin/:next/:id" element={<AdminRouter />} />
          </Route>
          </>
        :
        <Route element={<ProtectedRoutes />}>
          <Route exact path="/dashboard/:next" element={<AppRouter />} />
        </Route>
         }
        <Route element={<CustomerProtectedRoutes />}>
          <Route exact path="/customer/:next" element={<CustomerRouter />} />
        </Route>

        {/* <Route path="*" element={<Navigate to="/signin" replace />} /> */}
      </Routes>
    </>
  );
};

export default Router;
