import React,{useEffect,useState} from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import api from "../../../api/index";
import { Loader } from "../../Loader";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import copy from 'copy-to-clipboard';


function Transaction(props) {
  const navigate = useNavigate()


  return(
    <>
      {/* Begin Page Content */}
      <div className="container-fluid subscripPage">
        <div className="row">
            <div className="col pb-3">
              <Table />
            </div>
        </div>
      </div>
      {/* /.container-fluid */}
       
    </>
  )
}


const Table=()=>{
  const [transactions, setTransaction] = useState([]);
  const userObject = useSelector((state) => state.userReducer.users);
  const alert = useAlert()
  const [loader, setLoader] = useState(false);

  const [pageLimit, setPageLimit] = useState(3);
  const [pageSkip, setPageSkip] = useState(0);
  const [docCount, setDocCount] = useState(0);

  const [activeTab, setActiveTab] = useState(1);
  const [copyAddress, setCopyAddress] = useState("Copy Address");
  const customerObject = useSelector((state) => state.customerReducer.customer);


  // Fetch Transaction Details
  const fetchAllTrans = async (skip) => {
    try {
      setLoader(true);

      const payload = {
        address:customerObject?.wallet,
        skip:skip,
        pageSize:pageLimit
      };
      const response = await api.fetchTransactionAllByAddress(payload);

      console.log("response");
      console.log(response);
      setTransaction([...transactions, ...response?.msg]);
      // setTransaction(response.msg);
      setLoader(false);
      setDocCount(response?.docCount)
      console.log(response?.docCount);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };
    // format date
    const formatDate = (dateParam) => {
      const date = new Date(dateParam);
      return date.toLocaleDateString();
    };

    const handleShowMore = async () => {
      setPageSkip(pageSkip+pageLimit)
      console.log(pageSkip + pageLimit);
      await fetchAllTrans(pageSkip+ pageLimit);
    }

    const handleCopyAdr = (address) => {
      copy(address)
      setCopyAddress("copied")
      setTimeout(() => {
        setCopyAddress("Copy Address")
      }, 3000);
    }

  useEffect(()=>{
    fetchAllTrans(0)
  },[])

  return(
                <div className=" sortby-box br-10 border-transparent "> 
                  <div className="m-pi-header">
                    <div className='d-flex justify-content-between flex-wrap'>
                      <h3 className='mb-0 mt-2'>Transaction</h3>
                    </div>
                    <div className="pi-filters">
                      <div className={`filter ${activeTab === 1 && "active"}`} onClick={() => setActiveTab(1)}>All</div>
                      <div className={`filter ${activeTab === 2 && "active"}`} onClick={() => setActiveTab(2)}>Active</div>
                      <div className={`filter ${activeTab === 3 && "active"}`} onClick={() => setActiveTab(3)}>Pending</div>
                    </div>
                  </div>
                  <div className='card sortby-box br-10 border-transparent m-pi-data'>
                    <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
                        <table className="table table-hover table-striped m-pi-table">
                          <thead>
                                  <tr>
                                    <th scope="col">Owner</th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Product Amount</th>
                                    <th scope="col">Transaction Date</th>
                                    <th scope="col">Event</th>
                                  </tr>
                          </thead>
                          <tbody>
                            {transactions?.map((item) => (
                            <tr>
                              <td>
                                  <div className="copyLink">
                                    <div className='d-flex jc-center'>
                                      <span className='' onClick={() => handleCopyAdr(item?.address)}> {item?.address.slice(0,4)}...{item?.address.slice(item?.address.length-4,item?.address.length)}</span>
                                    </div>
                                    <div className="copyText">{copyAddress}</div>
                                  </div>
                              </td>
                              <td>
                                      <div className='font-500'>{item?.product?.name}</div>
                              </td>
                              <td>
                                      <div>{item?.product?.amount / 10 ** 18}$</div>
                              </td>
                              <td>
                                      <div>{moment(item?.date).format("DD MMM YYYY")}</div>
                              </td>
                              <td>
                                      {item?.event == "subscribe"?
                                      <div className='eventBadge badge badge-success text-uppercase'>{item?.event}</div>
                                      :
                                      <div className='eventBadge badge badge-danger text-uppercase'>{item?.event}</div>
                                      }
                              </td>
                            </tr>
                            ))}
                        
                          </tbody>
                        </table>
                        {docCount != transactions?.length && docCount >= pageLimit &&
                        <div className='showMoreMain text-center'>
                            <button className="gradBtn fs-12" onClick={handleShowMore}>
                              Show More
                              {/* <div className="loaderStyle"></div> */}
                            </button> 
                        </div>
                      }
                    </div>
                  </div>
                     {/* Loader component */}
                    <Loader loader={loader} />
                  </div>
  )
}

export {Transaction};
