import { Player } from 'video-react';
import "../../node_modules/video-react/dist/video-react.css";

import IMG from "../images/art2.png";
import POSTER from "../images/videoPoster.png";
const VIDEO= "https://www.youtube.com/embed/6cwDA5q8AnA";


export default function INTRO() {
    return <div className="text-center">
        <h1 className='inh1 h21223'>How to Checkout with SiePay?</h1>
        <div className="container">
            <div className="row vidRow">
                <div className="col-3" style={{ position: "relative" }}>
                    <img className='introChar' src={IMG} alt="img" />
                </div>
                <div className="col-9">
                    {/* <Player
                        fluid={true}
                        playsInline
                        poster={POSTER}
                        src={VIDEO}
                    /> */}
                    <iframe className='videoMa' width="100%" height="500" src="https://www.youtube.com/embed/6cwDA5q8AnA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
} 