import React, { useEffect, useState } from "react";
import dollar from "../images/dollar.png";
import dynamic from "../images/dynamic.png";
import data_im from "../images/data.png";
import star from "../images/star.png";
import Footer from "./Footer";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { useSelector } from "react-redux";
import { Loader } from "./Loader";
import { useAlert } from "react-alert";


import logo from "../images/updated-img/logo.png"


const Signup = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const user = useSelector((state) => state.userReducer.users);
  const [passError, setPassError] = useState(false);

  useEffect(() => {
    if (user.token) {
      navigate("/");
    }
  }, [])

  const onSubmit = async () => {
    try {
      const validatorResponse = await validation();

      if (validatorResponse) {
        return alert.show(validatorResponse);
      }

      setLoader(true);
      const payload = {
        ...data,
        role: 1,
      };
      console.log(payload);
      const response = await api.register(payload);

      setLoader(false);
      console.log('====================================');
      console.log(response);
      console.log('====================================');
      if (response?.error) {
        if (response?.error == "Username or email has already been taken") {
          alert.show("Username or email has already been taken");
          // navigate("/signin");
          return
        } else {
          throw new Error(response?.error);
        }
      }

      alert.show(response.msg);
      navigate("/signin");
    } catch (e) {
      setLoader(false);
      alert.show("Error while registering");
    }
  };

  const validation = async () => {
    var letter = /[a-zA-Z]/;
    var number = /[0-9]/;
    var valid = number.test(data.password) && letter.test(data.password);


    if (!data.email) {
      return "Email is required";
    }
    if (!data.username) {
      return "Username is required";
    }
    if (data.username.includes(' ')) {
      return "Spaces are not allowed in Username";
    }
    if (!data.firstName) {
      return "First name is required";
    }
    if (!data.lastName) {
      return "Last name is required";
    }
    if (!data.password) {
      return "Password is required";
    }
    if (data.password && data.password != data.confirmPassword) {
      return "Confirm Password is must be same as Password";
    }
    if (data.confirmPassword.includes(' ')) {
      return "Spaces are not allowed in password";
    }
    if (!valid || data.password.length < 6) {
      setPassError(true)
      return "password not valid"
    } else (setPassError(false))
    return false;
  };

  const onInputChange = async (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <>
      <section className="page2sec1">
        {/* <Header2 /> */}
        <div className="container-fluid px-0">

          {/* <div className="col-lg-6 sectop order-2 order-lg-1">
              <h1 style={{ color: "white" }}>Sign Up</h1>
              <p style={{ color: "white" }}>
              A cryptocurrency recurring payment system is a way to automate payments using DeFi wallets.
              </p>

              <div className="flex">
                <div>
                  <img className="dollar" src={dollar} />
                </div>
                <div>
                  <h3 style={{ color: "white" }}>Easy Integration</h3>
                  <p style={{ color: "rgb(187 245 225)" }}>
                  With a few clicks you can integrate with this system. No coding experience required!
                  </p>
                </div>
              </div>

              <div className="flex">
                <div>
                  <img className="dollar" src={dynamic} />
                </div>
                <div>
                  <h3 style={{ color: "white" }}>Gateway for Merchant's</h3>
                  <p style={{ color: "rgb(187 245 225)" }}>
                  You can send invoices and manage payments with our powerful dashboard. Get set up in minutes!
                  </p>
                </div>
              </div>
            </div> */}

          {/* <div className="col-lg-6 create order-1 order-lg-2">
              <div id="smallcenter">
                <h1 className="sigup">Create a Merchant Account</h1>
                <p className="sigupp">
                  Already a user? <span onClick={() => navigate("/signin")}> Sign in</span>
                </p>

                <div className="flex2">
                  <div>
                    <input
                      className="firstdiv"
                      name="firstName"
                      onChange={onInputChange}
                      placeholder="First Name"
                    />
                  </div>

                  <div>
                    <input
                      className="lastdiv"
                      name="lastName"
                      onChange={onInputChange}
                      placeholder="Last Name"
                    />
                  </div>
                </div>

                <div>
                  <input
                    className="secdiv"
                    name="username"
                    onChange={onInputChange}
                    placeholder="Username"
                  />
                </div>

                <div>
                  <input
                    className="thirddiv"
                    placeholder="Email"
                    name="email"
                    onChange={onInputChange}
                  />
                </div>

                <div className="flex2">
                  <div>
                    <input
                      className="firstdiv"
                      name="password"
                      onChange={onInputChange}
                      type={"password"}
                      placeholder="Password"
                    />
                  </div>

                  <div>
                    <input
                      name="confirmPassword"
                      onChange={onInputChange}
                      className="lastdiv"
                      type={"password"}
                      placeholder="Confirm Password"
                    />
                  </div>
                </div>
                {passError &&<p className="passError">Password must have alpha numaric and atleast 6 character</p>}


                <p className="ppp mt-4">
                Protected by reCAPTCHA and subject to the <span> Siepay Privacy Policy </span>
                and <span> Terms of Service.</span>
                </p>

                <div className="btnnncre">
                  <button onClick={onSubmit} className="crebtn">
                    Create Now
                  </button>
                </div>
              </div>
            </div> */}

          <div className="row mx-auto m-signin">
            {/* COLUMN #1 */}
            <div className="col-lg-6 pt-5 order-lg-1 order-2 px-5 m-signin-content">
              <div className="m-logo">
                <img src={logo} alt="logo" />
              </div>

              <div className="m-sign-text">
                <h1>
                  Stay on top of your finances.
                  <br />
                  Don't leave that up to others.
                </h1>
                <p>- Leif Garrett</p>
              </div>
              {/* <h1 style={{ color: "white" }}>Sign In</h1>
              <p style={{ color: "white" }}>
                A cryptocurrency recurring payment system is a way to automate payments using DeFi wallets.
              </p>

              <div className="flex">
                <div>
                  <img className="dollar" src={dollar} />
                </div>
                <div>
                  <h3 style={{ color: "white" }}>Easy Integration</h3>
                  <p style={{ color: "rgb(187 245 225)" }}>
                    With a few clicks you can integrate with this system. No coding experience required!
                  </p>
                </div>
              </div>

              <div className="flex">
                <div>
                  <img className="dollar" src={dynamic} />
                </div>
                <div>
                  <h3 style={{ color: "white" }}>Gateway for Merchant's</h3>
                  <p style={{ color: "rgb(187 245 225)" }}>
                    You can send invoices and manage payments with our powerful dashboard. Get set up in minutes!
                  </p>
                </div>
              </div> */}
            </div>


            {/* COLUMN #2 */}
            <div className="col-lg-6 order-lg-2 order-1 px-0">
              <div className="m-signin-sec2">

                <form className="m-signup-form" onSubmit={(e) => e.preventDefault()}>
                  <h2>Create Your </h2>
                  <h2>Merchant Account</h2>
                  <p>Already a User? <span onClick={() => navigate("/signin")} style={{ cursor: "pointer" }}>Sign In</span></p>

                  <div className="flex2 form-input2">
                    <div className="form-input-2">
                      <input
                        className="firstdiv"
                        name="firstName"
                        onChange={onInputChange}
                        placeholder="First Name"
                      />
                    </div>

                    <div className="form-input-2">
                      <input
                        className="lastdiv"
                        name="lastName"
                        onChange={onInputChange}
                        placeholder="Last Name"
                      />
                    </div>
                  </div>

                  <div className="flex2 form-input2">
                    <div className="form-input-2 form-input2">
                      <input
                        className="secdiv"
                        name="username"
                        onChange={onInputChange}
                        placeholder="Username"
                      />
                    </div>
                  </div>

                  <div className="flex2 form-input2">
                    <div className="form-input-2 form-input2">
                      <input
                        className="thirddiv"
                        placeholder="Email"
                        name="email"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="flex2 form-input2">
                    <div className="form-input-2">
                      <input
                        className="firstdiv"
                        name="password"
                        onChange={onInputChange}
                        type={"password"}
                        placeholder="Password"
                      />
                    </div>

                    <div className="form-input-2">
                      <input
                        name="confirmPassword"
                        onChange={onInputChange}
                        className="lastdiv"
                        type={"password"}
                        placeholder="Confirm Password"
                      />
                    </div>
                  </div>
                  {passError && <p className="passError text-danger">Password must have alpha numaric and atleast 6 character</p>}

                  <div className="text-center m-signup-submit mt-4">
                    <button onClick={onSubmit} className="crebtn1">
                      Sign Up
                    </button>
                  </div>

                </form>


              </div>


              {/* <h1 className="signinh1">SignIn Your Merchant Account</h1> */}
              {/* <p onClick={() => navigate("/signup")} className="sigupp">
                Do not have an account? <span>Sign up</span>
              </p> */}

              {/* <div>
                <input
                  onChange={onInputChange}
                  name="username"
                  className="secdiv"
                  placeholder="Username"
                />
              </div> */}

              {/* <div>
                <div>
                  <input
                    className="secdiv"
                    onChange={onInputChange}
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                </div>
              </div> */}

              {/* <p className="ppp">
                Protected by reCAPTCHA and subject to the <span> Siepay Privacy Policy </span>
                and <span> Terms of Service.</span>
              </p> */}

              {/* <div className="">
                <div>
                  <p className="forgot" style={{ cursor: "pointer" }} onClick={() => navigate("/forget")}>Forgot Password?</p>
                </div>

                <div className="text-center">
                  <button onClick={onSubmit} className="crebtn1">
                    Login Now
                  </button>
                </div>
              </div> */}



            </div>
          </div>

        </div>

        {/* <Footer /> */}
      </section>
      <Loader loader={loader} />
    </>
  );
};

export default Signup;
