import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import userAction from "../../redux/users/action";
import "./index.css";
import { getAccount, web3Initializer } from "../../helpers/metamask";
import web3Object from "../../web3/web3";
import USDTContract from "../../abi/usdt.json";
import SubscriptionContract from "../../abi/subscription.json";
import bigNumber from "bignumber.js";
import { Loader } from "../Loader";
import api from "../../api";
import { useAlert } from "react-alert";
import addIco from "../../images/addIco.svg"
import minusIco from "../../images/minusIco.svg"

import metamask from "../../images/updated-img/metamask.png"
import walletconnect from "../../images/updated-img/walletconnect.png"
import bitkeep from "../../images/updated-img/bitkeep.png"
import close from "../../images/updated-img/close.png"


import Web3 from "web3";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import WalletConnectProvider from "@walletconnect/web3-provider";
import { EthereumProvider } from '@walletconnect/ethereum-provider'


const ProductCheckout = (props) => {
  const alert = useAlert();
  const [data, setData] = useState([]);
  const [merchant, setMerchant] = useState([]);
  const [wallet, setWallet] = useState(null);
  const [loader, setLoader] = useState(false);
  const [approved, setApprove] = useState(true);
  const [subscription, setSubscription] = useState([true]);
  const [subscribed, setSubscribed] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [dynamicAmount, setDynamicAmount] = useState("");
  const user = useSelector((state) => state.userReducer.users);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProduct();
  }, []);


  function toFixedExp(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += (new Array(e + 1)).join('0');
      }
    }
    return x;
  }
  const fetchProduct = async () => {
    try {
      setLoader(true);
      const params = new URL(document.location).searchParams;
      console.log(params);
      const pid = params.get("pid");
      console.log(pid);

      if (!pid) {
        navigate("/");
      }

      const payload = {
        _id: pid,
      };
      const response = await api.getAllProductByID(payload);

      if (!response.product) {
        navigate("/");
      }
      console.log(response.product);
      if (response.product.unitPrice) {
        let unitPrice = parseFloat(response.product.unitPrice) * 10 ** 18
        setDynamicAmount(unitPrice + parseInt(response.product.amount))
      } else {
        setDynamicAmount(response.product.amount)
      }

      console.log("fixed amount:" + response.product.amount);
      setMerchant(response.merchant);
      setData(response.product);
      setLoader(false);
      console.log(response);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const isSubscribed = async (wallet, callFrom) => {
    try {
      setLoader(true);
      const params = new URL(document.location).searchParams;
      const pid = params.get("pid");

      if (!pid) {
        navigate("/");
      }

      const payload = {
        productId: pid,
        address: wallet,
      };
      const response = await api.getUserSubscribe(payload);
      console.log('====================================');
      console.log(response);
      console.log('====================================');
      if (response.msg) {
        setSubscribed(true);

        if (callFrom === "subscribeFun" && data.unitPrice) {
          let unitPrice = parseFloat(data.unitPrice) * 10 ** 18

          setDynamicAmount(parseInt(data.amount))
          // setDynamicAmount(data.amount)
          setQuantity(response.msg.quantity)
        } else {
          if (response.msg.quantity) {
            let unitPrice = parseFloat(data.unitPrice) * 10 ** 18
            setDynamicAmount((unitPrice * response.msg.quantity) + parseInt(data.amount))
            setQuantity(response.msg.quantity)
            console.log(unitPrice);
            console.log(response.msg.quantity);
            console.log(data.amount);
          } else {
            setQuantity("")
            setDynamicAmount(data.amount)
          }
        }
      } else {
        await isApproved(wallet);
        // if(data.unitPrice){
        //   let unitPrice = parseFloat(data.unitPrice) * 10 ** 18
        //   setDynamicAmount(unitPrice + parseInt(data.amount))
        // }
      }


      console.log(response.msg);
      setSubscription(response.msg);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const subscribeUsingCrypto = async () => {
    try {
      if (typeof wallet === "undefined" || wallet === "") {
        alert.show("Wallet address not save in state")
      }
      console.log("wallet => => =>", wallet);
      console.log("FormData", FormData);
      console.log("!")
      console.log(typeof data.amount)
      const walletAddress = wallet;
      const params = new URL(document.location).searchParams;
      const pid = params.get("pid");
      console.log("!")

      if (!pid) {
        navigate("/");
      }
      if (data.type === "dynamic") {
        if (!quantity) {
          return alert.show("Please add quantity")
        }
        else {
          data.amount = dynamicAmount.toString();
        }
      }
      console.log(typeof data.amount)

      setLoader(true);

      const subsContract = new web3Object.web3.eth.Contract(
        SubscriptionContract,
        process.env.REACT_APP_SUBSCRIPTION_ADDRESS
      );
      console.log("!")
        let tok = "0x6bc55a24Cb90c0BbbAa3A85D531588952C78D3a5";
      const time = Math.floor(new Date().getTime() / 1000) + 1000;
      console.log("!")
      console.log(data.address,
        tok,
        parseInt(data.productId),
        process.env.REACT_APP_USDT_ADDRESS,"--",
        data.amount,
        parseInt(data.PeriodType),
        data.unit)

      const subscriptionResp = await subsContract.methods
        .subscribeProduct(
          parseInt(data.productId),
          tok,
        )
        .send({ from: walletAddress });
      console.log("subscriptionResp")
      console.log(subscriptionResp)

      let payload;
      data.type === "dynamic" ?
        payload = {
          productId: pid,
          address: wallet,
          paidAmount: data.amount,
          subscriptionId: subscriptionResp.events?.AddedSubscriber?.returnValues?._subscriptionId,
          userID: data.userID,
          quantity: quantity,
          ...FormData
        }
        :
        payload = {
          productId: pid,
          address: wallet,
          paidAmount: data.amount,
          subscriptionId: subscriptionResp.events?.AddedSubscriber?.returnValues?._subscriptionId,
          userID: data.userID,
          ...FormData
        };
      const subscribeAPI = await api.subscribe(payload);
      console.log(payload)
      console.log("!")

      console.log(subscribeAPI.msg._id);

      const payloadTransaction = {
        product: pid,
        user: data.userID,
        address: wallet,
        subscription: subscribeAPI.msg._id,
        event: "subscribe"
      };
      console.log('====================================');
      console.log(payloadTransaction);
      console.log('====================================');
      const transactionsCreate = await api.createTransaction(
        payloadTransaction
      );
      console.log("!")
      setQuantity(1)

      await isSubscribed(wallet, "subscribeFun");
      alert.show("Subscription Created");
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const isApproved = async (walletiid) => {
    try {
      if (data.type === "dynamic") {
        if (!quantity) {
          return alert.show("Please add quantity")
        }
        else {
          // data.amount = dynamicAmount.toString(); 
        }
      }

      setLoader(true);
      const walletAddress = walletiid;

      console.log("Wallet", walletAddress);
      console.log(
        "Subscription Address",
        process.env.REACT_APP_SUBSCRIPTION_ADDRESS
      );
      console.log("USDT Address", process.env.REACT_APP_USDT_ADDRESS);
      console.log("USDT CON", USDTContract);

      const usdtContract = new web3Object.web3.eth.Contract(
        USDTContract,
        process.env.REACT_APP_USDT_ADDRESS
      );
      const checkApproved = await usdtContract.methods
        .allowance(walletAddress, process.env.REACT_APP_SUBSCRIPTION_ADDRESS)
        .call();
      const approvedAmount = new bigNumber(checkApproved);
      console.log("approveOr: " + checkApproved);
      console.log("approve: " + approvedAmount);
      console.log("data.amount: " + data.amount);
      console.log("dy amount: " + dynamicAmount.toString());
      const checkedAmount = new bigNumber(dynamicAmount) * 100;
      if (approvedAmount >= checkedAmount) {
        setApprove(false);
      }

      console.log(approvedAmount);
      setLoader(false);
    } catch (e) {
      console.log(e);
      alert.show("Error while checking approved");
      setLoader(false);
    }
  };

  const approveAllowance = async () => {
    try {
      if (data.type === "dynamic") {
        if (!quantity) {
          return alert.show("Please add quantity")
        }
        else {
          data.amount = dynamicAmount.toString();
        }
      }

      setLoader(true);
      const walletAddress = wallet;

      console.log("Wallet", walletAddress);
      console.log("USDT Address", process.env.REACT_APP_USDT_ADDRESS);
      console.log(
        "Subscription Address",
        process.env.REACT_APP_SUBSCRIPTION_ADDRESS
      );
      console.log("USDT Con", USDTContract);

      const usdtContract = new web3Object.web3.eth.Contract(
        USDTContract,
        process.env.REACT_APP_USDT_ADDRESS
      );
      let balance = await usdtContract.methods.balanceOf(walletAddress).call();
      let dec = await usdtContract.methods.decimals().call();
      balance = balance / 10 ** dec;
      const subsVal = parseInt(data.amount) / 10 ** dec;

      console.log("balance:" + balance);
      console.log(subsVal);

      if (parseInt(balance) <= subsVal) {
        setLoader(false);
        return alert.show("Insufficient Balance");
      }

      const amountOfApp = new bigNumber(data.amount) * 100
      const amountToApprove = amountOfApp;
      // const amountToApprove = new bigNumber(amountOfApp);

      console.log("amountOfApp:" + amountOfApp);
      console.log("allownce:" + amountToApprove);
      console.log(typeof amountToApprove);
      
      const approvalResponse = await usdtContract.methods
        .approve(process.env.REACT_APP_SUBSCRIPTION_ADDRESS, toFixedExp(amountToApprove.toString()))
        .send({ from: walletAddress });

      console.log(approvalResponse);
      setApprove(false);
      setLoader(false);
    } catch (e) {
      console.log(e);
      alert.show("Error while approving");
      setLoader(false);
    }
  };

  const cancelSubscription = async () => {
    try {
      setLoader(true);
      console.log("Cancel");
      console.log(SubscriptionContract);
      console.log(process.env.REACT_APP_SUBSCRIPTION_ADDRESS);
      console.log(subscription.subscriptionId);
      console.log(wallet);
      const params = new URL(document.location).searchParams;
      const pid = params.get("pid");

      const subsContract = new web3Object.web3.eth.Contract(
        SubscriptionContract,
        process.env.REACT_APP_SUBSCRIPTION_ADDRESS
      );
      const subscriptionResp = await subsContract.methods
        .cancelSubscription(subscription.subscriptionId)
        .send({ from: wallet });

      const payload = {
        address: wallet,
        productId: data._id,
      };

      const deleteresp = await api.delteSubscription(payload);
      console.log(deleteresp);

      const payloadTransaction = {
        product: pid,
        user: data.userID,
        address: wallet,
        subscription: subscription._id,
        event: "unsubscribe"
      };
      if (deleteresp.msg === "Deleted") {
        const transactionsCreate = await api.createTransaction(
          payloadTransaction
        );
      }
      console.log('====================================');
      console.log(payloadTransaction);
      console.log('====================================');

      window.location.reload();
      await fetchProduct();
      await isSubscribed(wallet);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const handleQuantity = (e) => {
    console.log("unit price:" + data.unitPrice);
    console.log("price:" + data.amount);
    let unitPrice = parseFloat(data.unitPrice) * 10 ** 18
    if (e.target.value > 0) {
      setQuantity(e.target.value)
      setDynamicAmount((unitPrice * e.target.value) + parseInt(data.amount))
    }

  }

  const handleQuantityAdd = () => {
    if (quantity > 0) {
      let newQuantity = parseInt(quantity + 1)
      let unitPrice = parseFloat(data.unitPrice) * 10 ** 18
      setQuantity(newQuantity)
      setDynamicAmount((unitPrice * newQuantity) + parseInt(data.amount))
    }
  }

  const handleQuantitySub = () => {
    if (quantity > 1) {
      let newQuantity = parseInt(quantity - 1)
      let unitPrice = parseFloat(data.unitPrice) * 10 ** 18
      setQuantity(newQuantity)
      setDynamicAmount((unitPrice * newQuantity) + parseInt(data.amount))
      // setDynamicAmount(data.amount * newQuantity)

    }
  }
  console.log(quantity);
  console.log(dynamicAmount);


  const connectMetamask = async () => {
    try {
      setLoader(true);
      await web3Initializer();

      const userWalletId = await getAccount();

      if (!userWalletId) {
        throw new Error("Unable to connect user, Metamask error.");
      }

      const chainId = await web3Object.web3.eth.getChainId();

      if (parseInt(chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
        alert.show("Please connect to Binance Test Network");
        throw new Error("Please connect to Binance Test Net");
      }

      const signature = await web3Object.web3.eth.personal.sign(
        `I am signing to subscribe this product membership from my account: ${data._id}`,
        userWalletId,
        ""
      );

      if (!signature) {
        throw new Error("Unable to connect user, Signature denied.");
      }

      setWallet(userWalletId);
      await isSubscribed(userWalletId);
      setLoader(false);
    } catch (e) {
      alert.show("Error while connection with Wallet");
      setLoader(false);
    }
  };

  const connectBitKeep = async () => {
    try {
      setLoader(true);

      const web3 = web3Object;
      const isBitKeepInstalled = window.isBitKeep && !!window.bitkeep.ethereum;
      if (!isBitKeepInstalled) {
        alert.show("Bitkeep wallet not found.");
        return;
      }

      const provider = window.bitkeep && window.bitkeep.ethereum;
      if (!provider) {
        alert.show("Bitkeep wallet provider found.");
        return;
      }

      const accs = await provider.request({
        method: "eth_requestAccounts",
      });


      // const ThisIsBitkeepWeb3 = await new Web3("https://data-seed-prebsc-1-s3.binance.org:8545/");
      const ThisIsBitkeepWeb3 = await new Web3(window.bitkeep.ethereum);
      web3.web3 = ThisIsBitkeepWeb3;

      const isConnecttowallet = await provider.isConnected();

      if (!isConnecttowallet) {
        alert.show("Bitkeep wallet not connected.");
        setLoader(false);
        return;
      }

      console.log(web3Object.web3);

      // const back = sessionStorage.getItem("path");
      let chainId = await web3Object.web3.eth.getChainId();
      if (parseInt(chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
        alert.show("Please connect to Binance Test Network");
        setLoader(false);
        throw new Error("Please connect to Binance Test Net");
      }

      const signature = await ThisIsBitkeepWeb3.eth.personal.sign(
        `I am signing to subscribe this product membership from my account: ${data._id}`,
        accs[0],
        ""
      );

      if (!signature) {
        throw new Error("Unable to connect user, Signature denied.");
      }

      setWallet(accs[0]);
      await isSubscribed(accs[0]);
      setLoader(false);

      console.log("accs", accs);
      console.log("chainId_", chainId);
      console.log("isConnecttowallet", isConnecttowallet);

    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  // mobile wllet provider
  // const providerOptions = new WalletConnectProvider({
  //   rpc: {
  //     56: "https://bsc-dataseed1.defibit.io/",
  //     97: "https://data-seed-prebsc-1-s1.binance.org:8545",
  //     1: "https://mainnet.infura.io/v3/3f2f5e20071a4cbe9dd30a0090ff6402",
  //     4: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  //     qrcode: true,
  //     pollingInterval: 12000,
  //   },
  //   chainId: 97,
  // });

  const handleWalletConnect = async () => {
    try {
      // setLoader(true);
      const providerOptions = await EthereumProvider.init({
        projectId: process.env.REACT_APP_WC_PROJECT_ID, // REQUIRED your projectId
        chains: [parseInt(process.env.REACT_APP_CHAIN_ID)], // REQUIRED chain ids
        showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal,
      });
      const web3 = web3Object;

      // setModalShow(false)
      await providerOptions.enable();
      setLoader(true);
      const ThisIsWCWeb3 = new Web3(providerOptions);
      web3.web3 = ThisIsWCWeb3;
      console.log(ThisIsWCWeb3);

      let accs = await ThisIsWCWeb3.eth.getAccounts();
      console.log(accs);


      let chainId = await ThisIsWCWeb3.eth.getChainId();
      if (parseInt(chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)) {
        alert.show("Please connect to Binance Test Network");
        setLoader(false);
        throw new Error("Please connect to Binance Test Net");
      }

      const signature = await ThisIsWCWeb3.eth.personal.sign(
        `I am signing to subscribe this product membership from my account: ${data._id}`,
        accs[0],
        ""
      );

      if (!signature) {
        throw new Error("Unable to connect user, Signature denied.");
      }

      setWallet(accs[0]);
      await isSubscribed(accs[0]);
      setLoader(false);

      console.log("accs", accs);
      console.log("chainId_", chainId);
    }
    catch (e) {
      console.log(e);
      setLoader(false);
    }
  };




  const [passError, setPassError] = useState(false);
  const validation = async () => {
    var letter = /[a-zA-Z]/;
    var number = /[0-9]/;
    //var valid = number.test(data.password) && letter.test(data.password);
    return false;
  };

  const [FormData, setFormData] = useState({})
  const onInputChange = async (e) => {
    setFormData({ ...FormData, [e.target.name]: e.target.value });
  };






  return (
    <div className="CheckoutMain py-0">
      <div className="effect"></div>
      <div className="container-fluid px-0">
        <div className="row mx-auto ai-center m-checkout-page">

          <div className="col-md-6">
            <div className="m-signin-sec2">
              <form className="m-signup-form" onSubmit={(e) => e.preventDefault()}>
                <h2 style={{ fontWeight: 600 }}>Checkout Products </h2>
                <p>Note that all the information you are requested to fill is optional</p>

                <div className="flex2 form-input2">
                  <div className="form-input-2">
                    <input
                      className="firstdiv"
                      name="firstName"
                      onChange={onInputChange}
                      placeholder="First Name"
                    />
                  </div>

                  <div className="form-input-2">
                    <input
                      className="lastdiv"
                      name="lastName"
                      onChange={onInputChange}
                      placeholder="Last Name"
                    />
                  </div>
                </div>

                <div className="flex2 form-input2">
                  <div className="form-input-2 form-input2">
                    <input
                      className="secdiv"
                      name="email"
                      onChange={onInputChange}
                      placeholder="Email"
                    />
                  </div>
                </div>

                <div className="flex2 form-input2">
                  <div className="form-input-2 form-input2">
                    <select
                      className="firstdiv"
                      name="country"
                      onChange={onInputChange}
                      placeholder="Select Country"
                    >
                      <option value="Pakistan">Pakistan</option>
                      <option value="UK">UK</option>
                      <option value="USA">USA</option>
                    </select>
                  </div>
                </div>

                <div className="flex2 form-input2">
                  <div className="form-input-2">
                    <select
                      className="firstdiv"
                      name="city"
                      onChange={onInputChange}
                      placeholder="Select Country"
                    >
                      <option value="Karachi">Karachi</option>
                      <option value="London">London</option>
                      <option value="aaa">aaa</option>
                    </select>
                  </div>

                  <div className="form-input-2">
                    <input
                      name="postalCode"
                      onChange={onInputChange}
                      className="lastdiv"
                      type={"text"}
                      placeholder="Postal Code"
                    />
                  </div>
                </div>

                <div className="flex2 form-input2">
                  <div className="form-input-2 form-input2">
                    <input
                      className="thirddiv"
                      placeholder="Street Address"
                      name="streetAddress"
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                {passError && <p className="passError text-danger">Password must have alpha numaric and atleast 6 character</p>}


                {!subscribed && (
                  <>
                    {!wallet && (
                      <div className="text-center m-signup-submit mt-4">
                        <ConnectModal connectMetamask={connectMetamask} connectBitKeep={connectBitKeep} walletConnect={handleWalletConnect} />
                      </div>
                    )}
                    {" "}
                    {wallet && approved && (
                      <div className="text-center m-signup-submit mt-4">
                        <button className="crebtn1" onClick={approveAllowance}>
                          Approve
                        </button>
                      </div>
                    )}
                    {wallet && !approved && (
                      <div className="text-center m-signup-submit mt-4">
                        <button className="crebtn1" disabled={!wallet || approved} onClick={subscribeUsingCrypto}>
                          Subscribe
                        </button>
                      </div>
                    )}
                  </>
                )}
                {subscribed && (
                  <div className="text-center m-signup-submit mt-4">
                    <button className="crebtn1" onClick={cancelSubscription} >
                      Cancel Subscription
                    </button>
                  </div>
                )}

              </form>
            </div>
          </div>

          <div className="col-md-6 px-0">
            <div className="m-checkout">
              <div className="m-checkout-content">
                <div className="m-order-summary">
                  <h5 className="head">Order Summary</h5>
                  <p className="title">Title</p>
                  <h6 className="asd">{(data?.name)?.toUpperCase()}</h6>

                  <div className="row product">
                    <div className="col">Product ID</div>
                    <div className="col price">{data?.productId}</div>
                  </div>
                  <div className="row product">
                    <div className="col">Product Price</div>
                    <div className="col price">${parseFloat(data?.amount) / 1000000000000000000}</div>
                  </div>
                  {
                    data?.type === "dynamic" && <>
                      <div className="quantityCounter mt-3">
                        <div className="countBtn" onClick={() => handleQuantitySub()}><img src={minusIco} alt="" /></div>
                        <div className="form-group mx-2 mb-0">
                          <input type="number"
                            onChange={(e) => handleQuantity(e)}
                            min="1"
                            value={quantity}
                            placeholder='Quantity' className='form-control' />
                        </div>
                        <div className="countBtn" onClick={() => handleQuantityAdd()}><img src={addIco} alt="" /></div>
                      </div>
                    </>
                  }
                  <div className="row total">
                    <div className="col">Total </div>
                    <div className="col price">${parseFloat(dynamicAmount) / 1000000000000000000}</div>
                  </div>
                </div>

                <div className="m-order-summary">
                  <form className="m-signup-form" onSubmit={(e) => e.preventDefault()}>
                    <div className="flex2 form-input2">
                      <div className="form-input-2 form-input2">
                        <input
                          className="secdiv"
                          name="username"
                          onChange={onInputChange}
                          placeholder="Add promo code"
                        />
                      </div>
                    </div>
                  </form>

                  <div className="row discount">
                    <div className="col">Discount</div>
                    <div className="col price">$0</div>
                  </div>

                  <div className="row total">
                    <div className="col">Total</div>
                    <div className="col price">${data?.type === "dynamic" ? (parseFloat(dynamicAmount) / 1000000000000000000) : (parseFloat(data?.amount) / 1000000000000000000)}</div>
                  </div>
                </div>
              </div>
            </div>



            {/* ---------------------- */}
            {/* <div className="card-body">
              <div className="card-title">Order Summary</div>
              <div className="card-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores ut maxime adipisci rem quam at eligendi debitis consequatur doloremque dolorem dolores, cum magnam sapiente provident cupiditate. Quo quia repellendus corporis!</div>
              <div className="card-plan">
                <div className="card-plan-text">
                  <div className="card-plan-title">Name</div>
                  <div className="card-plan-price">{data?.name}</div>
                </div>
                <div className="card-plan-text">
                  <div className="card-plan-title"> Amount/Duration</div>
                  <div className="card-plan-price">{dynamicAmount / 10 ** 18}$ /
                    <span className="ml-1">
                      {data?.unit === 0 && (data?.time + " " + "Minutes")}
                      {data?.unit === 1 && (data?.time + " " + "Hours")}
                      {data?.unit === 2 && (data?.time + " " + "Days")}
                      {data?.unit === 3 && (data?.time + " " + "Weeks")}
                      {data?.unit === 4 && (data?.time + " " + "Months")}
                      {data?.unit === 5 && (data?.time + " " + "Years")}
                    </span>
                  </div>
                </div>
                {subscribed && quantity &&
                  <div className="card-plan-text">
                    <div className="card-plan-title">Quantity</div>
                    <div className="card-plan-price">{quantity}</div>
                  </div>
                }

              </div>


              {data.type === "dynamic" && !subscribed &&
                <div className="quantityCounter mt-3">
                  <div className="countBtn" onClick={() => handleQuantitySub()}><img src={minusIco} alt="" /></div>
                  <div className="form-group mx-2 mb-0">
                    <input type="number"
                      onChange={(e) => handleQuantity(e)}
                      min="1"
                      value={quantity}
                      placeholder='Quantity' className='form-control' />
                  </div>
                  <div className="countBtn" onClick={() => handleQuantityAdd()}><img src={addIco} alt="" /></div>
                </div>
              }

              {!subscribed && (
                <>
                  {!wallet && (
                    <div className={"card-payment-button"}>
                      <ConnectModal connectMetamask={connectMetamask} connectBitKeep={connectBitKeep} walletConnect={handleWalletConnect} />
                    </div>
                  )}
                  {" "}
                  {wallet && approved && (
                    <div className="card-payment-button">
                      <button onClick={approveAllowance}>
                        Approve
                      </button>
                    </div>
                  )}
                  <div className="card-cancel-button">
                    <button disabled={!wallet || approved} onClick={subscribeUsingCrypto}>
                      Subscribe
                    </button>
                  </div>
                </>
              )}
              {subscribed && (
                <div className="card-payment-button">
                  <button className="cancelBtn" onClick={cancelSubscription} >
                    Cancel Subscription
                  </button>
                </div>
              )}

            </div> */}





          </div>
        </div>

        <Loader loader={loader} />
        {loader && <Loader />}
      </div>
    </div>
  );
};




export const ConnectModal = (props) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button className="crebtn1 mx-auto" onClick={() => setModalShow(true)}>
        Connect Wallet
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        connectMetamask={props.connectMetamask}
        connectBitKeep={props.connectBitKeep}
        walletConnect={props.walletConnect}
      />
    </>
  );
}
function MyVerticallyCenteredModal(props) {
  const handleWalletConnect = () =>{
    props.onHide()
    props.walletConnect()
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="checkout-modal"
    >
      <Modal.Header className="pt-4">
        <Modal.Title id="contained-modal-title-vcenter"> Connect Wallet </Modal.Title>
        <div>
          <img src={close} alt="close" onClick={() => props.onHide()} />
        </div>
      </Modal.Header>
      <div className="tagline"> Select what network and wallet you want to connect below </div>
      <Modal.Body>
        <div className="row my-4">
          <div className="col-4">
            <div className="modal-card">
              <img className="img-fluid" src={metamask} alt="metamask" onClick={() => props.connectMetamask()} />
              <h4>Metamask</h4>
            </div>
          </div>
          <div className="col-4">
            <div className="modal-card">
              <img className="img-fluid" src={bitkeep} alt="bitkeep" onClick={() => props.connectBitKeep()} />
              <h4>Bitkeep</h4>
            </div>
          </div>
          <div className="col-4">
            <div className="modal-card">
              <img className="img-fluid" src={walletconnect} alt="walletconnect" onClick={() => handleWalletConnect()} />
              <h4>Wallet Connect</h4>
            </div>
          </div>
        </div>
      </Modal.Body>

      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default ProductCheckout;
