import { useSelector } from "react-redux";
import { Navigate, Outlet  } from "react-router-dom";

const useAuth = (props) => {
  const user = { loggedIn: false };
  const userStore = useSelector((state) => state.userReducer);

  if (userStore?.users?.token) {
    user.loggedIn = true;
  }

  return user && user.loggedIn;
};

const ProtectedRoutes = (props) => {
  const isAuth = useAuth();

  return isAuth ? <Outlet /> : <Navigate to="/signin" />;
};

export default ProtectedRoutes;
