import React, { useState } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { PrivacyModal } from '../privacyModal'
import { TermsModal } from '../termsModal'
import protocolImg1 from '../../images/protocolImg1.png'
import protocolImg2 from '../../images/protocolImg2.png'
import { Link } from 'react-router-dom'
import './protocol.css'
import { Demo } from '../demo'

export const Protocol = () => {
    const [show, setShow] = useState(false);
    const [showTerms, setShowTerms] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseTerms = () => setShowTerms(false);
    const handleShowTerms = () => setShowTerms(true);

  return (
    <div className="protocolPage homePage">
        <Header />
        <section className="section1">
            <div className="container">
                <div className="row ai-center">
                    <div className="col-lg-7">
                        <div className="imgDiv">
                            <img src={protocolImg1} alt="protocol banner" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <h1>A cutting-edge non-custodial payment processor for web3 Apps.</h1>
                        <p>SiePay empowers you to effortlessly create the automation features that your users have long been pining for.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="section2">
            <div className="container">
                <h1 className='inh1'>Secret To Engaging Users</h1>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="cardM">
                            <h3 className='mb-3'>Creator Platforms</h3>
                            <p>Our APIs and SDKs empower content creators to implement crypto subscriptions and automated billing effortlessly.</p>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="cardM">
                            <h3 className='mb-3'>Lending Applications</h3>
                            <p>Our APIs empower lending platforms, including credit cards and Buy Now, Pay Later (BNPL) products, to effortlessly facilitate automatic loan repayment.</p>
                        </div>
                    </div>
                </div>

                <div className="cardM">
                    <div className="row ai-center">
                        <div className="col-lg-6">
                            <h3 className='mb-4'>Streamline Your Treasury Management Process</h3>
                            <p>Our Web3 treasury management tools seamlessly integrate with SiePay, allowing you to send requests directly to our APIs for smoother and more efficient transactions.</p>
                            <Link className="btn1 dib mt-4" to={"/signin"} >Get Started</Link>
                        </div>
                        <div className="col-lg-6">
                            <div className="imgDiv">
                                <img src={protocolImg2} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='section3'>
            <div className="container">
                <h1 className='inh1'>Easy Integration With Your App</h1>
                <p className="desc">Utilize SiePay's SDK and API endpoints to effortlessly integrate SiePay into your application. Empower your users with the ability to:</p>
                <div className="row">
                    <div className="col-lg-4 mb-4">
                        <div className="cardM">
                            <div className="number">01</div>
                            <h3 className='mb-3'>Creator Platforms</h3>
                            <p>Teams can now devote more time to product development instead of the cumbersome task of coordinating multisig holders for payment. The current process is time-consuming and involves redundant steps as multisig owners have to reconfirm decisions that have already been approved by stakeholders. However, SiePay has revolutionized this process with its automated solution, making the entire process more efficient and hassle-free</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="cardM">
                            <div className="number">02</div>
                            <h3 className='mb-3'>Easily Level Up Your Reporting</h3>
                            <p>Streamline your team's financial processes with SiePay. Say goodbye to the time-consuming and tedious task of reconciling on-chain transactions with invoices and off-chain approvals. Our platform seamlessly matches scheduled transfers with their corresponding on-chain transaction hash, giving you full visibility over your team's financial activities. Experience efficiency like never before.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="cardM">
                            <div className="number">03</div>
                            <h3 className='mb-3'>Make Workflows Seamless and Stress Free</h3>
                            <p>SiePay Streamline your approval processes with SiePay, empowering applications to tailor flows to your operations. Gain approval once and efficiently construct approval flows based on your organizational structure, rather than relying on individual key holders.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Demo />

        <Footer handleShow={handleShow} handleShowTerms={handleShowTerms} />
        <PrivacyModal show={show} handleClose={handleClose} />
        <TermsModal handleCloseTerms={handleCloseTerms} showTerms={showTerms} />
    </div>
  )
}
