import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import api from "../../../api/index";
import { Loader } from "../../Loader";
import { useAlert } from "react-alert";
import noUpTxImg from "../../../images/noUpTxImg.png";




function UpcomingTx() {
  const navigate = useNavigate()

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid subscripPage">
        <div className="row">
          <div className="col">
            <div className="upcommingPage">
                <h2>You have no upcoming automated transactions</h2>
                <div className="noUpTxImg">
                    <img className='img-fluid' src={noUpTxImg} alt="no Tx" />
                </div>
            </div>
          </div>
        </div>
      </div>
      {/* /.container-fluid */}

    </>
  )
}




export { UpcomingTx };