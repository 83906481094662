import { useState, useEffect } from "react";
import Web3 from "web3";
import web3Object from "../web3/web3";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



export const web3Initializer = async () => {
  try {
    const web3 = web3Object;

    if (window.ethereum)
      web3.web3 = new Web3(window.ethereum);
  } catch (e) {
    console.log("Connection failed with metamask")
  }
};

export const getAccount = async () => {
  try {
    const web3 = web3Object;
    console.log(web3.web3._provider)
    const accountDetails = await web3.web3._provider.request({
      method: "eth_requestAccounts",
    });

    if (!accountDetails.length) {
      console.log("please connect to metamask account!");

      return null;
    } else if (accountDetails[0]) {
      return accountDetails[0];
    }
  } catch (e) {
    console.log("Metamask account fetching is un-successfull=>", e.message);
  }
};





export const ConnectModal = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Connect Your Wallet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="row">
          <div className="col-4">
            <h4>Metamask</h4>
            <button className="btn-success btn">Connect Now</button>
          </div>
          <div className="col-4">
            <h4>Wallet Connect</h4>
            <button className="btn-success btn">Connect Now</button>
          </div>
          <div className="col-4">
            <h4>Bitkeep</h4>
            <button className="btn-success btn">Connect Now</button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}