import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { useAlert } from "react-alert";
import { Loader } from "../../Loader";

import getStarted1 from "../../../images/updated-img/getStarted1.png";
import getStarted2 from "../../../images/updated-img/getStarted2.png";
import getStarted3 from "../../../images/updated-img/getStarted3.png";
import addProduct from "../../../images/addProduct.png"
import uploadIcon from "../../../images/uploadIco.svg"
import close from "../../../images/close.svg";
import arrowDown from "../../../images/arrowDown.svg";

import "./getStarted.css"
import api from '../../../api';



function GetStarted() {
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const alert = useAlert()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submit = async (e) => {
    e.preventDefault();
    console.log(data);

    if (data?.fullname && data?.gender && data?.designation && data?.id_passport && data?.businessName && data?.businessAddress && data?.businessType && data?.businessWebsite && data?.estimatedMonthlyVolume && data?.idImg && data?.companyDocumentImg) {
      try {
        setLoader(true);
        const formData = new FormData();
        formData.append("fullname", data.fullname);
        formData.append("gender", data.gender);
        formData.append("designation", data.designation);
        formData.append("id_passport", data.id_passport);
        formData.append("businessName", data.businessName);
        formData.append("businessAddress", data.businessAddress);
        formData.append("businessType", data.businessType);
        formData.append("businessWebsite", data.businessWebsite);
        formData.append("estimatedMonthlyVolume", data.estimatedMonthlyVolume);
        formData.append("idImg", data.idImg, data.idImg.name);
        formData.append("companyDocumentImg", data.companyDocumentImg, data.companyDocumentImg.name);
        // const uploadRes = await api.uploadFile(formData);
        // console.log(uploadRes);
        // setSelectedImg(
        //   `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${uploadRes[0]}.${uploadRes[1]}`
        // );

        // let sendData={...data, idImg:"testing",companyDocumentImg:"testing"};
        const response = await api.sendKyc(formData)
        console.log(response);
        if (response?.msg == "send successfully") {
          handleClose()
          setData({})
          alert.show("KYC data sent, wait for response")
          setLoader(false);
        }else{
          handleClose()
          setLoader(false);
          alert.show(response?.msg)
        }

      } catch (e) {
        console.log(e);
        alert.show("Something went wrong.")
        setLoader(false);
      }
    } else {
      alert.show("Please fill all fields")
    }


  };


  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid getStartedPage">
        <div className="row1">
          <h1>Get Started</h1>
          <p>Only a few steps to start receive your payments.</p>
        </div>
        <div className="row2">
          <div className="col1">
            <h3>Get Verified</h3>
            <p>Submit your business details and get verified.</p>
          </div>
          <div className="col2">
            <button className="gradBtn fs-12 " onClick={() => handleShow()}>Get Verified</button>
          </div>
        </div >
        <div className="row mx-auto">
          <div className="col-md-4 px-0">
            {/* <div className="payCard">
              <div className="imgDiv">
                <img className='img-fluid' src={getStarted1} alt="getStarted1" />
              </div>
              <h3>Create a Product.</h3>
              <div className="desc">Add a product with subscription price and set your receiving period.</div>
              <button className="gradBtn fs-12 ">STEP 01</button>
            </div> */}
            <div className="row payCard ml-1">
              <div className="col-lg-5 col-sm-12">
                <div className="imgDiv">
                  <img className='img-fluid' src={getStarted1} alt="getStarted1" />
                </div>
              </div>
              <div className="col-lg-7 col-sm-12">
                <h3>Pay Checkout</h3>
                <div className="desc">Receive crypto payments on online sales</div>
                <button className="gradBtn fs-12 ">See details</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 px-0">
            <div className="row payCard mx-1">
              <div className="col-lg-5 col-sm-12">
                <div className="imgDiv">
                  <img className='img-fluid' src={getStarted2} alt="getStarted2" />
                </div>
              </div>
              <div className="col-lg-7 col-sm-12">
                <h3>Invoicing</h3>
                <div className="desc">Create and send invoices</div>
                <button className="gradBtn fs-12 ">See details</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 px-0">
            <div className="row payCard mr-1">
              <div className="col-lg-5 col-sm-12">
                <div className="imgDiv">
                  <img className='img-fluid' src={getStarted3} alt="getStarted3" />
                </div>
              </div>
              <div className="col-lg-7 col-sm-12">
                <h3>Subscription</h3>
                <div className="desc">Create and send subscription based payments</div>
                <button className="gradBtn fs-12 ">See details</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /.container-fluid */}



      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="productModal">
            <div className="closeIco" onClick={handleClose}><img src={close} alt="close" /></div>
            <div className="logoRow">
              <img src={addProduct} alt="" />
              <div className='ml-2'>
                <h6 className='mb-0'>KYC</h6>
                <p className='mb-0'>Complete your KYC to get verified</p>
              </div>
            </div>
            <form onSubmit={(e) => submit(e)}>

              <div className="form-group">
                <label >Full Name</label>
                <input type="text"
                  onChange={(e) => setData({ ...data, fullname: e.target.value })}
                  placeholder='Enter your Name' className='form-control' required />
              </div>

              <div className="d-flex">
                <div className="form-group w-100 mr-2">
                  <label >Select Gender</label>
                  <div className="dropdown no-arrow nav-item ">
                    <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div className="profile-div w-100 ml-2 text-uppercase">
                        {data?.gender ? data?.gender : "Please select gender"}
                      </div>
                    </a>
                    {/* Dropdown - User Information */}
                    <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                      <a className="dropdown-item text-uppercase" href="#" onClick={(e) => setData({ ...data, gender: "male" })}>
                        <div className="Icoimg"> Male </div>
                      </a>
                      <a className="dropdown-item text-uppercase" href="#" onClick={(e) => setData({ ...data, gender: "female" })}>
                        <div className="Icoimg"> Female </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label >Designation</label>
                <input type="text"
                  onChange={(e) => setData({ ...data, designation: e.target.value })}
                  placeholder='Enter your designation' className='form-control' required />
              </div>

              <div className="form-group">
                <label >ID/Passport Number</label>
                <input type="text"
                  onChange={(e) => setData({ ...data, id_passport: e.target.value })}
                  placeholder='Enter your ID/Passport Number' className='form-control' required />
              </div>

              <div className="form-group">
                <label >Business Name</label>
                <input type="text"
                  onChange={(e) => setData({ ...data, businessName: e.target.value })}
                  placeholder='Enter your Business Name' className='form-control' required />
              </div>

              <div className="form-group">
                <label >Business Address</label>
                <input type="address"
                  onChange={(e) => setData({ ...data, businessAddress: e.target.value })}
                  placeholder='Enter your Business Address' className='form-control' required />
              </div>

              <div className="d-flex">
                <div className="form-group w-100 mr-2">
                  <label >Business Type</label>
                  <div className="dropdown no-arrow nav-item ">
                    <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div className="profile-div w-100 ml-2 text-uppercase">
                        {data?.businessType ? data?.businessType : "Please select Business Type"}
                      </div>
                    <img src={arrowDown} alt="" className="arrowDown" />
                    </a>
                    {/* Dropdown - User Information */}
                    <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                      <a className="dropdown-item text-uppercase" href="#" onClick={(e) => setData({ ...data, businessType: "proprietorship" })}>
                        <div className="Icoimg"> Sole Proprietorship </div>
                      </a>
                      <a className="dropdown-item text-uppercase" href="#" onClick={(e) => setData({ ...data, businessType: "partnership" })}>
                        <div className="Icoimg"> Partnership </div>
                      </a>
                      <a className="dropdown-item text-uppercase" href="#" onClick={(e) => setData({ ...data, businessType: "LLC" })}>
                        <div className="Icoimg"> Limited Liability Company (LLC) </div>
                      </a>
                      <a className="dropdown-item text-uppercase" href="#" onClick={(e) => setData({ ...data, businessType: "corporation" })}>
                        <div className="Icoimg"> Corporation </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label >Business Website</label>
                <input type="text"
                  onChange={(e) => setData({ ...data, businessWebsite: e.target.value })}
                  placeholder='Enter your Business Website' className='form-control' required />
              </div>

              <div className="form-group">
                <label >Estimated Monthly Volume</label>
                <input type="text"
                  onChange={(e) => setData({ ...data, estimatedMonthlyVolume: e.target.value })}
                  placeholder='Enter your Estimated Monthly Volume' className='form-control' required />
              </div>

              <div className="d-flex mb-3">
                <div className="form-group uploadFile mr-3">
                  <label>
                    {data?.idImg ?
                      <img src={URL.createObjectURL(data?.idImg)} alt="upload" />
                      :
                      <img src={uploadIcon} alt="upload" />
                    }
                    <input type="file"
                      onChange={(e) => setData({ ...data, idImg: e.target.files[0] })}
                      placeholder='Upload your ID' className='form-control' />
                  </label>
                  <div className="text-center title">Upload ID</div>
                </div>

                <div className="form-group uploadFile">
                  <label>
                    {data?.companyDocumentImg ?
                      <img src={URL.createObjectURL(data?.companyDocumentImg)} alt="upload" />
                      :
                      <img src={uploadIcon} alt="upload" />
                    }
                    <input type="file"
                      onChange={(e) => setData({ ...data, companyDocumentImg: e.target.files[0] })}
                      placeholder='Upload your Company Document' className='form-control' />
                  </label>
                  <div className="text-center title">Upload Company Document</div>
                </div>
              </div>

              <div className="btnMain text-center">
                <button className="gradBtn fs-12 " >Verify Now</button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Loader loader={loader} />

    </>
  )
}


export { GetStarted };
