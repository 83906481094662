import React from 'react'
import { Modal } from 'react-bootstrap'
import close from "../images/close.svg";
import logo from "../images/logo.svg";

export const TermsModal = ({showTerms,handleCloseTerms}) => {
  return (
    <Modal show={showTerms} onHide={handleCloseTerms} className="privacyModal" >
                <Modal.Body>
                    <div className="productModal p-2 p-sm-4">
                        <div className="closeIco" onClick={handleCloseTerms}><img src={close} alt="close" /></div>
                        <div className="logoRow jc-center">
                            <img src={logo} alt="" />
                        </div>
                        <div className='ml-2 text-center mb-5'>
                            <h1 className='mb-0'>SiePay Terms & Conditions</h1>
                            <p className='mb-0'>The Easiest Crypto Recurring Payment System For Businesses & Individuals.</p>
                        </div>
                        <div className="privacyMain">
                            <p>These terms and conditions (the "Terms") govern the use of the recurring payment system for cryptocurrency transactions (the "Service") offered by SiePay   (the "Company"). By using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, you should not use the Service.</p>
                            <p>SiePay   reserves the right to periodically update its Platform, as well as this Agreement. Notifications of these updates will be available on the platform or through other communications from SiePay  . If You disagree with any modifications made, Your option is to discontinue use of the Platform and/or terminate this Agreement at any time; however continued usage constitutes acceptance for all changes that have been presented.</p>
                            <p>Eligibility: To be eligible to use the Service, you must be at least 18 years of age and have the legal capacity to enter into a binding agreement.</p>
                            <p>Use of the Service: The Service allows for the scheduling of recurring cryptocurrency transactions. You are responsible for ensuring that the cryptocurrency wallet addresses provided to the Company are accurate and belong to the intended recipient. The Company will not be liable for any errors or omissions in the wallet address provided by you.</p>
                            <p>Fees: The Company is not charging any fees from the user for the use of the Service. However, SiePay   is charging a transaction fee from the merchant. In future, SiePay may charge the fees from the direct user. These fees will be clearly disclosed to you prior to scheduling a recurring transaction.</p>
                            <p>Refunds: In cases where a refund is requested due to incorrect billing information or other errors, the amount refunded will only cover the amount of the disputed transaction(s). Any associated fees charged by the System will not be refunded under any circumstances unless otherwise stated in writing by an authorized representative of the System at the time of purchase. Additionally, it is expected that customers resolve disputes with merchants directly rather than through the System itself; if such a dispute cannot be resolved amicably between parties then users should contact their local consumer protection agency for further assistance if necessary.  </p>
                            <p>Cancellations & Terminations: Users may cancel recurring payments at any time provided they follow applicable procedures outlined by their merchant of choice as well as those specified by the Crypto Recurring Payment System itself; once canceled, no further payments will be processed from that user’s account until reactivated in writing by an authorized representative of said merchant/System combination. Termination of services can occur when either party (user or merchant) deems it necessary without notice; in such cases users must contact their merchant directly regarding any outstanding balances or refunds due upon termination of services with said merchant/System combination which may have already been processed at time of notice but not yet received (i.e., pending status).  </p>
                            <p>Liability Limitations & Exclusions: The Crypto Recurring Payment System is not liable for any loss or damages resulting from use of its system including but not limited to security breaches, unauthorized access, incorrect data entry/transmission errors etc., it does guarantee that transactions made through its system are legitimate and will appear on users’ accounts accordingly as confirmed during the transaction review stage prior to completing the payment process with merchant/System combination selected at time of checkout (checkout includes both confirmation and review stages).</p>
                            <p>Entire Agreement: These Terms constitute the entire agreement between you and the Company regarding the use of the Service and supersede all prior agreements and understandings, whether written or oral.</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
  )
}
