import React from "react";
import fee from "../images/compress/fee.webp";
import checkMark from "../images/checkMark.svg";

const TFee = () => {
  return (
    <>

    <section className="sec4">


      <div className="container">
        <div className="row ai-center">
         

          <div className="col-lg-6">
            <div className="img4 animatable slideInRight">
              <img className="img-fluid" src={fee} />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="">
              <h1 className="h1enable animatable slideInUp mt-0">Transaction FEE</h1>
              <h3 className="noFee">No transaction fee for user</h3>
              <p className="pmerchant animatable slideInUp mt-1">Siepay is a cryptocurrency platform that does not charge any transaction fees to users. Instead, it charges 0.1% from merchants for use of their service and this makes SiePay a payment processing company that offers users no transaction fees and low rates for merchants. </p> 
              <h6 className="lFee"><img className="mr-2" src={checkMark} alt="" />Low Fees</h6>
              <h6 className="lFee"><img className="mr-2" src={checkMark} alt="" />No Lock of funds</h6>
              <h6 className="lFee"><img className="mr-2" src={checkMark} alt="" />No chargebacks</h6>
            </div>
          </div>

        </div>
      </div>

      </section>
    </>
  );
};

export default TFee;
