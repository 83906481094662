import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import api from "../../../api/index";
import { Loader } from "../../Loader";
import bigNumber from "bignumber.js";
import { useAlert } from "react-alert";
import copy from 'copy-to-clipboard';

import Modal from 'react-bootstrap/Modal';
import chevronDown from "../../../images/chevronDown.svg";
import close from "../../../images/close.svg";
import addProduct from "../../../images/addProduct.png";
import ethIco from "../../../images/ethIco.png";
import bscIco from "../../../images/bscIco.png";
import polygonIco from "../../../images/polygonIco.svg";
import tronIco from "../../../images/tronIco.png";
import usdtIco from "../../../images/usdtIco.png";
import usdcIco from "../../../images/usdcIco.png";
import busdIco from "../../../images/busdIco.png";
import view from "../../../images/view.png";

import copyicon from "../../../images/link.png";
import iframelinkicon from "../../../images/photo.png";
import editIco from "../../../images/editIco.svg";
import checkIcon from "../../../images/updated-img/check-icon.png";

function CustomerProduct() {
  const navigate = useNavigate()


  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid subscripPage">
        <div className="row">
          <div className="col pb-3">
            <Table />
          </div>
        </div>
      </div>
      {/* /.container-fluid */}

    </>
  )
}


const Table = () => {
  const customerObject = useSelector((state) => state.customerReducer.customer);
  const alert = useAlert()
  const [productData, setProductData] = useState({
    network: "eth",
    currency: "usdt"
  });
  const [editData, setEditData] = useState({});
  const [viewData, setViewData] = useState({});

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const [showView, setShowView] = useState(false);
  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);

  const [copyLink, setCopyLink] = useState("Copy Link");
  const [copyCode, setCopyCode] = useState("Copy Embed Code");
  const [copyAddress, setCopyAddress] = useState("Copy Address");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);
  const [docCount, setDocCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [step, setStep] = useState(1);


  //  To Fetch All Products
  const fetchAllProducts = async (skip) => {
    try {
        if(customerObject?.wallet) {
            //   setLoader(true);
              let fil = {
                skip: skip,
                pageSize: pageLimit,
                address:customerObject?.wallet,
              }
              const response = await api.getAllProductByCustomer(fil);
              if (response.product) {
                setData([...data, ...response?.product]);
                setLoader(false);
                setDocCount(response?.docCount)
                console.log(response?.docCount);
                console.log(response?.product);
              }
        }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  const handleCopyAdr = (address) => {
    copy(address)
    setCopyAddress("copied")
    setTimeout(() => {
      setCopyAddress("Copy Address")
    }, 3000);
  }
  //open edit product modal
  const openEditM = (item, index) => {
    console.log(item);
    setCurrentIndex(index)

    handleShowEdit()
    setEditData({ _id: item._id, name: item.name, webhookUrl: item?.webhookUrl })
  }
  //open view product modal
  const openViewM = (item) => {
    console.log(item);
    handleShowView()
    setViewData(item)
  }

  const submitEditProduct = async () => {
    console.log(editData);
    setLoader(true);

    let response = await api.editProduct(editData)
    console.log(response);
    if (response.error) {
      setLoader(false);
      return alert.show("Error while updating product.");
    }
    else {
      let tempData = [...data]
      tempData.splice(currentIndex, 1, response.data)
      setData(tempData)

      // setData([])
      alert.show("Product update successfully");
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1000);
      // await fetchAllProducts(0);
      setLoader(false);
      setProductData({});
      handleCloseEdit()
      // window.location.reload()
    }


  }
  const handleShowMore = async () => {
    setPageSkip(pageSkip + pageLimit)
    console.log(pageSkip + pageLimit);
    await fetchAllProducts(pageSkip + pageLimit);
  }
  useEffect(() => {
    fetchAllProducts(0)
    console.log("data");
  }, [customerObject])

  return (
    <>
      {/* ------------------------- Musto Updates ------------------------- */}
      <div className="m-pi-header">
        <div className='d-flex justify-content-between flex-wrap'>
          <h3 className='mb-0  mt-2 mb-4'>Products</h3>
        </div>
        {/* <div className="pi-filters">
          <div className={`filter ${activeTab === 1 && "active"}`} onClick={() => setActiveTab(1)}>All</div>
          <div className={`filter ${activeTab === 2 && "active"}`} onClick={() => setActiveTab(2)}>Active</div>
          <div className={`filter ${activeTab === 3 && "active"}`} onClick={() => setActiveTab(3)}>Pending</div>
        </div> */}
      </div>

      <div className="card sortby-box br-10 border-transparent m-pi-data">
        <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
          <table className="table table-hover table-striped m-pi-table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Price</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Address</th>
                <th scope="col">Subscription Period</th>
                <th scope="col">Created</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, key) => (
                <tr key={key}>
                  <td>
                    <div className='font-500'>{item?.name}</div>
                  </td>
                  <td>
                    <div className='price'>${item?.amount / 10 ** 18}  </div>
                  </td>
                  <td>
                    <div>{item?.unitPrice ? "$" + item?.unitPrice : "-"}  </div>
                  </td>
                  <td>
                    <div className="copyLink">
                      <div className='d-flex jc-center'>
                        <span className='' onClick={() => handleCopyAdr(item?.address)}> {item?.address.slice(0,4)}...{item?.address.slice(item?.address.length-4,item?.address.length)}</span>
                      </div>
                      <div className="copyText">{copyAddress}</div>
                    </div>

                  </td>

                  <td>
                    <div>
                      {item?.unit == 0 && item?.time == 5 && "5-minutes"}
                      {item?.unit == 0 && item?.time == 10 && "10-minutes"}
                      {item?.unit == 3 && item?.time == 1 && "weekly"}
                      {item?.unit == 3 && item?.time == 2 && "bi weekly"}
                      {item?.unit == 4 && item?.time == 1 && "monthly"}
                      {item?.unit == 4 && item?.time == 2 && "bi monthly"}
                      {item?.unit == 4 && item?.time == 4 && "quaterly"}
                      {item?.unit == 4 && item?.time == 6 && "half yearly"}
                      {item?.unit == 5 && item?.time == 1 && "yearly"}
                    </div>
                  </td>
                  <td>
                    <div>{moment(item?.createdAt).format("DD MMM YYYY")}</div>
                  </td>
                
               
                </tr>
              ))}
            </tbody>
          </table>
          {
            docCount != data.length && docCount >= pageLimit &&
            <div className='showMoreMain text-center'>
              <button className="gradBtn fs-12" onClick={handleShowMore}> Show More </button>
            </div>
          }
        </div>
      </div>

      {/* ------------------------- END ------------------------- */}


      {/* <div className="card sortby-box br-10 border-transparent" style={{ marginTop: "250px" }}>
        <div className="card-header bg-white border-transparent pt-3 ">
          <div className="row1">
            <div className="">
              <h3 className='mb-0'>Products Integration Guide</h3>
            </div>
            <div>
              <button className="gradBtn fs-12 " onClick={handleShow}>Add a Product</button>
            </div>
          </div>
        </div>
        <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Price</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Name</th>
                <th scope="col">Address</th>
                <th scope="col">Subscription Period</th>
                <th scope="col">Created</th>
                <th scope="col">Share</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, key) => (
                <tr>
                  <td>
                    <div>${item?.amount / 10 ** 18}  </div>
                  </td>
                  <td>
                    <div>{item?.unitPrice ? "$" + item?.unitPrice : "-"}  </div>
                  </td>
                  <td>
                    <div className='font-500'>{item?.name}</div>
                  </td>
                  <td>
                    <div className="copyLink">
                      <div className='d-flex jc-center'>
                        <span className='limitedAddress' onClick={() => handleCopyAdr(item?.address)}> {item?.address}</span> ...
                      </div>
                      <div className="copyText">{copyAddress}</div>
                    </div>

                  </td>

                  <td>
                    <div>
                      {item?.unit == 0 && item?.time == 5 && "5-minutes"}
                      {item?.unit == 0 && item?.time == 10 && "10-minutes"}
                      {item?.unit == 3 && item?.time == 1 && "weekly"}
                      {item?.unit == 3 && item?.time == 2 && "bi weekly"}
                      {item?.unit == 4 && item?.time == 1 && "monthly"}
                      {item?.unit == 4 && item?.time == 2 && "bi monthly"}
                      {item?.unit == 4 && item?.time == 4 && "quaterly"}
                      {item?.unit == 4 && item?.time == 6 && "half yearly"}
                      {item?.unit == 5 && item?.time == 1 && "yearly"}
                    </div>
                  </td>
                  <td>
                    <div>{moment(item?.createdAt).format("DD MMM YYYY")}</div>
                  </td>
                  <td>
                    <div className="icons-cont">
                      <div className="copyLink">
                        <img className='mr-2' onClick={() => generateCopyLink(item)} src={copyicon} />
                        <div className="copyText">{copyLink}</div>
                      </div>
                      <div className="copyLink">
                        <img onClick={() => generateIframe(item)} src={iframelinkicon} />
                        <div className="copyText">{copyCode}</div>
                      </div>

                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <div className='editIco mr-2' onClick={() => openViewM(item)}>
                        <img style={{ width: 25 }} src={view} alt="" />
                      </div>
                      <div className='editIco' onClick={() => openEditM(item, key)}>
                        <img src={editIco} alt="" />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
          {docCount != data.length && docCount >= pageLimit &&
            <div className='showMoreMain text-center'>
              <button className="gradBtn fs-12" onClick={handleShowMore}>
                Show More
              </button>
            </div>
          }
        </div>
      </div> */}

      <Modal show={show} onHide={handleClose} centered={true}>
        <Modal.Body>
          <div className="m-product-modal pb-3" >
            <div className="closeIco" onClick={handleClose}><img src={close} alt="close" /></div>
            <div className='m-add-product-head'>
              <div className={`m-product-modal-tab ${step === 1 && "active"}`} onClick={() => setStep(1)} >Step 1</div>
              <div className={`m-product-modal-tab ${step === 2 && "active"}`} onClick={() => setStep(2)} >Step 2</div>
              <div className={`m-product-modal-tab ${step === 3 && "active"}`} onClick={() => setStep(3)} >Step 3</div>
            </div>

            <form className="m-signin-form productModal p-0" onSubmit={(e) => e.preventDefault()}>
              <h3 style={{ color: "#0F1F3B", textAlign: "center" }}>Add Product </h3>
              <p style={{ color: "#0F1F3B", textAlign: "center" }}>Subscription plan depend on what you need</p>

              {
                step === 1 ?
                  <>
                    <div className="form-input modalInput">
                      <input
                        value={productData?.name}
                        onChange={(e) =>
                          setProductData({ ...productData, name: e.target.value })
                        }
                        placeholder='Product Name' className="secdiv"
                        style={{ fontSize: "12px" }}
                      />
                    </div>
                    <div className="form-input modalInput">
                      <input
                        value={productData?.address}
                        onChange={(e) =>
                          setProductData({ ...productData, address: e.target.value })
                        }
                        placeholder='Enter wallet address'
                        className="secdiv"
                        style={{ fontSize: "12px" }}
                      />
                    </div>
                    <div className="d-flex">
                      <div className="form-group w-100 mr-2">
                        <div className="dropdown no-arrow nav-item ">
                          <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="profile-div w-100 ml-2 text-uppercase">
                              {productData?.network == "bsc" &&
                                <div className="Icoimg">
                                  <img className='' src={bscIco} alt="" />
                                </div>
                              }
                              {productData?.network == "eth" &&
                                <div className="Icoimg">
                                  <img className='' src={ethIco} alt="" />
                                </div>
                              }
                              {productData?.network == "polygon" &&
                                <div className="Icoimg">
                                  <img className='' src={polygonIco} alt="" />
                                </div>
                              }
                              {productData?.network == "tron" &&
                                <div className="Icoimg">
                                  <img className='' src={tronIco} alt="" />
                                </div>
                              }
                              {productData?.network ? productData?.network : "Please select Network"}
                            </div>
                            <div>
                              <img src={chevronDown} className="w-9 ml-1" alt="dropdown icon" />
                            </div>
                          </a>
                          {/* Dropdown - User Information */}
                          <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, network: "eth" })}>
                              <div className="Icoimg">
                                <img className='' src={ethIco} alt="" />
                              </div>
                              Eth
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, network: "bsc" })}>
                              <div className="Icoimg">
                                <img className='' src={bscIco} alt="" />
                              </div>
                              Bsc
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, network: "polygon" })}>
                              <div className="Icoimg">
                                <img className='' src={polygonIco} alt="" />
                              </div>
                              Polygon
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, network: "tron" })}>
                              <div className="Icoimg">
                                <img className='' src={tronIco} alt="" />
                              </div>
                              Tron
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="form-group w-100">
                        <div className="dropdown no-arrow nav-item ">
                          <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="profile-div w-100 ml-2 text-uppercase">
                              {productData?.currency == "usdt" &&
                                <div className="Icoimg">
                                  <img className='' src={usdtIco} alt="" />
                                </div>
                              }
                              {productData?.currency == "usdc" &&
                                <div className="Icoimg">
                                  <img className='' src={usdcIco} alt="" />
                                </div>
                              }
                              {productData?.currency == "busd" &&
                                <div className="Icoimg">
                                  <img className='' src={busdIco} alt="" />
                                </div>
                              }
                              {productData?.currency ? productData?.currency : "Please select Currency"}
                            </div>
                            <div>
                              <img src={chevronDown} className="w-9 ml-1" alt="dropdown icon" />
                            </div>
                          </a>
                          {/* Dropdown - User Information */}
                          <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, currency: "usdt" })}>
                              <div className="Icoimg">
                                <img className='' src={usdtIco} alt="" />
                              </div>
                              USDT
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, currency: "usdc" })}>
                              <div className="Icoimg">
                                <img className='' src={usdcIco} alt="" />
                              </div>
                              USDC
                            </a>
                            <a className="dropdown-item text-uppercase" href="#"
                              onClick={(e) => setProductData({ ...productData, currency: "busd" })}>
                              <div className="Icoimg">
                                <img className='' src={busdIco} alt="" />
                              </div>
                              BUSD
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center m-signup-submit mt-2 ml-0">
                      <button className="crebtn1 ml-0" style={{ width: "80%" }} onClick={() => setStep(2)}> Continue </button>
                    </div>
                  </>
                  :
                  step === 2 ?
                    <>
                      <div className="form-group mb-0">
                        <div className="dropdown no-arrow nav-item ">
                          <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="profile-div w-100 ml-2" style={{ color: "#707070", fontSize: "12px" }}>
                              {productData?.unit ?
                                productData?.unit
                                : "Please select Time"}
                            </div>
                            <div>
                              <img src={chevronDown} className="w-9 ml-1" alt="dropdown icon" />
                            </div>
                          </a>
                          {/* Dropdown - User Information */}
                          <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "5-minutes" })}>
                              5-minutes
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "10-minutes" })}>
                              10-minutes
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "weekly" })}>
                              weekly
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "bi weekly" })}>
                              bi weekly
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "monthly" })}>
                              monthly
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "bi monthly" })}>
                              bi monthly
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "quaterly" })}>
                              quaterly
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "half yearly" })}>
                              half yearly
                            </a>
                            <a className="dropdown-item" href="#"
                              onClick={(e) => setProductData({ ...productData, unit: "yearly" })}>
                              yearly
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="form-input modalInput">
                        <input
                          value={productData?.webhookUrl}
                          onChange={(e) =>
                            setProductData({ ...productData, webhookUrl: e.target.value })
                          }
                          placeholder='Webhook Url' className="secdiv"
                          style={{ fontSize: "12px" }}
                        />
                      </div>

                      <div className="text-center m-signup-submit mt-2 ml-0">
                        <button className="crebtn1 ml-0" style={{ width: "80%" }} onClick={() => setStep(3)}> Continue </button>
                      </div>
                    </>
                    :
                    <>
                      <div className='row justify-content-between'>
                        <div className="col-6 form-input modalInput">
                          <div className={`checkBoxModal ${productData.type === "fixed" && "active"}`} onClick={(e) => setProductData({ ...productData, type: "fixed" })}>
                            <p>Fixed Price</p>
                            <p className='opiop'><img src={checkIcon} alt="checkIcon" /></p>
                          </div>
                        </div>
                        <div className="col-6 form-input modalInput">
                          <div className={`checkBoxModal ${productData.type === "dynamic" && "active"}`} onClick={(e) => setProductData({ ...productData, type: "dynamic" })}>
                            <p>Dynamic Price</p>
                            <p className='opiop'><img src={checkIcon} alt="checkIcon" /></p>
                          </div>
                        </div>
                      </div>

                      <div className="form-input modalInput">
                        <input
                          value={productData?.amount}
                          onChange={(e) =>
                            setProductData({ ...productData, amount: e.target.value })
                          }
                          placeholder='Enter Price'
                          className="secdiv"
                          style={{ fontSize: "12px" }}
                        />
                      </div>
                      {
                        productData.type === "dynamic" &&
                        <div className="form-input modalInput">
                          <input
                            value={productData?.unitPrice}
                            onChange={(e) =>
                              setProductData({ ...productData, unitPrice: e.target.value })
                            }
                            placeholder='Enter Unit Price'
                            className="secdiv"
                            style={{ fontSize: "12px" }}
                          />
                        </div>
                      }

                    
                    </>
              }
            </form>
          </div>


          {/* <div className="logoRow">
              <img src={addProduct} alt="" />
              <div className='ml-2'>
                <h6 className='mb-0'>Add Product</h6>
                <p className='mb-0'>Subscription plan depend on what you need</p>
              </div>
            </div> */}

          {/* <div className="form-group">
              <label >Name</label>
              <input type="text"
                onChange={(e) =>
                  setProductData({ ...productData, name: e.target.value })
                }
                placeholder='Enter product name' className='form-control' />
            </div> */}
          {/* <div className="form-group">
              <label >Address</label>
              <div className="d-flex">
                <input type="text"
                  value={productData?.address}
                  onChange={(e) =>
                    setProductData({ ...productData, address: e.target.value })
                  }
                  placeholder='Enter wallet address' className='form-control' />
              </div>
            </div> */}
          {/* <div className="d-flex">
              <div className="form-group w-100 mr-2">
                <label >Select network</label>
                <div className="dropdown no-arrow nav-item ">
                  <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="profile-div w-100 ml-2 text-uppercase">
                      {productData?.network == "bsc" &&
                        <div className="Icoimg">
                          <img className='' src={bscIco} alt="" />
                        </div>
                      }
                      {productData?.network == "eth" &&
                        <div className="Icoimg">
                          <img className='' src={ethIco} alt="" />
                        </div>
                      }
                      {productData?.network == "polygon" &&
                        <div className="Icoimg">
                          <img className='' src={polygonIco} alt="" />
                        </div>
                      }
                      {productData?.network == "tron" &&
                        <div className="Icoimg">
                          <img className='' src={tronIco} alt="" />
                        </div>
                      }
                      {productData?.network ? productData?.network : "Please select Network"}
                    </div>
                    <div>
                      <img src={chevronDown} className="w-9 ml-1" alt="dropdown icon" />
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                    <a className="dropdown-item text-uppercase" href="#"
                      onClick={(e) => setProductData({ ...productData, network: "eth" })}>
                      <div className="Icoimg">
                        <img className='' src={ethIco} alt="" />
                      </div>
                      Eth
                    </a>
                    <a className="dropdown-item text-uppercase" href="#"
                      onClick={(e) => setProductData({ ...productData, network: "bsc" })}>
                      <div className="Icoimg">
                        <img className='' src={bscIco} alt="" />
                      </div>
                      Bsc
                    </a>
                    <a className="dropdown-item text-uppercase" href="#"
                      onClick={(e) => setProductData({ ...productData, network: "polygon" })}>
                      <div className="Icoimg">
                        <img className='' src={polygonIco} alt="" />
                      </div>
                      Polygon
                    </a>
                    <a className="dropdown-item text-uppercase" href="#"
                      onClick={(e) => setProductData({ ...productData, network: "tron" })}>
                      <div className="Icoimg">
                        <img className='' src={tronIco} alt="" />
                      </div>
                      Tron
                    </a>
                  </div>
                </div>
              </div>

              <div className="form-group w-100">
                <label >Select Currency</label>
                <div className="dropdown no-arrow nav-item ">
                  <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="profile-div w-100 ml-2 text-uppercase">
                      {productData?.currency == "usdt" &&
                        <div className="Icoimg">
                          <img className='' src={usdtIco} alt="" />
                        </div>
                      }
                      {productData?.currency == "usdc" &&
                        <div className="Icoimg">
                          <img className='' src={usdcIco} alt="" />
                        </div>
                      }
                      {productData?.currency == "busd" &&
                        <div className="Icoimg">
                          <img className='' src={busdIco} alt="" />
                        </div>
                      }
                      {productData?.currency ? productData?.currency : "Please select Currency"}
                    </div>
                    <div>
                      <img src={chevronDown} className="w-9 ml-1" alt="dropdown icon" />
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                    <a className="dropdown-item text-uppercase" href="#"
                      onClick={(e) => setProductData({ ...productData, currency: "usdt" })}>
                      <div className="Icoimg">
                        <img className='' src={usdtIco} alt="" />
                      </div>
                      USDT
                    </a>
                    <a className="dropdown-item text-uppercase" href="#"
                      onClick={(e) => setProductData({ ...productData, currency: "usdc" })}>
                      <div className="Icoimg">
                        <img className='' src={usdcIco} alt="" />
                      </div>
                      USDC
                    </a>
                    <a className="dropdown-item text-uppercase" href="#"
                      onClick={(e) => setProductData({ ...productData, currency: "busd" })}>
                      <div className="Icoimg">
                        <img className='' src={busdIco} alt="" />
                      </div>
                      BUSD
                    </a>
                  </div>
                </div>
              </div>
            </div> */}

          {/* <div className="form-group">
              <label >Webhook Url <span className='optional'>(optional)</span></label>
              <div className="d-flex">
                <input type="text"
                  onChange={(e) =>
                    setProductData({ ...productData, webhookUrl: e.target.value })
                  }
                  placeholder='Enter price' className='form-control' />
              </div>
            </div> */}

          {/* <div className="sPaymentM">
              <label className='mb-0'>Product type:</label>
              <div className='d-flex'>
                <div className={productData.type == "fixed" ? 'anual mr-3 green' : 'anual mr-3'} >Fixed
                  <input className='ml-1' type="radio" name="type" value="fixed"
                    onChange={(e) => setProductData({ ...productData, type: e.target.value })} />
                </div>
                <div className={productData.type == "dynamic" ? 'anual green' : 'anual'}>Dynamic
                  <input className='ml-1' type="radio" name="type" value="dynamic"
                    onChange={(e) => setProductData({ ...productData, type: e.target.value })} />
                </div>
              </div>
            </div> */}
          {/* <div className="form-group">
              <label >Fixed Price</label>
              <div className="d-flex">
                <input type="number"
                  onChange={(e) =>
                    setProductData({ ...productData, amount: e.target.value })
                  }
                  placeholder='Enter price' className='form-control' />
              </div>
            </div> */}
          {/* {productData?.type == "dynamic" &&
              <div className="form-group">
                <label >Unit Price</label>
                <div className="d-flex">
                  <input type="text"
                    onChange={(e) =>
                      setProductData({ ...productData, unitPrice: e.target.value })
                    }
                    placeholder='Enter unit price' className='form-control' />
                </div>
              </div>
            } */}
          {/* <div className="btnMain text-center">
              <button className="gradBtn fs-12 " onClick={addProductFunc}>Save Product</button>
            </div> */}

        </Modal.Body>
      </Modal>

      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Body>
          <div className="productModal">
            <div className="closeIco" onClick={handleCloseEdit}><img src={close} alt="close" /></div>
            <div className="logoRow">
              <img src={addProduct} alt="" />
              <div className='ml-2'>
                <h6 className='mb-0'>Edit Product</h6>
                {/* <p className='mb-0'>Subscription plan depend on what you need</p> */}
              </div>
            </div>
            <div className="form-group">
              <label >Name</label>
              <input type="text"
                onChange={(e) =>
                  setEditData({ ...editData, name: e.target.value })
                }
                value={editData.name}
                placeholder='Enter product name' className='form-control' />
            </div>
            <div className="form-group">
              <label >Webhook Url <span className='optional'>(optional)</span></label>
              <div className="d-flex">
                <input type="text"
                  onChange={(e) =>
                    setEditData({ ...editData, webhookUrl: e.target.value })
                  }
                  value={editData.webhookUrl}
                  placeholder='Enter price' className='form-control' />
              </div>
            </div>

            <div className="btnMain text-center">
              <button className="gradBtn fs-12 " onClick={submitEditProduct}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* view modal */}
      <Modal show={showView} onHide={handleCloseView}>
        <Modal.Body>
          <div className="productModal">
            <div className="closeIco" onClick={handleCloseView}><img src={close} alt="close" /></div>
            <div className="logoRow">
              <img src={addProduct} alt="" />
              <div className='ml-2'>
                <h6 className='mb-0'>{viewData.name}</h6>
              </div>
            </div>
            <div className="form-group">
              <label >Address</label>
              <p className='mb-0'>{viewData?.address}</p>
            </div>
            <div className="form-group">
              <label >Network</label>
              <p className='mb-0'>
                <div className='text-left text-uppercase'>
                  <>
                    {viewData?.network == "bsc" &&
                      <div className="Icoimg">
                        <img className='' src={bscIco} alt="" />
                      </div>
                    }
                    {viewData?.network == "eth" &&
                      <div className="Icoimg">
                        <img className='' src={ethIco} alt="" />
                      </div>
                    }
                    {viewData?.network == "polygon" &&
                      <div className="Icoimg">
                        <img className='' src={polygonIco} alt="" />
                      </div>
                    }
                    {viewData?.network == "tron" &&
                      <div className="Icoimg">
                        <img className='' src={tronIco} alt="" />
                      </div>
                    }
                  </>
                  {viewData?.network}
                </div>
              </p>
            </div>
            <div className="form-group">
              <label >Currency</label>
              <p className='mb-0'>
                <div className='text-uppercase'>
                  <>
                    {viewData?.currency == "usdt" &&
                      <div className="Icoimg">
                        <img className='' src={usdtIco} alt="" />
                      </div>
                    }
                    {viewData?.currency == "usdc" &&
                      <div className="Icoimg">
                        <img className='' src={usdcIco} alt="" />
                      </div>
                    }
                    {viewData?.currency == "busd" &&
                      <div className="Icoimg">
                        <img className='' src={busdIco} alt="" />
                      </div>
                    }
                  </>
                  {viewData?.currency}
                </div>
              </p>
            </div>
            <div className="form-group">
              <label >Subscription Period</label>
              <p className='mb-0'>
                {viewData?.unit == 3 && viewData?.time == 1 && "weekly"}
                {viewData?.unit == 3 && viewData?.time == 2 && "bi weekly"}
                {viewData?.unit == 4 && viewData?.time == 1 && "monthly"}
                {viewData?.unit == 4 && viewData?.time == 2 && "bi monthly"}
                {viewData?.unit == 4 && viewData?.time == 4 && "quaterly"}
                {viewData?.unit == 4 && viewData?.time == 6 && "half yearly"}
                {viewData?.unit == 5 && viewData?.time == 1 && "yearly"}
              </p>
            </div>
            <div className="form-group">
              <label >Type</label>
              <p className='mb-0'>{viewData?.type}</p>
            </div>
            <div className="form-group">
              <label >Price</label>
              <p className='mb-0'>{viewData?.amount / 10 ** 18}</p>
            </div>
            {viewData?.unitPrice &&
              <div className="form-group">
                <label >Unit Price</label>
                <p className='mb-0'>{viewData?.unitPrice}</p>
              </div>
            }
            {viewData?.webhookUrl &&
              <div className="form-group">
                <label >Webhook Url</label>
                <p className='mb-0'>{viewData?.webhookUrl}</p>
              </div>
            }
            <div className="form-group">
              <label >Created At</label>
              <p className='mb-0'>{moment(viewData?.createdAt).format("DD MMM YYYY")}</p>
            </div>


          </div>
        </Modal.Body>
      </Modal>
      {/* Loader component */}
      <Loader loader={loader} />
    </>
  )
}

export { CustomerProduct };