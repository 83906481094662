import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import { useDispatch } from "react-redux";
import userActions from "../redux/users/action";
import { useAlert } from "react-alert";

const Header = () => {
  const user = useSelector((state) => state.userReducer.users);
  const dispatch = useDispatch();
  const { setUser } = userActions;
  const navigate = useNavigate();
  const alert = useAlert()

  const logout = async () => {
    try {
      await dispatch(setUser({}));
      localStorage.clear()
      navigate("/");
    } catch {
      alert.show("Error while logging out");
    }
  };
  return (
    <>
      <section className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <NavLink className="navbar-brand" to="/">
            <img
              src={logo}
              width={130}
              height={50}
              className="d-inline-block align-top"
              alt=""
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto" id="headerrnav">
              <li className="nav-item active">
                <NavLink
                  style={{ color: "black", fontSize: "18px" }}
                  className="nav-link"
                  to="/"
                >
                  Home <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li className="nav-item">
                {user.token && (
                  <NavLink
                    style={{ color: "black", fontSize: "18px" }}
                    className="nav-link"
                    onClick={logout}
                  >
                    Logout
                  </NavLink>
                )}
              </li>
            </ul>
          </div>
        </nav>
      </section>
    </>
  );
};

export default Header;
