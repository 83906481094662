import React,{useEffect,useState} from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../api";
import userAction from "../../../redux/users/action";
import dateIco from "../../../images/dateIco.svg"
import phoneIco from "../../../images/phoneIco.svg"
import emailIco from "../../../images/emailIco.svg"
import lockIco from "../../../images/lockIco.svg"
import passwordIco from "../../../images/passwordIco.svg"
import showPasswordIco from "../../../images/showPasswordIco.svg"
import { useAlert } from "react-alert";
import "./profile.css"
const { setUser } = userAction;


function Profile() {
  const userObject = useSelector((state) => state.userReducer.users);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const [profileForm,setProfileForm]= useState({
    firstName:userObject.user.firstName,
    lastName:userObject.user.lastName,
    username:userObject.user.username,
    email:userObject.user.email,
    password:"",
    newPassword:"",
    confirmPassword:"",
  });
  const alert = useAlert()
  const [editProfile,setEditProfile] = useState(false)

  function handleChange(e) {
    editProfile && setProfileForm({...profileForm,[e.target.name]:e.target.value})
  }

    //  Validation for Profile
    const validation = () => {
        if (!profileForm.email) {
          return "Email is required";
        }
        if (!profileForm.firstName) {
          return "First Name is required";
        }
        if (!profileForm.lastName) {
          return "Last Name is required";
        }
        if (!profileForm.username) {
          return "Username is required";
        }
        if (profileForm.password) {
          if (!profileForm.newPassword) {
            return "Old and New password are required";
          }
        }
        if (profileForm.newPassword) {
          if (!profileForm.password) {
            return "Old and New password are required";
          }
        }
        if (profileForm.newPassword && (profileForm.newPassword != profileForm.confirmPassword) ) {
            return "Password not match"
        }
    
        return false;
      };

        //  Edit profile
  const hanldeSubmit = async () => {
    console.log(profileForm);
    try {
      const validationRes = await validation();

      if (validationRes) {
        return alert.show(validationRes);
      }

      const payload = {
        _id: userObject.user._id,
        profileForm,
      };
      console.log("payload");
      console.log(payload);
      const resp = await api.editProfile(payload);

      if (resp.error) {
        return alert.show("Old Password not correct or link is down.");
      }

      setProfileForm({ ...profileForm, password: "", newPassword: "", confirmPassword: "" });

      await fetchUserData();
      setEditProfile(false)
      alert.show("Successfully profile editted!");
    } catch (e) {
      alert.show("Error while editting profile");
    }
  };

    // To fetch updated data of user and save to redux
    const fetchUserData = async () => {
        try {
          setLoader(true);
    
          const payload = {
            _id: userObject?.user?._id,
          };
          const userData = await api.getUserData(payload);
    
          const token = userObject.token;
          userData.token = token;
    
          dispatch(setUser(userData));
          console.log(userObject);
          setLoader(false);
        } catch (e) {
          setLoader(false);
          console.log(e);
        }
      };


  return(
    <>
      {/* Begin Page Content */}
      <div className="container-fluid ">
        <div className="profilePage">
            <div className="row1">
                <div className="col1">
                    <h3>Profile Information</h3>
                </div>
                <div className="col2">
                    <button className={editProfile?"gradBtn fs-12 ":"transparentBtn fs-12 "}  onClick={()=> setEditProfile(!editProfile)}>Edit Profile</button>
                </div>
            </div>
            <div className="formMain">
                <h6 className='mb-3 mt-5'>Personal Information</h6>
                <div className="formRow">
                    <div className="form-group">
                        <label >First Name</label>
                        <input onChange={(e)=>handleChange(e)} type="text" name="firstName" value={profileForm.firstName} placeholder='Enter name' className='form-control' />
                    </div>
                    <div className="form-group">
                        <label >Last Name</label>
                        <input onChange={(e)=>handleChange(e)} type="text" name='lastName' value={profileForm.lastName} placeholder='Enter name' className='form-control' />
                    </div>
                </div>
                {/* <div className="formRow">
                    <div className="form-group">
                        <label >Date if Birth</label>
                        <div className="inputDiv">
                            <img className='iconRight ' src={dateIco} alt="" />
                            <input onChange={(e)=>handleChange(e)} type="text" placeholder='Enter product name' className='pr-5 form-control' />
                        </div>
                    </div>
                    <div className="form-group">
                        <label >Mobile Phone</label>
                        <div className="inputDiv">
                            <img className='iconLeft' src={phoneIco} alt="" />
                            <input onChange={(e)=>handleChange(e)} type="text" placeholder=' Enter product name' className='pl-5 form-control' />
                        </div>
                    </div>
                </div> */}
                <div className="form-group">
                    <label >Username</label>
                    <div className="inputDiv">
                        <input onChange={(e)=>handleChange(e)} type="text" name='username' value={profileForm.username} placeholder='Enter product name' className='form-control' />
                    </div>
                </div>
                <div className="form-group">
                    <label >Email</label>
                    <div className="inputDiv">
                        <img className='iconLeft' src={emailIco} alt="" />
                        <input onChange={(e)=>handleChange(e)} disabled type="text" name='email' value={profileForm.email} placeholder='Enter product name' className='pl-5 form-control' />
                    </div>
                </div>
                <div className="formRow">
                    <div className="form-group mr-2">
                        <label>Old Password</label>
                        <div className="inputDiv">
                            <img className='iconLeft' src={lockIco} alt="" />
                            <img className='iconRight' src={passwordIco} alt="" />
                            <input onChange={(e)=>handleChange(e)} type="password" name='password' value={profileForm.password} placeholder='Enter Old Password' className='pl-5 form-control' />
                        </div>
                    </div>
                    <div className="form-group mr-2">
                        <label>New Password</label>
                        <div className="inputDiv">
                            <img className='iconLeft' src={lockIco} alt="" />
                            <img className='iconRight' src={passwordIco} alt="" />
                            <input onChange={(e)=>handleChange(e)} type="password" name='newPassword' value={profileForm.newPassword} placeholder='Enter New Password' className='pl-5 form-control' />
                        </div>
                    </div>

                    <div className="form-group">
                        <label >Confirm Password</label>
                        <div className="inputDiv">
                            <img className='iconLeft' src={lockIco} alt="" />
                            <img className='iconRight' src={passwordIco} alt="" />
                            <input onChange={(e)=>handleChange(e)} type="password" name='confirmPassword' value={profileForm.confirmPassword} placeholder='Confirm Password' className='pl-5 form-control' />
                        </div>
                    </div>
                </div>
                {editProfile &&
                <div className='text-center mt-4'>
                    <button className="gradBtn fs-12" onClick={hanldeSubmit}>Save Changes</button>
                </div>
                }
            </div>
        </div>
      
      </div>
      {/* /.container-fluid */}
       
    </>
  )
}


export {Profile};
