import React from "react";
import rectangle from "../images/Rectangle.png";

const Most = () => {
  const setFaqIco = (e)=>{
    let elem = e.target
    if(elem.innerText == "+"){
      elem.innerText = "-"
    }else{
      elem.innerText = "+"
    }
    console.log(elem.innerText);
  }
  return (
    <>
      <section className="sec8" id="faq">
        <div className="container">
          <div className="row ai-center">
            
            <div className="col-lg-5">
              <h1 className="mosth1 animatable slideInUp">Most Frequently Asked Questions</h1>
              <p className="pwant animatable slideInUp">
              I have a few questions about SiePay and crypto recurring payments. Can you answer them for me?” You're in luck! We've put together this helpful guide to answer the most frequently asked ones, so read on…
              </p>
            </div>  

            <div className="col-lg-7" >
              
              {/* <div>
                <img className="rectangle" src={rectangle} />
              </div>

              <div className="siediv1">
                <div><h3 className="mosmall">What is Siepay?</h3></div>
                <div><h1 className="posmall">+</h1></div>

              </div>
            
              <div className="siediv2">
                <div><h3 className="mosmall">What is payment gateway?</h3>
                <p className="psmall">Payment gateway is the front-end technology that reads payment cards and sends customer information to the merchant acquiring bank for processing.</p>
                </div>
                
                <div><h1 className="posmall">|</h1></div>

              </div>

              <div className="siediv3">
                <div><h3 className="mosmall">Wh0 is Luff's father?</h3></div>
                <div><h1 className="posmall">+</h1></div>

              </div> */}

                <div className="faqMain" id="accordion">
                  <div className="bgEffect"></div>
                  <div className="cardMain">

                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          What is SiePay?
                            {/* <span className="plus">+</span>
                            <span className="minus">-</span> */}
                          </button>
                        </h5>
                      </div>
                      <div id="collapseOne" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                        A cryptocurrency recurring payment system is a way to automate payments using crypto currencies. This can be helpful for businesses that want to make regular payments, or for people who want to set up a subscription service.
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="mb-0">
                          <button className="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          How Does SiePay Work?
                            {/* <span className="plus">+</span>
                            <span className="minus">-</span> */}
                          </button>
                        </h5>
                      </div>
                      <div id="collapseTwo" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                        SiePay is a new payment processing system that works similarly to traditional payments and uses smart contracts on chains. The company ingests requests from wallets, checks for validation before proceeding with the transaction; this allows users who have authorized an application in their preferred medium (off-chain terms of service contract) or online marketplace - such as those using Ethereum's Blockchain or any EVM blockchain etc.,to automatically send funds without any input required by either party except when it comes time to pay back debts after selling goods/services owed.
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h5 className="mb-0">
                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          What network is SiePay available on?
                            {/* <span>+</span> */}
                          </button>
                        </h5>
                      </div>
                      <div id="collapseThree" className="collapse " aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                        SiePay is a revolutionary protocol that enables fast and secure payments. It's currently live on Ethereum, Binance but you can also test it out using our Goerli Testnet!
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h5 className="mb-0">
                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                          Who pays for gas fees when I use SiePay?
                            {/* <span>+</span> */}
                          </button>
                        </h5>
                      </div>
                      <div id="collapseFour" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                        The user never has to pay for each auto payment, as it's all done automatically. The third party takes care of processing transactions and paying with their own gas fee so that users can enjoy the benefits without worrying about the transaction fees. However, The user will only pay for the gas to sign the contract or eventually cancel.
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h5 className="mb-0">
                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                          What if, when my payment is due, I don't have enough funds in my wallet or the ERC-20 allowance is inadequate?
                            {/* <span>+</span> */}
                          </button>
                        </h5>
                      </div>
                      <div id="collapseFive" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                        The user never has to pay for each auto payment, as it's all done automatically. The third party takes care of processing transactions and paying with their own gas fee so that users can enjoy the benefits without worrying about the transaction fees. However, The user will only pay for the gas to sign the contract or eventually cancel.
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h5 className="mb-0">
                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                          What if, when my payment is due, I don't have enough funds in my wallet or the ERC-20 allowance is inadequate?
                            {/* <span>+</span> */}
                          </button>
                        </h5>
                      </div>
                      <div id="collapseSix" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                          <p>                        In order to avoid any inconveniences, your wallet needs a balance and/or the allowance is too low. If you don't have enough money in there or if it's just not possible for some reason then we won’t process transactions automatically because that would mean sending them off without knowing whether they'll work first. A company would never want their customer's payment to fail. To avoid this, they provide the option for a retry period so that if one does not work out in your favor there is still time before another attempt can be made again with no consequences!</p>
                          <p>To help prevent missed payments, Siepay makes sure to keep in touch with their customers even before they're overdue. They will send an email reminder 3 days ahead of time, just like this one here:</p>
                          <p>"Hi there! Your payment is scheduled for today (insert date), but if you don't have enough money set aside yet...we'll send another alert 24 hours beforehand."</p>
                      </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h5 className="mb-0">
                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                          Can a user cancel a subscription?                            
                          {/* <span>+</span> */}
                          </button>
                        </h5>
                      </div>
                      <div id="collapseSeven" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                        Customers are always free to cancel at any time. Your company's internal configuration may determine whether or not continued access is granted, but in most cases this will depend on what you have paid for with your purchase.                      </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h5 className="mb-0">
                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                          Are there refunds?                           
                          {/* <span>+</span> */}
                          </button>
                        </h5>
                      </div>
                      <div id="collapseEight" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                        Company's/Merchant’s can always send customers back their own money if they want. It’s not possible for SiePay to issue refunds within the system, but companies themselves are able to make these decisions instead.
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h5 className="mb-0">
                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                          After I subscribe, can I switch to a different wallet?                        
                          {/* <span>+</span> */}
                          </button>
                        </h5>
                      </div>
                      <div id="collapseNine" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                        The end merchant's service usually provides a way to update your wallet address if it changes.
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h5 className="mb-0">
                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                          Is it possible to change the token I use to pay in?                          
                          {/* <span>+</span> */}
                          </button>
                        </h5>
                      </div>
                      <div id="collapseTen" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                        The company you are subscribing to may allow for changing the token of payment depending on what's best suited. Please contact the company to update your preferred token of payment.
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h5 className="mb-0">
                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                          You ask for my email address when I check out, why?                          
                          {/* <span>+</span> */}
                          </button>
                        </h5>
                      </div>
                      <div id="collapseEleven" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                        Merchants who use SiePay can choose to gather email addresses or not. Giving clients an email address enables them to receive cancellation alerts, receipts, and emails serving as reminders when a payment is due; this, in turn, results in more successful transactions and a better overall experience.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Most;
