import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
 
import searchIcon from "../../../../images/search.svg";
import chevronDown from "../../../../images/chevronDown.svg";
import profile from "../../../../images/avatar.png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import userActions from "../../../../redux/users/action";
import customerActions from "../../../../redux/customer/action";
import { useAlert } from "react-alert";



function Navbar() {
  const {next} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
const userObject = useSelector((state) => state.userReducer.users);
const customerObject = useSelector((state) => state.customerReducer.customer);

  const { setUser } = userActions;
  const { setCustomer } = customerActions;

  const logout = async () => {
    try {
      await dispatch(setUser({}));
      localStorage.clear()
      navigate("/");
    } catch {
      alert.show("Error while logging out");
    }
  };
  const logoutCustomer = async () => {
    try {
      await dispatch(setCustomer({}));
      localStorage.clear()
      navigate("/welcome");
    } catch {
      alert.show("Error while logging out");
    }
  };
    return(
        <>
             {/* Topbar */}
             <nav className="navbar jc-sb bg-white navbar-expand navbar-light topbar static-top pe-3" style={{ marginBottom: "25px" }}>
                {/* Sidebar Toggle (Topbar) */}
                <button id="sidebarToggleTop" className="btn btn-link rounded-circle mr-3">
                  <i className="fa fa-bars color-green" />
                </button>
                {/* Topbar Search */}
                {/* <div className="navHeading">
                   {next != "get-started" && next}
                </div> */}
                {/* <form className="d-none d-sm-inline-block form-inline ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                  <div className="input-group sMain">
                    <input type="text" className="form-control border-0 " placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                    <div className="input-group-append">
                      <button className="btn search-btn pe-4" type="button">
                        <img src={searchIcon} className="w-15 mb-1" alt="serach icon" />
                      </button>
                    </div>
                  </div>
                </form> */}
                {/* Topbar Navbar */}
                
                {customerObject?.wallet ?
                <ul className="navbar-nav">
                  <li className="dropdown no-arrow nav-item ">

                    <a className="nav-link profileLink dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img className="img-profile rounded-circle" src={profile} />

                      <div className="profile-div ml-2">
                        <div className="mr-2 profile-name">{customerObject?.wallet.slice(0,4)}...{customerObject?.wallet.slice(customerObject?.wallet.length-4,customerObject?.wallet.length)}</div>
                        {/* <span className="status">Active</span> */}
                      </div>
                      <div>
                        <img src={chevronDown} className="w-9 ml-1 mb-2" alt="dropdown icon" />
                      </div>

                    </a>
                    {/* Dropdown - User Information */}
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                      <a className="dropdown-item" href="/dashboard/profile">
                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                        Profile
                      </a>
                      <a className="dropdown-item" href="/dashboard/settings">
                        <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                        Settings
                      </a>
                      <a className="dropdown-item" href="/dashboard/activity-logs">
                        <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                        Activity Log
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item" href="#" onClick={logoutCustomer}>
                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                        Logout
                      </a>
                    </div>
                  </li>
                </ul>
                :
                <ul className="navbar-nav">
                  <li className="dropdown no-arrow nav-item ">

                    <a className="nav-link profileLink dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img className="img-profile rounded-circle" src={profile} />

                      <div className="profile-div ml-2">
                        <div className="mr-2 profile-name">{userObject?.user?.username}</div>
                        {/* <span className="status">Active</span> */}
                      </div>
                      <div>
                        <img src={chevronDown} className="w-9 ml-1 mb-2" alt="dropdown icon" />
                      </div>

                    </a>
                    {/* Dropdown - User Information */}
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                      <a className="dropdown-item" href="/dashboard/profile">
                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                        Profile
                      </a>
                      <a className="dropdown-item" href="/dashboard/settings">
                        <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                        Settings
                      </a>
                      <a className="dropdown-item" href="/dashboard/activity-logs">
                        <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                        Activity Log
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item" href="#" onClick={logout}>
                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                        Logout
                      </a>
                    </div>
                  </li>
                </ul>
                }
              </nav>
              {/* End of Topbar */}
        </>
    )
    
}
export default Navbar