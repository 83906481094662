import React,{useEffect,useState} from 'react';
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import api from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import copy from 'copy-to-clipboard';
import ModalImage from "react-modal-image";
import { useAlert } from "react-alert";

import view from "../../../../images/view.png";
import editIco from "../../../../images/editIco.svg";
import close from "../../../../images/close.svg";

import "./mDetail.css"
// import axios from "axios";
// js

// import Navbar from './navbar';
// import Sidebar from './sidebar';

function AdminMerchantDetail(props) {
  const navigate = useNavigate()
  const {id} = useParams();

     // fetch all subscription of product
     const [loader, setLoader] = useState(false);
     const [subscriptions, setSubscritpions] = useState([]);
     const [product, setProduct] = useState([]);
     const [transaction, setTransaction] = useState([]);
     const [kyc, setKyc] = useState([]);
     const [NavMenu, setNavMenu] = useState("product");

     const [pageLimit, setPageLimit] = useState(3);
     const [pageSkip, setPageSkip] = useState(0);
     const [docCount, setDocCount] = useState(0);

     const fetchALlSubscription = async (skip) => {
      try {
        setLoader(true);
        const response = await api.getMerchantDetail(id);
  
        console.log(response);
        // setSubscritpions([...subscriptions, ...response?.merchant]);
        setSubscritpions(response?.subscription);
        setProduct(response?.product);
        setTransaction(response?.transaction);
        setKyc(response?.kyc);
        setLoader(false);
        setDocCount(response?.docCount)
        console.log(response?.docCount);
      } catch (e) {
        console.log(e);
        setLoader(false);
      }
    };
  useEffect(()=>{
    fetchALlSubscription(0);
  },[])
  return(
    <>
      {/* Begin Page Content */}
      <div className="container-fluid subscripPage">
        {/* navigation menu */}
        <div className="card sortby-box br-10 border-transparent "> 
          <div className="card-header bg-white border-transparent pt-3 ">
            <div className="mDetailMenu">
              <button className={NavMenu == "product"?'roundButton active':'roundButton' }  onClick={()=> setNavMenu("product")}>Product</button>
              <button className={NavMenu == "subscriptions"? 'roundButton active':'roundButton'} onClick={()=> setNavMenu("subscriptions")}>Subscriptions</button>
              <button className={NavMenu == "transaction"?'roundButton active':'roundButton' }  onClick={()=> setNavMenu("transaction")}>Transaction</button>
              <button className={NavMenu == "kyc"?'roundButton active':'roundButton' }  onClick={()=> setNavMenu("kyc")}>KYC</button>
              {/* <button className='roundButton'>Expired</button> */}
            </div>
          </div>
        </div>
        {/* navigation menu */}
        <div className="row">
            <div className="col">
              {NavMenu == "product" &&
              <TableProduct setLoader={setLoader} product={product} />
            }
            {NavMenu == "subscriptions" &&
            <TableSubscription setLoader={setLoader} subscriptions={subscriptions} />
            }
            {NavMenu == "transaction" &&
            <TableTransacrion setLoader={setLoader} transaction={transaction} />
            }
            {NavMenu == "kyc" &&
            <TableKyc setLoader={setLoader} kycData={kyc} setKyc={setKyc} />
            }
            </div>
        </div>
      </div>
      {/* /.container-fluid */}
       
    </>
  )
}


const TableProduct=({setLoader,product})=>{
  const userObject = useSelector((state) => state.userReducer.users);
  const [copyAddress, setCopyAddress] = useState("Copy Address");

  // console.log(id);
 

    // format date
    const formatDate = (dateParam) => {
      const date = new Date(dateParam);
      return date.toLocaleDateString();
    };

    // const handleShowMore = async () => {
    //   setPageSkip(pageSkip+pageLimit)
    //   console.log(pageSkip + pageLimit);
    //   await fetchALlSubscription(pageSkip+ pageLimit);

    // }

    const handleCopyAdr = (address) => {
      copy(address)
      setCopyAddress("copied")
      setTimeout(() => {
        setCopyAddress("Copy Address")
      }, 3000);
    }


  return(
                <div className="card sortby-box br-10 border-transparent "> 
                  <div className="card-header bg-white border-transparent pt-3 ">
                    <h3 className='mb-4'>Product ({product?.length})</h3>
                    <div className="row1">
                        <div className="">
                          <button className='roundButton active'>All</button>
                          <button className='roundButton'>Active</button>
                          <button className='roundButton'>Pending</button>
                          {/* <button className='roundButton'>Expired</button> */}
                        </div>
                        <div>
                            {/* <button className="gradBtn fs-12 ">+ Create Subscription</button> */}
                        </div>
                    </div>
                  </div>
                    <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
                    <table className="table table-hover">
                          <thead>
                                  <tr>
                                    <th scope="col">Price</th>
                                    <th scope="col">Unit Price</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Subscription Period</th>
                                    <th scope="col">Created</th>
                                    
                                  </tr>
                          </thead>
                          <tbody>
                            {product?.map((item,key)=>(
                            <tr>
                              <td>
                                      <div>${item?.amount / 10 ** 18}  </div>
                              </td>
                              <td>
                                      <div>{item?.unitPrice ? "$"+item?.unitPrice:"-" }  </div>
                              </td>
                              <td>
                                      <div className='font-500'>{item?.name}</div>
                              </td>
                              <td>
                                    <div className="copyLink">
                                      <div className='d-flex jc-center'>
                                        <span className='limitedAddress' onClick={()=>handleCopyAdr(item?.address)}> {item?.address}</span> ...
                                      </div>                                         
                                      <div className="copyText">{copyAddress}</div>
                                    </div>
                                                                   
                              </td>
                            
                              <td>
                                    <div>
                                      {item?.unit == 3 && item?.time == 1 && "weekly" }
                                      {item?.unit == 3 && item?.time == 2 && "bi weekly" }
                                      {item?.unit == 4 && item?.time == 1 && "monthly" }
                                      {item?.unit == 4 && item?.time == 2 && "bi monthly" }
                                      {item?.unit == 4 && item?.time == 4 && "quaterly" }
                                      {item?.unit == 4 && item?.time == 6 && "half yearly" }
                                      {item?.unit == 5 && item?.time == 1 && "yearly" }
                                    </div>
                              </td>
                              <td>
                                      <div>{moment(item?.createdAt).format("DD MMM YYYY") }</div>
                              </td>
                            
                            </tr>
                            ))}
                            
                          </tbody>
                        </table>
                        {/* {docCount != product?.length && docCount >= pageLimit &&
                        <div className='showMoreMain text-center'>
                            <button className="gradBtn fs-12" onClick={handleShowMore}>
                              Show More
                            </button> 
                        </div>
                        } */}
                    </div>
                  </div>
  )
}

const TableSubscription=({setLoader,subscriptions})=>{
  const userObject = useSelector((state) => state.userReducer.users);
  const [copyAddress, setCopyAddress] = useState("Copy Address");

  // console.log(id);
 

    // format date
    const formatDate = (dateParam) => {
      const date = new Date(dateParam);
      return date.toLocaleDateString();
    };

    // const handleShowMore = async () => {
    //   setPageSkip(pageSkip+pageLimit)
    //   console.log(pageSkip + pageLimit);
    //   await fetchALlSubscription(pageSkip+ pageLimit);

    // }

    const handleCopyAdr = (address) => {
      copy(address)
      setCopyAddress("copied")
      setTimeout(() => {
        setCopyAddress("Copy Address")
      }, 3000);
    }


  return(
                <div className="card sortby-box br-10 border-transparent "> 
                  <div className="card-header bg-white border-transparent pt-3 ">
                    <h3 className='mb-4'>Subscriptions ({subscriptions?.length})</h3>
                    <div className="row1">
                        <div className="">
                          <button className='roundButton active'>All</button>
                          <button className='roundButton'>Active</button>
                          <button className='roundButton'>Pending</button>
                          {/* <button className='roundButton'>Expired</button> */}
                        </div>
                        <div>
                            {/* <button className="gradBtn fs-12 ">+ Create Subscription</button> */}
                        </div>
                    </div>
                  </div>
                    <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
                    <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Product Name</th>
                              <th scope="col">Subscription Amount</th>
                              <th scope="col">Subscribed By</th>
                              <th scope="col">Start Time</th>
                              <th scope="col">End Time</th>
                              <th colSpan={1} scope="col">
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                          {subscriptions?.map((item) => (
                            <tr>
                              <td>
                                      <div className='font-500'>{item?.productId?.name}</div>
                              </td>
                              <td>
                                      <div>
                                        {item?.productId?.unitPrice ?
                                        <>
                                         {(parseFloat(item?.productId?.unitPrice) * item?.quantity) + parseInt(item?.productId?.amount) / 10 ** 18 + "$"}
                                        </>
                                        :
                                        <>
                                        {item?.productId?.amount / 10 ** 18 + "$"}
                                        </>
                                        }
                                      </div>
                              </td>
                              <td>
                                  <div className='d-flex'><span className='limitedAddress'> {item?.address}</span> ...</div>

                              </td>
                              
                              <td>
                                      <div>{moment(item?.startDate).format("DD MMM YYYY")}</div>
                              </td>
                              <td>
                                      <div>{moment(item?.endDate).format("DD MMM YYYY")}</div>
                              </td>
                              <td>
                                {item?.status == 0 ?
                                  <div className='badge badge-warning'>Pending </div>
                                  : 
                                  <div className='badge badge-success'> Active</div>
                                }
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                        {/* {docCount != product?.length && docCount >= pageLimit &&
                        <div className='showMoreMain text-center'>
                            <button className="gradBtn fs-12" onClick={handleShowMore}>
                              Show More
                            </button> 
                        </div>
                        } */}
                    </div>
                  </div>
  )
}

const TableTransacrion=({setLoader,transaction})=>{
  const userObject = useSelector((state) => state.userReducer.users);
  const [copyAddress, setCopyAddress] = useState("Copy Address");

    // const handleShowMore = async () => {
    //   setPageSkip(pageSkip+pageLimit)
    //   console.log(pageSkip + pageLimit);
    //   await fetchALlSubscription(pageSkip+ pageLimit);

    // }




  return(
                <div className="card sortby-box br-10 border-transparent "> 
                  <div className="card-header bg-white border-transparent pt-3 ">
                    <h3 className='mb-4'>Transaction ({transaction?.length})</h3>
                    <div className="row1">
                        <div className="">
                          <button className='roundButton active'>All</button>
                          <button className='roundButton'>Active</button>
                          <button className='roundButton'>Pending</button>
                          {/* <button className='roundButton'>Expired</button> */}
                        </div>
                        <div>
                            {/* <button className="gradBtn fs-12 ">+ Create Subscription</button> */}
                        </div>
                    </div>
                  </div>
                    <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
                    <table className="table table-hover">
                          <thead>
                                  <tr>
                                    <th scope="col">Owner</th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Product Amount</th>
                                    <th scope="col">Transaction Date</th>
                                    <th scope="col">Event</th>
                                  </tr>
                          </thead>
                          <tbody>
                            {transaction?.map((item) => (
                            <tr>
                              <td>
                                      <div className='d-flex jc-center'><span className='limitedAddress'> {item?.address}</span> ...</div>
                              </td>
                              <td>
                                      <div className='font-500'>{item?.product?.name}</div>
                              </td>
                              <td>
                                      <div>{item?.product?.amount / 10 ** 18}$</div>
                              </td>
                              <td>
                                      <div>{moment(item?.date).format("DD MMM YYYY")}</div>
                              </td>
                              <td>
                                      {item?.event == "subscribe"?
                                      <div className='eventBadge badge badge-success text-uppercase'>{item?.event}</div>
                                      :
                                      <div className='eventBadge badge badge-danger text-uppercase'>{item?.event}</div>
                                      }
                              </td>
                            </tr>
                            ))}
                        
                          </tbody>
                        </table>
                        {/* {docCount != product?.length && docCount >= pageLimit &&
                        <div className='showMoreMain text-center'>
                            <button className="gradBtn fs-12" onClick={handleShowMore}>
                              Show More
                            </button> 
                        </div>
                        } */}
                    </div>
                  </div>
  )
}

const TableKyc=({setLoader,kycData,setKyc})=>{
  const alert = useAlert();

  const userObject = useSelector((state) => state.userReducer.users);
  const [copyAddress, setCopyAddress] = useState("Copy Address");
  const [editData, setEditData] = useState({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // console.log(id);

    // format date
    const formatDate = (dateParam) => {
      const date = new Date(dateParam);
      return date.toLocaleDateString();
    };

    // const handleShowMore = async () => {
    //   setPageSkip(pageSkip+pageLimit)
    //   console.log(pageSkip + pageLimit);
    //   await fetchALlSubscription(pageSkip+ pageLimit);

    // }

    const handleCopyAdr = (address) => {
      copy(address)
      setCopyAddress("copied")
      setTimeout(() => {
        setCopyAddress("Copy Address")
      }, 3000);
    }
    const submitEdit = async () => {
      console.log(editData);
      if(editData.status){
        const response = await api.editKyc(editData);
        if (response.success) {
          console.log(response);
          response.data.status=editData.status
          setKyc([response.data]);
          setLoader(false);
          alert.show("Success")
          handleClose()
        }
      }else{
        alert.show("Please select status")
      }
    }


  return(
    <>
      <div className="card sortby-box br-10 border-transparent "> 
                  <div className="card-header bg-white border-transparent pt-3 ">
                    <h3 className='mb-4'>KYC </h3>
                    <div className="row1">
                        <div className="">
                          <button className='roundButton active'>All</button>
                          <button className='roundButton'>Active</button>
                          <button className='roundButton'>Pending</button>
                          {/* <button className='roundButton'>Expired</button> */}
                        </div>
                        <div>
                            {/* <button className="gradBtn fs-12 ">+ Create Subscription</button> */}
                        </div>
                    </div>
                  </div>
                    <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 ">
                    <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Identity</th>
                              <th scope="col">company Document</th>
                              <th scope="col">Full Name</th>
                              <th scope="col">Gender</th>
                              <th scope="col">designation</th>
                              <th scope="col">Id/Passport</th>
                              <th scope="col">businessName</th>
                              <th scope="col">businessAddress</th>
                              <th scope="col">businessType</th>
                              <th scope="col">businessWebsite</th>
                              <th scope="col">Monthly Volume</th>
                              <th scope="col">Submit At</th>
                              <th colSpan={1} scope="col">
                                Status
                              </th>
                              <th colSpan={1} scope="col">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                          {kycData?.map((item) => (
                            <tr>
                              <td>
                                    {/* <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${item?.idImg}`} alt="" /> */}
                                  <div className='idImg'>
                                    <ModalImage
                                      small={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${item?.idImg}`}
                                      large={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${item?.idImg}`}
                                      alt="ID"
                                    />
                                  </div>
                              </td>
                              <td>
                                  <div className='idImg'>
                                    <ModalImage
                                      small={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${item?.companyDocumentImg}`}
                                      large={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${item?.companyDocumentImg}`}
                                      alt="company document"
                                    />
                                  </div>
                              </td>
                              <td>
                                {item?.fullname}
                              </td>
                              <td>
                                {item?.gender}
                              </td>
                              <td>
                                {item?.designation}
                              </td>
                              <td>
                                {item?.id_passport}
                              </td>
                              <td>
                                {item?.businessName}
                              </td>
                              <td>
                                {item?.businessAddress}
                              </td>
                              <td>
                                {item?.businessType}
                              </td>
                              <td>
                                {item?.businessWebsite}
                              </td>
                              <td>
                                {item?.estimatedMonthlyVolume}
                              </td>
                              <td>
                                <div>{moment(item?.createdAt).format("DD MMM YYYY")}</div>
                              </td>
                              <td>
                                {item?.status == 1?
                                  <div className='badge badge-warning'>Pending</div>
                                  : item?.status == 2?
                                  <div className='badge badge-success'>Approved</div>
                                  : item?.status == 3?
                                  <div className='badge badge-danger'>Rejected</div>
                                  :
                                  <div className='badge badge-danger'>Not Submited</div>
                                }
                              </td>
                              <td>
                                <div className='editKYCIco' >
                                  <img onClick={handleShow} src={editIco} alt="" />
                                </div>
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                        {/* {docCount != product?.length && docCount >= pageLimit &&
                        <div className='showMoreMain text-center'>
                            <button className="gradBtn fs-12" onClick={handleShowMore}>
                              Show More
                            </button> 
                        </div>
                        } */}
                    </div>
        </div>
        <Modal show={show} onHide={handleClose}>
<Modal.Body>
  <div className="productModal">
    <div className="closeIco" onClick={handleClose}><img src={close} alt="close" /></div>
    <div className="logoRow">
      <div className='ml-2'>
        <h6 className='mb-0'>Edit KYC Status</h6>
        {/* <p className='mb-0'>Subscription plan depend on what you need</p> */}
      </div>
    </div>
    <div className="form-group">
      <label>Status</label>
        <select className='form-control' name="status" id="" onChange={(e) =>
          setEditData({ ...editData, status: e.target.value })
        }>
          <option value={2}>Approve</option>
          <option value={3}>Reject</option>
        </select>
    </div>
 

    <div className="btnMain text-center">
      <button className="gradBtn fs-12 " onClick={submitEdit}>Save</button>
    </div>
  </div>
</Modal.Body>
</Modal>
    </>
  )
}



export {AdminMerchantDetail};
