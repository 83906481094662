import icon from "../images/smlogo.png"

export function Loader({ loader }) {
    return (
        <>
            {loader &&
                <div className="globalLoader">
                    <div className="loader">
                        <img src={icon} alt="loader" />
                        <h3 style={{ textAlign: "center", marginTop: "40px" }}>Loading...</h3>
                    </div>
                </div>
            }
        </>
    )
}