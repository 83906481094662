import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import sec1Effect1 from "../images/compress/sec1Effect1.webp";
import Ellipse1 from "../images/compress/Ellipse1.webp";
import dashboard1 from "../images/compress/dashboard1.webp";
import dashboard2 from "../images/compress/dashboard2.webp";
import dashboard3 from "../images/compress/dashboard3.webp";
import dashboard4 from "../images/compress/dashboard4.webp";
import dashboard5 from "../images/compress/dashboard5.webp";
import mobile1 from "../images/compress/mobile1.webp";
import mobile2 from "../images/compress/mobile2.webp";
import mobile3 from "../images/compress/mobile3.webp";
import mobile4 from "../images/compress/mobile4.webp";
import mobile5 from "../images/compress/mobile5.webp";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const Payment = () => {
  const [counter1,setCounter]= useState(1)
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 900,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  var time =1;
  function hanldeCounter() {
    var inter = setInterval(() => {
      if( time == 5 ){
        // setCounter(1)
        time= 1
      }else{
        time = time + 1
      }
    }, 3700);
    setCounter(time)
  }
  
  useEffect(()=>{
    hanldeCounter()
  },[])
  return (
    <>

      <section className="sec1" id="home">

      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h1 className="heapayment "> The Easiest Crypto <span> Recurring</span> Payment System For Businesses & Individuals.</h1>
            <p className="ppayment">
            A cryptocurrency recurring payment system is a way to automate payments using crypto currencies. This can be helpful for businesses that want to make regular payments, or for people who want to set up a subscription service. 
            </p>

            <div className="btnsec" style={{display:"flex",alignItems:'center'}}>
           
              <div className="mr-2">
                <NavLink to="/signin" className="btn1">Get Started</NavLink>
              </div>

      
              {/* <div>
                <a href="mailto:contact@siepay.io" className="transparentBtnDark">Demo Request</a>
              </div> */}
          
            </div>
         
          </div>

          <div className="col-lg-6 imgMain">
            <div className="counter">0{counter1}</div>
            <div className="dashboard1Img">
              <Slider {...settings} afterChange={(counter1) => {
              setCounter( counter1 + 1 )
              }}>
              <img className="w-100" src={dashboard1} />
              <img className="w-100" src={dashboard2} />
              <img className="w-100" src={dashboard3} />
              <img className="w-100" src={dashboard4} />
              <img className="w-100" src={dashboard5} />
              </Slider>
            </div>
            <div className="mobile1Img">
              <Slider {...settings1}>
                <img className="w-100" src={mobile1} />
                <img className="w-100" src={mobile2} />
                <img className="w-100" src={mobile3} />
                <img className="w-100" src={mobile4} />
                <img className="w-100" src={mobile5} />
              </Slider>
            </div>
            <img className="sec1Effect1" src={sec1Effect1} />
            <img className="ellipse1" src={Ellipse1} />
          </div>
        </div>
      </div>

      </section>
    </>
  );
};

export default Payment;
