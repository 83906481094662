import React from 'react'
import { Modal } from 'react-bootstrap'
import close from "../images/close.svg";
import logo from "../images/logo.svg";

export const PrivacyModal = ({show,handleClose}) => {
  return (
    <Modal show={show} onHide={handleClose} className="privacyModal" >
    <Modal.Body>
        <div className="productModal p-2 p-sm-4">
            <div className="closeIco" onClick={handleClose}><img src={close} alt="close" /></div>
            <div className="logoRow jc-center">
                <img src={logo} alt="" />
            </div>
            <div className='ml-2 text-center mb-5'>
                <h1 className='mb-0'>SiePay Privacy Policy</h1>
                <p className='mb-0'>The Easiest Crypto Recurring Payment System For Businesses & Individuals.</p>
            </div>
            <div className="privacyMain">
                <h6>Scope and Consent</h6>
                <p>This Privacy Notice (“Notice”) describes the ways SiePay Inc. (“SiePay”, “we”, “us”, “our”) collects, stores, uses and protects information. SiePay offers a wide range of services, which include processing payments for merchants and providing customer support. This notice applies to all sites that Siepay operates including <a href="www.siepay.io">www.siepay.io</a></p>
                <p>We may collect information about visitors to our sites, including their geographic location and browsing behavior. We also gather data from those who sign up for service through us -(known  as "Merchants"). The types of users this applies too are:</p>
                <h6>Visitors to our sites.</h6>
                <p>Merchants using Our Services (accepting donations).</p>
                <p>Aggregators on top of These services so they can process auto payments.</p>
                <p>Shoppers of Merchants (“Shoppers” means individuals who indirectly interface with SiePay when paying a Merchant’s invoice that is forwarded by SiePay to a Merchant during checkout, when requesting a refund from a Merchant, when creating a SiePay account, or when making a donation to a non-profit);</p>
                <p>Payees who request a payout from merchants and create an account with us can enjoy fast processing times while still being able to use their funds as they please!</p>
                <h6>What kind of data do we collect?</h6>
                <p>When you interact with us, we may collect personal information. The type of data collected depends on what kind of user you are - so read below for more details! </p>
                <h6>Visitors to our sites.</h6>
                <p>We may collect the following information relating to visitors of our Sites: technical data, including IP address; type and version of browser you use as well as device operating systems. We also ask for your email address so that we can send out emails featuring offers on products tailored especially for customers like yourself! Finally if this is a business don't forget about their phone number too - just input it during registration time.</p>
                <p>We collect information about your computer or other access devices for purposes including fraud prevention. The data we get from you may be collected automatically using cookies, web beacons (also known as digital markers), device recognition software etcetera- all of which are technologies that help us understand how people interact with our website so it can change what they see online!</p>

                <h6>Merchants</h6>
                <p>We may need to collect the following information during onboarding: full name of beneficial owner or any user that is added onto an account at a Merchant (including nonprofits and aggregators), their merchant email address, business phone number if applicable for this type/category then it should be included here as well. We also ask you to provide us with proof from three different sources including your ID card which shows government issued identification such as driver's license; company seal(s) certified copy invoice bill payment slip and confirming ownership.</p>

                <h6>Customers of Merchants</h6>
                <p>When you make purchases online with SiePay, we may collect information about your payment so that the process is easier for customers and merchants. This includes things like billing and shipping addresses as well as other details needed when processing refunds or donations.</p>

                <h6>For Invoice</h6>
                <p>This is exactly what we do when paying invoices through our system. We collect technical information, including IP addresses, crypto wallet addresses used to view the invoice and browser type/version on your devices--all of which help us improve site functionality for future customers!</p>

                <h6>For Refund</h6>
                <p>When you request a refund, we collect:</p>
                <p className="mb-0">Amount</p>
                <p className="mb-0">Currency</p>
                <p className="mb-0">Email address</p>
                <p className="mb-0">Crypto wallet address</p>
                <p className="">Invoice ID</p>

                <p>We may collect this information either from the Merchant or directly with your consent. Contacting our team helps us provide better services by allowing for exceptions in case of payment difficulties like overpayments and underpayments, so we can get you back on track quickly.</p>

                <h6>How we use cookies to improve your experience</h6>
                <p>Cookies are small bits of data that may be stored on your computer or mobile device when you visit certain websites. Cookies allow us to recognize a user's browser, which in turn allows them access for quicker navigation within our website(s). The main purpose behind cookies is so we can provide customized content according to each visitor’s interests--this also entails remembering where visitors have been browsing previously before coming back again later on down the line.</p>

                <h6>Why do we need your personal information?</h6>
                <p>Here are some of the ways we might use your information:</p>
                <p>We need this so that you can have a good experience using our services, and also for other purposes as permitted or required by law. For example, we will collect certain data from each person who signs up to be part in one way or another with running things here on earth.</p>

                <p className="mb-0">For better service</p>
                <p className="mb-0">For faster transactions</p>
                <p className="mb-0">Resolve issues and troubleshoot problems</p>
                <p className="mb-0">Comply with regulations</p>
                <p className="mb-0">Customize, measure, and improve our business</p>
                <p>Data analysis</p>
                <h6>What kind of security measures do we take to protect your information?</h6>
                <p>We know that security is important to you and we take it very seriously. Our information will be protected by administrative, technical & physical means which ensures there's no chance of unauthorized access or leakage into third parties like hackers who want what isn't theirs (hint: yours!).but remember this cannot guarantee 100% protection so please let us know if something does happen!</p>
                <h6>Your choice of plans</h6>
                <p>Cookies are a way to remember your preferences and easily navigate the website. You can control how they work by adjusting some settings in your browser, but if you want even more control over cookies then we also offer an online Cookie Preference Center for managing all of these choices.</p>

                <h6>Contact Information</h6>
                <p>We welcome your questions and concerns about our privacy policies or practices. Please feel free to contact us at anytime with any inquiries you may have at <a href="mailto:" target="_blank" >contact@siepay.io</a> </p>
            </div>
        </div>
    </Modal.Body>
</Modal>
  )
}
