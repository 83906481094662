import { combineReducers } from "redux";

import userReducer from "./users/reducer";
import customerReducer from "./customer/reducer";

const reducers = combineReducers({
  userReducer,
  customerReducer,
});

export default reducers;
